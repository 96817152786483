import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SectionHeader from "components/source/checkout/bag/sm/components/sm-bag-section-header";
import VerticalGrid from "components/source/checkout/bag/sm/components/sm-bag-vertical-grid";
import RtrImage from "../../shared/rtr-image";
import SMKifahFooterComponent from "./sm/kifah/footer";
import AtomErrorNotification from "components/source/atoms/atom-error-notification";
import ExclamationRedFillIcon from "images/sprites/reb/svg/exlam-icon-white-3x.svg";
import { membershipIsBilledToApplePay } from "helpers/payment-options-helper";
import { setKifahTrigger } from "actions/kifah-actions";

const SMKifahComponent = () => {
  const dispatch = useDispatch();
  const itemsToShip = useSelector(state => state.ceCheckoutKifah?.bagItems) || [];
  const isBagLoading = useSelector(state => state?.ceBagLoading) || false;
  const isCheckoutSubmitting = useSelector(state => state?.ceCheckoutSubmitting) || false;
  const ceCheckoutError = useSelector(state => state.ceCheckoutError) || "";
  const kifahTrigger = useSelector(state => state.kifahTrigger);
  const cmsPromoBannerContent = useSelector(state => state.cmsContent?.smBagPromoBanner);
  const promoBannerBgColor = cmsPromoBannerContent?.config?.backgroundColor;
  const paymentMethods = useSelector(state => state.paymentProfiles);

  const membershipItems = useSelector(state => state.membershipState?.membershipItems) || [];

  React.useEffect(() => {
    return () => {
      dispatch(setKifahTrigger(null));
    };
  }, []);

  // so we can show the percent off sticker in KIFAH checkout
  itemsToShip.map(ceCheckoutItem => {
    ceCheckoutItem.percentOff = membershipItems.find(
      membershipItem => ceCheckoutItem.sku === membershipItem.sku
    )?.percentOff;
  });

  return (
    <div className="simplify-membership-bag-container simplify-membership-bag-container--kifah">
      {cmsPromoBannerContent?.confirmPurchaseScreen && (
        <div className="promo-banner" style={{ backgroundColor: promoBannerBgColor }}>
          {cmsPromoBannerContent.confirmPurchaseScreen}
        </div>
      )}
      {ceCheckoutError && !membershipIsBilledToApplePay(paymentMethods) && (
        <AtomErrorNotification
          additionalClassName="sm-bag-status-message error"
          errorIcon={<ExclamationRedFillIcon />}
          errorCopy={ceCheckoutError}
        />
      )}
      <div className="banner">
        <h2 className="header">Final Sale</h2>
        <div className="sub-header">This purchase opens up a spot in your {kifahTrigger ? "" : " next "} shipment.</div>
      </div>
      {(isBagLoading || isCheckoutSubmitting) && (
        <div className="simplify-membership-bag-loading-overlay">
          <RtrImage src="//sf-p.rtrcdn.com/images/loading.gif" alt="Loading animation" />
        </div>
      )}
      <div className="simplify-membership-bag-content">
        <div className="section-container remove-border">
          <SectionHeader headerText={"Purchasing"} />
          <VerticalGrid gridItems={itemsToShip} />
        </div>
      </div>
      <SMKifahFooterComponent />
    </div>
  );
};

export default SMKifahComponent;
