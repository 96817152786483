import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import ActionLogger from "action-logger";
import MoleculeAddress from "components/source/molecules/molecule-address";
import ClosableModal from "components/source/shared/closable-modal";
import { displayModal } from "actions/shared-actions";
import { shippingStepSubmittingReset, updateShippingAddress } from "actions/shipping-step-actions";
import { updateMembershipShipmentAddress, fetchMembershipState } from "actions/membership-state-actions";
import { AUTH_STATES, inventoryEligibilities } from "rtr-constants";
import MembershipHelpers from "helpers/membership-helpers";
import { membershipStatePropType, addressPropType, userDataPropType } from "components/propTypes";
import ceCheckoutActions from "actions/ce-checkout-actions";
import VerifyAuthState from "../VerifyAuthState";
import classNames from "classnames";
import { findWhere } from "helpers/underscore-js-migration-helper";
import { setAddressId } from "actions/products-actions";

class ChangeShippingAddressComponent extends React.Component {
  static propTypes = {
    addressBookTitle: PropTypes.string,
    addressBookDek: PropTypes.string,
    addresses: PropTypes.arrayOf(addressPropType), // mapStateToProps
    displayManageAddressCards: PropTypes.bool,
    displayModal: PropTypes.func.isRequired,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // mapStateToProps
    fetchMembershipState: PropTypes.func,
    getOrCreateCECheckout: PropTypes.func,
    isSMBagEnabled: PropTypes.bool,
    isMobileViewport: PropTypes.bool,
    membershipState: membershipStatePropType, // mapStateToProps
    modalName: PropTypes.string,
    onChange: PropTypes.func,
    selectedAddressId: PropTypes.string,
    setAddressId: PropTypes.func,
    setAsDefault: PropTypes.bool,
    shippingStepSubmittingReset: PropTypes.func,
    showMakeDefaultCheckbox: PropTypes.bool,
    updateShippingAddress: PropTypes.func,
    updateMembershipShipmentAddress: PropTypes.func,
    useSelectedAddressButtonText: PropTypes.string,
    userData: userDataPropType, // mapStateToProps
  };

  static defaultProps = {
    onChange: () => {},
    showMakeDefaultCheckbox: true,
    setAsDefault: false,
    displayManageAddressCards: false,
  };

  logAction(options) {
    ActionLogger.logAction({ object_type: "change_address_modal", ...options });
  }

  handleSetAsDefault = values => {
    const selectedAddress = this.props.addresses?.find(address => address.id === values.id);
    if (this.props.setAsDefault || selectedAddress?.default) {
      values.default = true;
    }
    return values;
  };

  isActiveEligibleInventory() {
    const { userData, membershipState } = this.props;

    const isSubscriptionMember = MembershipHelpers.isSubscriptionMember(userData);
    const hasRtrUnlimitedInventoryWithLens =
      MembershipHelpers.hasInventoryEligibility(membershipState, inventoryEligibilities.rtr_unlimited) &&
      userData.isUnlimitedLens;
    const hasRtrUpdateInventoryWithLens =
      MembershipHelpers.hasInventoryEligibility(membershipState, inventoryEligibilities.rtr_update) &&
      userData.isRTRUpdateLens;

    const inventoryLensExist = hasRtrUnlimitedInventoryWithLens || hasRtrUpdateInventoryWithLens;
    return isSubscriptionMember && inventoryLensExist;
  }

  selectedAddressId() {
    if (this.props.selectedAddressId) {
      return this.props.selectedAddressId;
    }

    const defaultAddress = findWhere(this.props.addresses, { default: true });
    return defaultAddress?.id || null;
  }

  writeMembershipShipmentAddress = addressId => {
    const { updateMembershipShipmentAddress } = this.props;

    if (!this.isActiveEligibleInventory()) {
      return;
    }

    updateMembershipShipmentAddress(addressId);
  };

  onSuccessCallback = address => {
    const { fetchMembershipState, getOrCreateCECheckout, isSMBagEnabled, onChange } = this.props;

    onChange(address);

    if (!this.isActiveEligibleInventory()) {
      return;
    }

    if (!isSMBagEnabled) {
      getOrCreateCECheckout();
    }

    fetchMembershipState();
  };

  useAddress = selectedAddressId => {
    // using the selectedAddressId arguement instead of the state's selectedAddressId as the component calling it (MoleculeAddress -> MoleculeAddressSelect)
    // will either call useAddress with this.selectedAddressId passed down as a callback, or the defaultAddress if no address was initially selected.
    if (selectedAddressId) {
      const vals = this.handleSetAsDefault({ id: selectedAddressId });
      this.props.updateShippingAddress({ vals }, address => {
        this.writeMembershipShipmentAddress(address.id);
        this.onSuccessCallback(address);
      });
      this.logAction({
        action: "edit_address",
        is_new: false,
      });
    }

    this.handleClose();
  };

  onSubmitAddressFormSuccess = address => {
    this.writeMembershipShipmentAddress(address.id);

    if (this.props.isSMBagEnabled) {
      this.useAddress(address.id);
    }

    this.logAction({
      action: "edit_address",
      is_new: true,
    });
    this.handleClose();
  };

  selectAddress = id => {
    this.props.setAddressId(id);
  };

  handleClose = () => {
    this.props.shippingStepSubmittingReset();
    this.props.displayModal(false);
  };

  render() {
    const { displayedModal, modalName, isMobileViewport } = this.props;
    const isOpen = displayedModal && displayedModal === modalName;

    return (
      <VerifyAuthState
        authModalProps={{ callback: () => this.props.displayModal(modalName) }}
        isReadyToPromptForAuth={isOpen}
        requiredAuthState={AUTH_STATES.AUTHORIZED}>
        <ClosableModal
          isOpen={isOpen}
          onRequestClose={this.handleClose}
          optionalClass={classNames("change-default-shipping-address", {
            ["bottom-modal-drawer bottom-modal-drawer--full-content-visible"]: isMobileViewport,
          })}>
          <MoleculeAddress
            writeMembershipShipmentAddress={this.writeMembershipShipmentAddress}
            addressBookTitle={this.props.addressBookTitle}
            addressBookDek={this.props.addressBookDek}
            showMakeDefaultCheckbox={this.props.showMakeDefaultCheckbox}
            selectedAddressId={this.selectedAddressId()}
            onSelectAddress={this.selectAddress}
            selectAddress={this.selectAddress}
            subformTitle={"Add New Address"}
            useAddress={this.useAddress}
            onSubmitAddressFormSuccess={this.onSubmitAddressFormSuccess}
            handleFormElementValuesCallback={this.handleSetAsDefault}
            useSelectedAddressButtonText={this.props.useSelectedAddressButtonText}
            displayManageAddressCards={this.props.displayManageAddressCards}
          />
        </ClosableModal>
      </VerifyAuthState>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    displayModal: modalName => {
      dispatch(displayModal(modalName));
    },
    fetchMembershipState: () => {
      dispatch(fetchMembershipState());
    },
    getOrCreateCECheckout: () => {
      dispatch(ceCheckoutActions.getOrCreate());
    },
    setAddressId: id => {
      dispatch(setAddressId(id));
    },
    shippingStepSubmittingReset: () => {
      dispatch(shippingStepSubmittingReset());
    },
    updateMembershipShipmentAddress: addressId => {
      dispatch(updateMembershipShipmentAddress(addressId));
    },
    updateShippingAddress: (address, successCallback) => {
      dispatch(updateShippingAddress(address, successCallback));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isMobileViewport: state.browser?.isMobileViewport,
    userData: state.userData,
    membershipState: MembershipHelpers.getMembershipState(state),
    addresses: state.addresses,
    displayedModal: state.displayedModal,
    personalInfoLoggingData: ownProps.personalInfoLoggingData, // Used on the membership homepage
    selectedAddressId: state.selectedAddressId || "",
  };
};

const ConnectedChangeShippingAddressComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeShippingAddressComponent);

ConnectedChangeShippingAddressComponent.displayName = "ChangeShippingAddress";
export default ConnectedChangeShippingAddressComponent;

export const { displayName } = ConnectedChangeShippingAddressComponent;
