import React, { useState, useRef, useEffect } from "react";
import ActionLogger from "action-logger";
import PropTypes from "prop-types";
import { ceBagPropType, previewInvoicePropType } from "components/propTypes";
import { hasReserveRentals } from "helpers/checkout-helpers";
import { bagTabs, setBagTabId } from "actions/bag-actions";
import { hasReserveItems } from "helpers/ce-bag-helpers";
import { format, parseISO } from "date-fns";
import { dateFnsFormats } from "rtr-constants";
import { useDispatch, useSelector } from "react-redux";
import LeftArrow from "images/left-arrow.svg";

const TAB_NAME_WITH_RESERVE = "Event";
const TAB_NAME_KIFFR_PURCHASE = "Purchases";
const TAB_NAME_SUBSCRIPTION_PURCHASE = "Membership";

const BagToggle = props => {
  const bagTab = useSelector(state => state.bagTab);
  const bagTabId = useSelector(state => state.bagTabId);
  const dispatch = useDispatch();
  const tabContainerRef = useRef(null);
  const [hideLeftScrollButton, setHideLeftScrollButton] = useState(true);
  const [hideRightScrollButton, setHideRightScrollButton] = useState(true);
  const toggleBaseClass = "membership-toggle";

  const handleClassicClick = tabId => {
    log("toggled_classic");
    dispatch(setBagTabId(tabId));
    props.onToggle(bagTabs.CLASSIC_TAB);
  };

  const handleUnlimitedClick = () => {
    log("toggled_unlimited");
    dispatch(setBagTabId(bagTabs.MEMBERSHIP_TAB));
    props.onToggle(bagTabs.MEMBERSHIP_TAB);
  };

  const log = action => {
    ActionLogger.logAction({
      object_type: "shopping_bag",
      action: action,
    });
  };

  const bagGroupIdWithLatestCreatedAt = () => {
    const membershipBagItems = props.ceBag?.members?.bagItems;
    const mixedBagItems = props.ceBag?.mixed?.bagGroups;

    if (!mixedBagItems?.length) {
      if (props.isSubscriptionMember) return bagTabs.MEMBERSHIP_TAB;
      return bagTabs.CLASSIC_TAB;
    }

    const idToLatestCreatedAt = props.ceBag?.mixed.bagGroups?.reduce((acc, group) => {
      acc[group.id] = Math.max(...group.bagItems.map(b => b.createdAt).map(d => new Date(d)));
      return acc;
    }, {});

    if (idToLatestCreatedAt) {
      if (membershipBagItems?.length) {
        idToLatestCreatedAt[bagTabs.MEMBERSHIP_TAB] = Math.max(
          ...membershipBagItems.map(b => b.createdAt).map(d => new Date(d))
        );
      }

      return Object.entries(idToLatestCreatedAt).sort((a, b) => b[1] - a[1])[0][0];
    }

    return null;
  };

  const bagGroupIdWithLatestAddition = bagGroupIdWithLatestCreatedAt();

  useEffect(() => {
    if (bagGroupIdWithLatestAddition === bagTabs.MEMBERSHIP_TAB) {
      props.onToggle(bagTabs.MEMBERSHIP_TAB);
    } else {
      props.onToggle(bagTabs.CLASSIC_TAB);
    }

    dispatch(setBagTabId(bagGroupIdWithLatestAddition));
  }, [bagGroupIdWithLatestAddition]);

  useEffect(() => {
    if (!props.ceBag?.mixed?.bagGroups) return;

    // If all items in a mixed bag have been removed, recalculate the most recently added to bag tab id
    if (!props.ceBag?.mixed?.bagGroups.find(bagGroup => bagGroup.id === bagTabId)) {
      dispatch(setBagTabId(bagGroupIdWithLatestAddition));
    }
  }, [props.ceBag?.mixed?.bagGroups?.length]);

  const showTabScrollButtons = () => {
    if (props.isMobileViewport) return;
    const tabContainer = tabContainerRef.current;

    if (tabContainer.scrollLeft === 0) {
      setHideLeftScrollButton(true);
      setHideRightScrollButton(false);
    } else if (tabContainer.scrollLeft + tabContainer.clientWidth >= tabContainer.scrollWidth - 10) {
      // Notes on above conditional: Sometimes the scroll overshoots by .5 pixels hence >=. Also sometimes
      // we "almost" scroll to the end (within a few pixels), in this case it doesn't make sense
      // for the user to have to click the arrow _again_ to hide it, instead just allow it to be within 10 pixels
      setHideRightScrollButton(true);
      setHideLeftScrollButton(false);
    } else {
      setHideLeftScrollButton(false);
      setHideRightScrollButton(false);
    }
  };

  useEffect(() => {
    if (!props.bagIsOpen) return;

    scrollTabs("center");
  }, [props.bagIsOpen, bagTabId]);

  useEffect(() => {
    if (!props.ceBag) return;

    const tabContainer = tabContainerRef?.current;
    if (!tabContainer) return;

    if (tabContainer.scrollWidth > tabContainer.clientWidth) {
      showTabScrollButtons();

      if (tabContainer) {
        tabContainer.addEventListener("scroll", showTabScrollButtons);
      }

      return () => {
        if (tabContainer) {
          tabContainer.removeEventListener("scroll", showTabScrollButtons);
        }
      };
    }
  }, [props.ceBag, props.bagIsOpen]);

  const renderMembershipModifierClass = () => {
    return `${toggleBaseClass}__label--active-${bagTab === bagTabs.MEMBERSHIP_TAB}`;
  };

  const renderClassicModifierClass = id => {
    return `${toggleBaseClass}__label--active-${bagTab !== bagTabs.MEMBERSHIP_TAB && bagTabId === id}`;
  };

  const generateReserveDateCopy = (defaultHeader, bagGroup) => {
    if (bagGroup?.beginDate && bagGroup?.endDate) {
      let endDate;
      if (new Date(parseISO(bagGroup.beginDate)).getMonth() === new Date(parseISO(bagGroup.endDate)).getMonth()) {
        endDate = format(parseISO(bagGroup.endDate), dateFnsFormats.dayOfMonth);
      } else {
        endDate = format(parseISO(bagGroup.endDate), dateFnsFormats.MMM_D);
      }

      return (
        <>
          <span>{defaultHeader}</span>
          <span className={toggleBaseClass + "__date-range"}>
            {format(parseISO(bagGroup.beginDate), dateFnsFormats.MMM_D)}-{endDate}
          </span>
        </>
      );
    } else {
      return defaultHeader;
    }
  };

  const renderReserveCopy = bagGroup => {
    const { ceBag, previewInvoice } = props;

    if (bagGroup?.bagGroupType === "SUBSCRIPTION") {
      return TAB_NAME_SUBSCRIPTION_PURCHASE;
    }

    const hasReserve = typeof ceBag !== "undefined" ? hasReserveItems(ceBag) : hasReserveRentals(previewInvoice);

    return hasReserve ? generateReserveDateCopy(TAB_NAME_WITH_RESERVE, bagGroup) : TAB_NAME_KIFFR_PURCHASE;
  };

  const scrollTabs = direction => {
    const tabContainer = tabContainerRef.current;
    if (!tabContainer) return;
    const scrollAmount = tabContainer.clientWidth * 0.66;

    if (direction === "left") {
      tabContainer.scrollTo({
        left: tabContainer.scrollLeft - scrollAmount,
        behavior: "smooth",
      });
    } else if (direction === "right") {
      tabContainer.scrollTo({
        left: tabContainer.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    } else {
      const selectedTab = tabContainer.querySelector(`.${toggleBaseClass}__label--active-true`);

      if (selectedTab) {
        tabContainer.scrollTo({
          left: selectedTab.offsetLeft - tabContainer.clientWidth / 2 + selectedTab.clientWidth / 2,
          behavior: "smooth",
        });
      }
    }
  };

  const renderToggleOptions = () => {
    const { isSubscriptionMember, ceBag } = props;
    const bagGroups = ceBag?.mixed?.bagGroups || [];
    const purchasingBagGroups = bagGroups.filter(o => o?.bagGroupType === "PURCHASES");
    const reserveBagGroups = bagGroups.filter(o => o?.bagGroupType !== "PURCHASES");
    const sortReserveBagGroups = reserveBagGroups.sort(
      (a, b) => new Date(parseISO(a.beginDate || "")) - new Date(parseISO(b.beginDate || ""))
    );

    return (
      <div data-test-id="bag-toggle-tab-container" ref={tabContainerRef} className={toggleBaseClass + "__labels"}>
        <button
          data-test-id="bag-toggle-left-arrow"
          onClick={() => scrollTabs("left")}
          className={`${toggleBaseClass}__left-button ${hideLeftScrollButton ? "hidden" : ""}`}>
          <LeftArrow />
        </button>
        {isSubscriptionMember && (
          <button
            aria-label="membership tab"
            className={renderMembershipModifierClass()}
            onClick={handleUnlimitedClick}>
            Membership
          </button>
        )}

        {purchasingBagGroups.map((purchasingBagGroup, i) => (
          <button
            aria-label={`purchase tab`}
            key={i}
            className={renderClassicModifierClass(purchasingBagGroup.id)}
            onClick={() => {
              handleClassicClick(purchasingBagGroup.id);
            }}>
            Purchasing
          </button>
        ))}

        {sortReserveBagGroups.map((group, i) => {
          return (
            <button
              aria-label={`reserve tab ${i}`}
              key={i}
              className={renderClassicModifierClass(group.id)}
              onClick={() => {
                handleClassicClick(group.id);
              }}>
              {renderReserveCopy(group)}
            </button>
          );
        })}
        <button
          data-test-id="bag-toggle-right-arrow"
          onClick={() => scrollTabs("right")}
          className={`${toggleBaseClass}__right-button ${hideRightScrollButton ? "hidden" : ""}`}>
          <LeftArrow />
        </button>
      </div>
    );
  };

  const { isSubscriptionMember, ceBag } = props;
  const bagGroups = ceBag?.mixed?.bagGroups || [];

  // if subscription user with no reserve/purchase tab dont show bag toggle
  // if reserve user with either none or one reserve/purchase tab dont show toggle
  if ((isSubscriptionMember && bagGroups.length === 0) || (!isSubscriptionMember && bagGroups.length <= 1)) {
    return null;
  }

  return <div className={toggleBaseClass}>{renderToggleOptions()}</div>;
};

BagToggle.propTypes = {
  ceBag: ceBagPropType,
  isSubscriptionMember: PropTypes.bool,
  onToggle: PropTypes.func,
  previewInvoice: previewInvoicePropType,
  bagIsOpen: PropTypes.bool,
  isMobileViewport: PropTypes.bool,
};

export default BagToggle;
