import { useState, useEffect } from "react";
import Proptypes from "prop-types";
import { useSelector } from "react-redux";
import { CEBagErrors } from "rtr-constants";
import AtomErrorNotification from "components/source/atoms/atom-error-notification";
import ExclamationRedFillIcon from "images/sprites/reb/svg/exlam-icon-white-3x.svg";
import ExclamationMidnightFillIcon from "images/sprites/reb/svg/exlam-icon-midnight-fill-3x.svg";

const propTypes = {
  statusMessageRef: Proptypes.object,
};

const SMBagStatusMessage = ({ statusMessageRef }) => {
  const [message, setMessage] = useState("");

  const smBagError = useSelector(state => state.smBag?.smBagError) || "";
  const ceCheckoutError = useSelector(state => state.ceCheckoutError) || "";
  const failedToRefreshItems = useSelector(state => state.smBag?.failedToRefreshItems) || [];

  const isWarningBanner = failedToRefreshItems?.length > 0;

  useEffect(() => {
    if (isWarningBanner) {
      setMessage("Some of your items are no longer available. Please remove and select something new.");
    } else if ([smBagError, ceCheckoutError].some(val => val)) {
      setMessage([smBagError, ceCheckoutError].find(val => val?.length > 0) || CEBagErrors.BAG_GENERIC_ERROR);
    }
  }, [smBagError, ceCheckoutError, isWarningBanner]);

  return (
    <AtomErrorNotification
      additionalClassName={`sm-bag-status-message ${isWarningBanner ? "warning" : "error"}`}
      errorIcon={isWarningBanner ? <ExclamationMidnightFillIcon /> : <ExclamationRedFillIcon />}
      errorCopy={message}
      forwardedRef={statusMessageRef}
    />
  );
};

SMBagStatusMessage.propTypes = propTypes;
export default SMBagStatusMessage;
