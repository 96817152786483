import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { rtrAddressPropType } from "components/propTypes";

const validDatePartKeys = ["street1", "street2", "city", "state", "zoneCode", "zipCode", "postalCode", "zipcode"];

const baseClassName = "inline-address";

export default class AtomInlineAddress extends React.Component {
  static propTypes = {
    address: rtrAddressPropType.isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    isWrappable: PropTypes.bool,
  };

  static defaultProps = {
    isWrappable: false,
  };

  getName() {
    const {
      name,
      address: { name: addressName, company },
    } = this.props;

    return name || addressName || company;
  }

  /*
   * Before rendering the address, we first want to filter out values that are falsey (undefined, null, "", etc.).
   * Also, the values need to be rendered in a very specific order: name goes first, zip code goes last, etc.
   * For this reason, when doing this filtering we want to iterate over an array of key names that we specify
   * rather than simply iterate over the keys in the object (which has no guaranteed order).
   */
  getAddressParts() {
    const { address } = this.props;

    // Create an array of truthy address values in the order specified by showroomAddressKeys
    return _.reduce(
      validDatePartKeys,
      (memo, key) => {
        if (address[key]) {
          memo.push(address[key]);
        }
        return memo;
      },
      []
    );
  }

  renderName() {
    const name = this.getName();

    if (!name) {
      return;
    }

    return <span className={`${baseClassName}--bold`}>{`${name}, `}</span>;
  }

  render() {
    let style;
    let { className = "" } = this.props;
    const { address, isWrappable } = this.props;

    if (!address) {
      return null;
    }

    const inlineAddress = this.getAddressParts().join(", ");

    if (!inlineAddress.length) {
      return null;
    }

    if (!isWrappable) {
      className += " no-wrap";
    }

    return (
      <span
        className={`${baseClassName} ${className}`}
        style={style}
        title={inlineAddress}
        data-heap-redact-attributes="title"
        data-heap-redact-text="true">
        {this.renderName()}
        {inlineAddress}
      </span>
    );
  }
}
