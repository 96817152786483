import PropTypes from "prop-types";
import { idPropType } from "./id-prop-type";
import { ceBagItemPropType } from "./ce-bag-item-prop-type";

export const ceBagGroupPropType = PropTypes.shape({
  id: idPropType,
  bagGroupType: PropTypes.oneOf(["RENTALS", "PURCHASES", "SUBSCRIPTION"]),
  bagItems: PropTypes.arrayOf(ceBagItemPropType),
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  zipCode: PropTypes.string,
  pricing: PropTypes.shape({
    groupId: PropTypes.string,
    subTotal: PropTypes.string,
  }),
});
