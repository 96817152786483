import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import { NewCheckout } from "routes";

const actions = {
  applyPromoSubmitting: createAction(ActionTypes.APPLY_PROMO_SUBMITTING),
  applyPromoSuccess: createAction(ActionTypes.APPLY_PROMO_SUCCESS),
  applyPromoFailure: createAction(ActionTypes.APPLY_PROMO_FAILURE),
  removePromoSubmitting: createAction(ActionTypes.REMOVE_PROMO_SUBMITTING),
  removePromoSuccess: createAction(ActionTypes.REMOVE_PROMO_SUCCESS),
  removePromoFailure: createAction(ActionTypes.REMOVE_PROMO_FAILURE),

  applyPromo: function (payload, onSuccess, onFailure) {
    return function (dispatch) {
      const { orderId, code } = payload;
      dispatch(actions.applyPromoSubmitting(payload));
      $.ajax({
        url: NewCheckout.promoCode(orderId),
        type: "PATCH",
        data: { promoCode: code },
      }).then(
        data => {
          dispatch(actions.applyPromoSuccess({ code, data }));
          if (onSuccess) {
            onSuccess(data, code);
          }
        },
        err => {
          dispatch(actions.applyPromoFailure({ message: err.responseText, code }));
          if (onFailure) {
            onFailure(err.responseText, code);
          }
        }
      );
    };
  },

  removePromo: function (payload, onSuccess, onFailure) {
    return function (dispatch) {
      const { orderId, code } = payload;
      dispatch(actions.removePromoSubmitting(payload));
      $.ajax({
        url: NewCheckout.promoCode(orderId),
        type: "DELETE",
      }).then(
        data => {
          dispatch(actions.removePromoSuccess(data));
          if (onSuccess) {
            onSuccess(data);
          }
        },
        err => {
          dispatch(actions.removePromoFailure({ message: err.responseText, code }));
          if (onFailure) {
            onFailure(err.responseText, code);
          }
        }
      );
    };
  },
};

export default actions;

export const {
  applyPromoSubmitting,
  applyPromoSuccess,
  applyPromoFailure,
  removePromoSubmitting,
  removePromoSuccess,
  removePromoFailure,
  applyPromo,
  removePromo,
} = actions;
