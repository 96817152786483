import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import SMBagActions from "actions/sm-bag-actions";
import CEBagActions from "actions/ce-bag-actions";
import MembershipHelpers from "helpers/membership-helpers";
import BagBannerShipmentCounter from "./sm/layout/sm-bag-banner-shipment-counter";
import BagFooter from "./sm/layout/sm-bag-footer";
import BagStatusMessage from "./sm/layout/sm-bag-status-message";

import HeadedYourWayComponent from "./sm/sections/sm-bag-headed-your-way";
import SMBagReplacementSpot from "./sm/pages/sm-bag-replacement-spot";
import KeepingItemsComponent from "./sm/sections/sm-bag-keeping-items";
import ReturningItemsComponent from "./sm/sections/sm-bag-returning-items";
import EmptyBagComponent from "components/source/checkout/bag/sm/pages/sm-bag-empty-bag";
import OutOfShipments from "components/source/checkout/bag/membership/out-of-shipments";
import RtrImage from "../../shared/rtr-image";
import SnackBarContainer from "components/source/shared/snack-bar-container";

import { getRoktLauncher } from "helpers/third-party/rokt-helpers";

const SMBagComponent = () => {
  const dispatch = useDispatch();
  const [isAbleToScroll, setIsAbleToScroll] = React.useState(false);
  const [showStatusMessage, setShowStatusMessage] = React.useState(null);
  const [refToScrollTo, setRefToScrollTo] = React.useState(null);
  const [refToMoveTo, setRefToMoveTo] = React.useState(null);

  const topOfTheBagRef = React.useRef(null);
  const returningItemsRef = React.useRef(null);
  const keepingItemsRef = React.useRef(null);
  const statusMessageRef = React.useRef(null);

  const bagIsOpen = useSelector(state => state.bagIsOpen) || false;
  const membershipState = useSelector(state => state.membershipState) || {};

  const hasOrderHistory = useSelector(state => state.smBag?.hasOrderHistory) || false;
  const isInSwapFlow = useSelector(state => state.smBag?.isInSwapFlow) || false;
  const itemsToShip = useSelector(state => state.smBag?.items?.arriving) || [];
  const itemsAtHome = useSelector(state => state.smBag?.items?.keeping) || [];
  const numShipmentsPerTerm = useSelector(state => state.smBag?.numShipmentsPerTerm) || 0;
  const isReplacementSwap = useSelector(state => state.smBag?.isReplacementSwap) || false;
  const smBagError = useSelector(state => state.smBag?.smBagError) || "";
  const smBagIsFull = useSelector(state => state.smBag?.isBagFull) || false;
  const smBagLoading = useSelector(state => state.smBag?.smBagLoading) || false;
  const failedToRefreshItems = useSelector(state => state.smBag?.failedToRefreshItems) || [];

  const ceCheckoutError = useSelector(state => state.ceCheckoutError) || "";
  const ceCheckoutSubmitting = useSelector(state => state.ceCheckoutSubmitting) || false;
  const ceBagCount = useSelector(state => state.ceBagCount) || {};
  const ceBag = useSelector(state => state.ceBag) || {};
  const ceBagLoading = useSelector(state => state.ceBagLoading) || false;
  const ceCheckoutPromoLoading = useSelector(state => state.ceCheckoutPromoLoading) || false;

  const isBagLoading = () => {
    return smBagLoading || ceCheckoutSubmitting || ceCheckoutPromoLoading || ceBagLoading;
  };

  React.useEffect(() => {
    if (bagIsOpen) {
      dispatch(SMBagActions.fetchSMBag(false, true));
      setRefToMoveTo(topOfTheBagRef);

      /**
       * instantiate the rokt launcher on opening the membership bag, though
       * we won't be using it at this time,
       */
      dispatch(getRoktLauncher()).catch(_ => {});
    }
  }, [bagIsOpen]);

  React.useEffect(() => {
    if (ceBagCount?.members < ceBagCount?.total && !ceBag.members) {
      dispatch(CEBagActions.get());
    }
  }, [ceBagCount, ceBag.members]);

  // checks loading state and triggers a state change to scroll to the bottom
  React.useEffect(() => {
    if (bagIsOpen && !isBagLoading()) {
      setIsAbleToScroll(true);
    }
  }, [bagIsOpen, smBagLoading, ceCheckoutSubmitting, ceCheckoutPromoLoading, ceBagLoading]);

  // controls scrolling behavior AFTER loading is clear
  React.useEffect(() => {
    if (refToMoveTo?.current && isAbleToScroll) {
      refToMoveTo.current.scrollIntoView({ behavior: "instant" });
      setIsAbleToScroll(false);
      setRefToMoveTo(null);
    } else if (refToScrollTo?.current && isAbleToScroll) {
      refToScrollTo.current.scrollIntoView({ behavior: "smooth" });
      setIsAbleToScroll(false);
      setRefToScrollTo(null);
    }
  }, [refToScrollTo, isAbleToScroll, refToMoveTo]);

  // controls when to scroll to the bottom AND status message behavior AFTER the page is loading
  React.useEffect(() => {
    if (smBagIsFull || failedToRefreshItems.length > 0) {
      if ([smBagError, ceCheckoutError].some(val => val) || failedToRefreshItems.length > 0) {
        setShowStatusMessage(true);
        setRefToScrollTo(statusMessageRef);
      } else {
        setShowStatusMessage(false);
      }
    } else {
      setShowStatusMessage(false);
    }
  }, [smBagIsFull, smBagError, ceCheckoutError, failedToRefreshItems]);

  const isBagEmpty = !isReplacementSwap && hasOrderHistory && !isInSwapFlow && !itemsToShip?.length;
  const containerClass = classNames("simplify-membership-bag-container", {
    "empty-bag": isBagEmpty && !itemsAtHome.length,
    "loading": isBagLoading(),
  });
  const loadingContainerClass = classNames("simplify-membership-bag-loading-overlay", {
    hidden: !bagIsOpen,
  });

  const renderEmptyBag = () => {
    if (!MembershipHelpers.hasShipmentsCount(membershipState) || itemsAtHome.length) {
      return (
        <OutOfShipments
          shipments={numShipmentsPerTerm}
          unlockDate={MembershipHelpers.fetchNextPickDate(membershipState)}
        />
      );
    }

    return <EmptyBagComponent />;
  };

  const renderBody = () => {
    let content;
    if (isReplacementSwap) {
      content = <SMBagReplacementSpot />;
    } else if (isBagEmpty) {
      content = renderEmptyBag();
    } else {
      content = (
        <>
          {showStatusMessage && <BagStatusMessage statusMessageRef={statusMessageRef} />}
          <BagBannerShipmentCounter />
          <div className="simplify-membership-bag-content">
            <HeadedYourWayComponent removeBottomBorder={!hasOrderHistory} />
            {hasOrderHistory && (
              <>
                <KeepingItemsComponent
                  keepingItemsRef={keepingItemsRef}
                  scrollToReturning={() => setRefToScrollTo(returningItemsRef)}
                />
                <ReturningItemsComponent
                  removeBottomBorder={smBagIsFull}
                  returningItemsRef={returningItemsRef}
                  scrollToKeeping={() => setRefToScrollTo(keepingItemsRef)}
                />
              </>
            )}
          </div>
        </>
      );
    }
    return (
      <>
        {content}
        <BagFooter />
      </>
    );
  };

  return (
    <div className={containerClass} ref={topOfTheBagRef} data-test-id="sm-bag">
      {isBagLoading() && (
        <div className={loadingContainerClass}>
          <RtrImage src="//sf-p.rtrcdn.com/images/loading.gif" alt="Loading animation" />
        </div>
      )}
      <>{renderBody()}</>
      <SnackBarContainer bagContext={true} />
    </div>
  );
};

export default SMBagComponent;
