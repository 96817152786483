import { useRef } from "react";
import PropTypes from "prop-types";

import WorldIcon from "images/world.svg";
import { TOOLTIP } from "rtr-constants";
import MoleculeTooltip from "components/source/molecules/tooltip/molecule-tooltip";
import { Flags, useFeatureFlag } from "../../hoc/with-feature-flags";

const Emissions = ({ additionalClassNames }) => {
  const emissionsRef = useRef(null);
  const EMISSIONS_MSG_ENABLED = useFeatureFlag(Flags.EMISSIONS_MSG);

  if (!EMISSIONS_MSG_ENABLED) {
    return null;
  }

  const toolTipContent =
    "RTR partners with trusted third parties to offset estimated carbon emissions from shipping to and from customers.";

  return (
    <div className={`emissions ${additionalClassNames ? additionalClassNames : ""}`}>
      <WorldIcon role="img" />
      <span className="universal-xsmall emissions-text">
        We{" "}
        <span className="universal-underline-dashed" ref={emissionsRef}>
          offset all CO&#8322; emissions
        </span>{" "}
        from shipping orders
      </span>
      <MoleculeTooltip
        className={`membership-footer__tooltip`}
        target={emissionsRef?.current}
        placement={TOOLTIP.PLACEMENTS.TOP}
        mode={TOOLTIP.MODE.HOVER}
        closeOnScroll={true}>
        <div className="membership-footer__tooltip-content">{toolTipContent}</div>
      </MoleculeTooltip>
    </div>
  );
};

Emissions.propTypes = {
  additionalClassNames: PropTypes.string,
};

export default Emissions;
