import PropTypes from "prop-types";
import { chargeOrTotalPropType, idPropType, invoiceOrderGroupPropType } from "./";

/*
 * In the previewInvoice, there are
 * charges and totals on the order, group,
 * and lineItem levels, so for brevity --
 *
 * All 'totals' and 'charges' props take this form:
 *
 *  totals: PropTypes.arrayOf(
 *    PropTypes.shape({
 *      type: PropTypes.string,
 *      amount: PropTypes.string
 *    })
 *  )
 *
 * There is a mock previewInvoice object here for
 * for reference: spec/javascripts/react/fixtures/previewInvoice.js
 */

export const previewInvoicePropType = PropTypes.shape({
  type: PropTypes.string,
  id: idPropType,
  sequence: PropTypes.number,
  orderId: PropTypes.number,
  totals: PropTypes.arrayOf(chargeOrTotalPropType),
  groups: PropTypes.arrayOf(invoiceOrderGroupPropType),
});
