import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { priceStringIntoFormattedNumber } from "helpers/invoice-helper";
import TooltipInline from "components/source/shared/tooltip-inline";
import LightningCircledIcon from "images/sprites/reb/svg/lightning_circled.svg";
import BagBannerComponent from "../components/sm-bag-banner-component";

const upgradesBannerProptypes = {
  upgradesInMembershipBag: PropTypes.number.isRequired,
  numberOfSlots: PropTypes.number.isRequired,
  upgradePrice: PropTypes.string,
};

const UpgradesBanner = ({ upgradesInMembershipBag, numberOfSlots, upgradePrice }) => {
  const [isToolTipOpen, setIsToolTipOpen] = React.useState(false);

  const onToolTip = (event, isVisible) => {
    if (event) {
      event.preventDefault();
    }
    setIsToolTipOpen(isVisible);
  };

  const onMouseOver = event => {
    if (event) {
      event.preventDefault();
    }
    setIsToolTipOpen(true);
  };
  const onMouseOut = event => {
    if (event) {
      event.preventDefault();
    }
    setIsToolTipOpen(false);
  };
  return (
    <>
      <LightningCircledIcon className="lightning-icon" title="lightning-icon" />
      <div className="copy">
        You&#39;re upgrading to a&nbsp;
        {/* disabling as we need event handlers to have this tooltip to trigger*/}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <div onMouseOver={onMouseOver} onFocus={onMouseOver} onMouseOut={onMouseOut} onBlur={onMouseOut} role="tooltip">
          <span className="universal-medium universal-underline-dashed">
            {upgradesInMembershipBag + numberOfSlots}-spot plan.
          </span>
          <TooltipInline
            display={isToolTipOpen}
            onToggleToolTip={(...args) => onToolTip(null, args)}
            align="right"
            className="upgrade-plan-tooltip">
            <p>
              You&#39;ve added more items than your plan includes. Rent extra for ${upgradePrice} per item or adjust
              your bag to match your {numberOfSlots}-spot plan.
            </p>
          </TooltipInline>
        </div>
      </div>
    </>
  );
};

function BagBannerShipmentCounter() {
  const itemsToReturn = useSelector(state => state.smBag?.items?.returning) || [];
  const itemsToShip = useSelector(state => state.smBag?.items?.arriving) || [];
  const shipmentsCount = useSelector(state => state.smBag?.numShipmentsRemaining) || 0;
  const monthlyShipmentLimit = useSelector(state => state.smBag?.numShipmentsPerTerm) || 0;
  const baseSlotUpgradePrice = useSelector(state => state.smBag?.baseSlotUpgradePrice) || "USD 31.00";

  const numberOfSlots = useSelector(state => state.smBag?.baseSlotCount) || 0;
  const upgradesInMembershipBag = useSelector(state => state.smBag?.numUpgradesInBag) || 0;

  const additionalClassName = upgradesInMembershipBag ? "upgrade" : "";
  const headerText = `Shipment ${monthlyShipmentLimit - shipmentsCount + 1} of ${monthlyShipmentLimit} for this month`;
  const subHeader = upgradesInMembershipBag ? (
    <UpgradesBanner
      upgradesInMembershipBag={upgradesInMembershipBag}
      numberOfSlots={numberOfSlots}
      upgradePrice={priceStringIntoFormattedNumber(baseSlotUpgradePrice, true)}
    />
  ) : (
    `You have a ${numberOfSlots}-spot per shipment plan.`
  );

  if (shipmentsCount > monthlyShipmentLimit && !(itemsToShip.length || itemsToReturn.length)) {
    return null;
  }

  return <BagBannerComponent additionalClassName={additionalClassName} headerText={headerText} subHeader={subHeader} />;
}

UpgradesBanner.propTypes = upgradesBannerProptypes;

export default BagBannerShipmentCounter;
