import PropTypes from "prop-types";

/*
 * In the previewInvoice, there are
 * charges and totals on the order, group,
 * and lineItem levels, so for brevity --
 *
 * All 'totals' and 'charges' props take this form:
 *
 *  totals: PropTypes.arrayOf(
 *    PropTypes.shape({
 *      type: PropTypes.string,
 *      amount: PropTypes.string
 *    })
 *  )
 *
 * There is a mock previewInvoice object here for
 * for reference: spec/javascripts/react/fixtures/previewInvoice.js
 */

// TODO: i18n
export const AMOUNT_REGEX = /^USD (?:- ?)?\$?[\d,]+(\.\d+)?$/;

export const chargeOrTotalPropType = PropTypes.shape({
  type: PropTypes.string,

  amount: (props, propName, componentName) => {
    // this isRequired
    if (AMOUNT_REGEX.test(props[propName])) {
      return;
    }

    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}': '${props[propName]}'. Validation failed.`
    );
  },
});

export const chargeOrTotalArrayPropType = PropTypes.arrayOf(chargeOrTotalPropType);
