import PropTypes from "prop-types";
import { CEBagGroupTypes } from "rtr-constants";
import styles from "./styles.module.scss";

const Header = ({ type, text }) => {
  return (
    <div className={`${styles.header} ${styles[type]}`}>
      <div className="universal-medium">{text}</div>
    </div>
  );
};

Header.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(CEBagGroupTypes)),
};

export default Header;
