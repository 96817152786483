import PropTypes from "prop-types";

const propTypes = {
  percentOff: PropTypes.number,
  purchasePrice: PropTypes.number,
  retailPrice: PropTypes.number,
};

const SmBagPricingDetail = ({ percentOff, purchasePrice, retailPrice }) => {
  const renderRetailPrice = () => {
    if (percentOff && retailPrice) {
      return <span className="detail-retail-price">{`$${retailPrice}`}</span>;
    }
  };

  const renderPurchasePrice = () => {
    if (purchasePrice) {
      return <div className="detail-purchase-price">{`$${purchasePrice}`}</div>;
    }
  };

  const renderPercentOff = () => {
    if (percentOff && retailPrice) {
      return <span className="detail-percent-off">{`-${percentOff}%`}</span>;
    }
  };

  if (purchasePrice) {
    return (
      <div className="pricing-detail" data-test-id="bag-pricing-detail">
        {renderPurchasePrice()}
        <div className="retail-data">
          {renderPercentOff()}
          {renderRetailPrice()}
        </div>
      </div>
    );
  }
};

SmBagPricingDetail.propTypes = propTypes;
export default SmBagPricingDetail;
