import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isZeroAmount, formattedPrice, formatSavingsAsPositive } from "helpers/invoice-helper";
import { membershipIsBilledToApplePay } from "helpers/payment-options-helper";
import { getBillingInfo, getBillingPaymentMethod } from "helpers/payment-method-helpers";
import { usdPriceIntoFloat } from "helpers/PricingHelper";
import { CECheckout, ChangePaymentMethodModalNames, flagsAndExperimentNames } from "rtr-constants";
import CECheckoutActions from "actions/ce-checkout-actions";
import { onEnterPress } from "helpers/a11y-helper";

import Terms from "components/source/checkout/bag/membership/terms";
import AtomButton from "components/source/atoms/atom-button";
import ManagePaymentMethods from "components/source/shared/manage-payment-methods";
import sharedActions from "actions/shared-actions";
import SMBagPromoCode from "components/source/checkout/bag/sm/components/sm-bag-promo-code";
import ApplePaySectionBag from "../../apple-pay-section-bag";
import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";
import { useFlagOrExperiment } from "components/source/hoc/with-flags-and-experiments";
import { isCanceled } from "helpers/membership-plan-helpers";
import MembershipHelpers from "helpers/membership-helpers";
import { addSnackBar } from "actions/snack-bar-actions";
import { bagTabs, switchBagTab } from "actions/bag-actions";

import * as membershipStateActions from "actions/membership-state-actions";
import { fireCEHeapEvent } from "helpers/heap-ce-helpers";

const SMKifahFooterComponent = () => {
  const dispatch = useDispatch();
  const checkout = useSelector(state => state?.ceCheckoutKifah);
  const promoError = useSelector(state => state?.ceCheckoutPromoError);
  const isLoading = useSelector(state => state?.ceBagLoading) || false;
  const promoLoading = useSelector(state => state?.ceCheckoutPromoLoading);
  const membershipState = useSelector(state => state.membershipState);
  const paymentMethods = useSelector(state => state.paymentProfiles);

  const showApplePayForKifah = useFlagOrExperiment(flagsAndExperimentNames?.COMET_APPLE_PAY_KIFAH);

  useEffect(() => {
    if (typeof showApplePayForKifah !== "undefined") return;
    dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames?.COMET_APPLE_PAY_KIFAH));
  }, []);

  const displayModal = modalName => {
    dispatch(sharedActions.displayModal(modalName));
  };

  const openPaymentProfileSelector = () => {
    displayModal(ChangePaymentMethodModalNames.changePaymentMethodFromBag);
  };

  const handleNonMembersCheckout = checkoutId => {
    const toastCopy =
      isCanceled(membershipState) || MembershipHelpers.isOutOfShipments(membershipState)
        ? "Purchase confirmed"
        : "Spot opened";
    dispatch(addSnackBar({ copyPrimary: toastCopy, duration: 5000, bagContext: true }));
    dispatch(membershipStateActions.fetchMembershipState());
    dispatch(switchBagTab(bagTabs.MEMBERSHIP_TAB));
    dispatch(fireCEHeapEvent("placeKifahOrderSuccess", { checkoutId }));
  };

  const renderBillingId = () => {
    const activeCard = checkout?.paymentMethods?.[0] || getBillingPaymentMethod(paymentMethods);

    const billingInfo = getBillingInfo(activeCard, false);

    return (
      <button
        data-test-id="kifah-billing-info"
        className="membership-footer__billing membership-footer__line-item"
        onKeyDown={onEnterPress(() => openPaymentProfileSelector())}
        onClick={() => openPaymentProfileSelector()}>
        <div className="membership-footer__billing-label">
          <h5 className="bag-section-heading">Payment</h5>
        </div>
        {billingInfo.creditCardIcon}
        <span className="membership-footer__billing_info body-copy body-copy--disabled">
          {billingInfo.billingInfoCopy}
        </span>
        <div className="membership-footer__edit">
          <div className="billing-edit"></div>
        </div>
      </button>
    );
  };

  const renderChargeLineItem = (description, value, originalValue = null, isPromoSavings = false) => {
    if (!description || !value) {
      return null;
    }

    const isDiscount = originalValue && usdPriceIntoFloat(value) < usdPriceIntoFloat(originalValue);
    const lineItemClassName = "membership-footer__summary__charges__line-item";

    return (
      <p className={`${lineItemClassName} body-copy${isPromoSavings ? " promo" : ""}`}>
        <span className={`${lineItemClassName}__description`}>{description}</span>
        <span className={`${lineItemClassName}__value`}>
          {isDiscount && <span className="original-price">{formattedPrice(originalValue, false)}</span>}
          {formattedPrice(value, false)}
        </span>
      </p>
    );
  };

  const onApplyPromoCode = (checkoutId, promoCode) => {
    dispatch(CECheckoutActions.applyPromoCode(checkoutId, promoCode, true));
  };

  const onRemovePromoCode = checkoutId => {
    dispatch(CECheckoutActions.removePromoCode(checkoutId, true));
  };

  const renderPromoCodeField = () => {
    return (
      <div
        className="membership-footer__promo-code membership-footer__summary__final body-copy"
        data-test-id="kifah-promo-code-field">
        <SMBagPromoCode
          checkoutId={checkout?.id}
          appliedPromoCode={checkout?.promoCode ?? null}
          appliedPromoSavings={formatSavingsAsPositive(checkout?.charges?.totals?.promoItem) ?? null}
          promoCodeError={promoError}
          onApply={onApplyPromoCode}
          onRemove={onRemovePromoCode}
        />
      </div>
    );
  };

  const onChangePaymentMethod = paymentMethod => {
    // paymentMethod would be null if the user closes the modal without changing the payment method,
    // so do nothing if that's the case.

    if (checkout?.id && paymentMethod?.id) {
      dispatch(CECheckoutActions.setPaymentMethod(checkout.id, paymentMethod.id, true));
    }
  };

  const priceIfNotZero = price => (isZeroAmount(price) ? null : price);

  const renderPrice = () => {
    const sub = priceIfNotZero(checkout.charges?.totals?.sub);
    const originalPrice = priceIfNotZero(checkout.charges?.totals?.prePromoSub);
    const promoSavings = priceIfNotZero(checkout.charges?.totals?.promoItem);
    const taxTotal = priceIfNotZero(checkout.charges?.totals?.tax);
    const total = checkout?.expectedPayments?.CREDIT_CARD ?? "USD 0.00";

    return (
      <div className="membership-footer__summary membership-footer__line-item" data-test-id="kifah-footer-summary">
        <section className="membership-footer__summary__label">
          <h5 className="bag-section-heading">Order Summary</h5>
        </section>

        {renderPromoCodeField()}

        <div className="membership-footer__summary__charges">
          {renderChargeLineItem("Subtotal", sub, originalPrice)}
          {renderChargeLineItem("Promo Savings", promoSavings, null, true)}
          {renderChargeLineItem("Tax", taxTotal)}
        </div>
        <div className="membership-footer__summary__final">
          <span className="membership-footer__summary__final__description">Total</span>
          <span className="membership-footer__summary__final__value">{formattedPrice(total, false)}</span>
        </div>
      </div>
    );
  };

  if (!checkout) {
    return null;
  }

  const renderConfirmationCTA = () => {
    const buttonText = showApplePayForKifah && window.ApplePaySession ? "Buy with Card On File" : "Confirm Purchase";
    return (
      <AtomButton
        className="confirm-button kifah-confirm-button"
        onClick={() => {
          dispatch(CECheckoutActions.placeOrderKifah(checkout.id));
        }}
        buttonText={buttonText}
        disabled={
          !!isLoading ||
          !!promoError ||
          promoLoading ||
          checkout.checkoutStatus.toLowerCase() !== CECheckout.CheckoutStatuses.VALIDATED
        }
      />
    );
  };

  const renderApplePaySection = () => {
    const applePayStyle = {
      type: "buy",
      style: "white-outline",
    };
    const renderApplePayOnly = membershipIsBilledToApplePay(paymentMethods);
    return (
      <ApplePaySectionBag
        renderConfirmPurchase={renderApplePayOnly ? false : renderConfirmationCTA}
        checkout={checkout}
        applePayStyle={applePayStyle}
        onSuccessfulPayment={handleNonMembersCheckout}
      />
    );
  };

  const renderConfirmSection = () => {
    return (
      <div className="confirm-button-container">
        {showApplePayForKifah && window.ApplePaySession ? renderApplePaySection() : renderConfirmationCTA()}
        <Terms isSMBagEnabled={true} />
      </div>
    );
  };

  return (
    <div className="simplify-membership-bag-footer">
      <ManagePaymentMethods
        displayModal={displayModal}
        modalName={ChangePaymentMethodModalNames.changePaymentMethodFromBag}
        onCreatePaymentMethodSuccess={onChangePaymentMethod}
        onUpdatePaymentMethodsSuccess={onChangePaymentMethod}
        showMakeDefaultCheckbox={true}
        isKifahBag={true}
      />
      <div className="membership-footer">
        {renderBillingId()}
        {renderPrice()}
      </div>
      {renderConfirmSection()}
    </div>
  );
};

export default SMKifahFooterComponent;
