import React from "react";
import PropTypes from "prop-types";
import StandaloneApplePayWrapper from "components/source/checkout/standalone-apple-pay-wrapper";
import styles from "./styles.module.scss";
import { applePayButtonPropType } from "components/propTypes/apple-pay-button-prop-type";

const propTypes = {
  renderConfirmPurchase: PropTypes.func.isRequired,
  checkout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // can be CTA function; null; undefined;
  bag: PropTypes.object,
  applePayStyle: applePayButtonPropType,
  onSuccessfulPayment: PropTypes.func,
};

const ApplePaySectionBag = ({
  renderConfirmPurchase,
  checkout,
  bag = {},
  applePayStyle,
  onSuccessfulPayment = null,
}) => {
  return (
    <div className={styles["apple-pay-section-bag"]}>
      <StandaloneApplePayWrapper
        checkout={checkout}
        bag={bag}
        applePayStyle={applePayStyle}
        onSuccessfulPayment={onSuccessfulPayment}
      />
      {typeof renderConfirmPurchase === "function" && (
        <>
          <div className={styles.or}></div>
          {renderConfirmPurchase()}
        </>
      )}
    </div>
  );
};

ApplePaySectionBag.propTypes = propTypes;

export default ApplePaySectionBag;
