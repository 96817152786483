import React from "react";
import PropTypes from "prop-types";
class Terms extends React.Component {
  static propTypes = {
    isSMBagEnabled: PropTypes.bool,
  };

  render() {
    return (
      <p className={`membership-footer__terms${this.props.isSMBagEnabled ? " universal-xsmall" : ""}`}>
        By confirming, I accept the {!this.props.isSMBagEnabled && <br />}
        <a href="/pages/termsofservice">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
      </p>
    );
  }
}

export default Terms;
