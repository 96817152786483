import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { formatDateFullMonthDay } from "helpers/date-helpers";
import SMBagActions from "actions/sm-bag-actions";

import SectionHeader from "../components/sm-bag-section-header";
import VerticalGrid from "../components/sm-bag-vertical-grid";

const propTypes = {
  removeBottomBorder: PropTypes.bool,
};

function HeadedYourWayComponent(props) {
  const dispatch = useDispatch();
  const failedToRefreshItems = useSelector(state => state.smBag?.failedToRefreshItems) || [];
  const isReplacementSwap = useSelector(state => state.smBag?.isReplacementSwap) || false;
  const hasOpenSlots = useSelector(state => state.smBag?.numOpenSlots > state.smBag?.items?.arriving?.length) || false;
  const numOfUnfilledSlots =
    useSelector(state => state.smBag?.numOpenSlots - state.smBag?.items?.arriving?.length) || 0;
  const numOfOpenSlots = useSelector(state => state.smBag?.numOpenSlots) || 0;
  const itemsArriving = useSelector(state => state.smBag?.items?.arriving) || [];
  const itemsReturning = useSelector(state => state.smBag?.items?.returning) || [];
  const itemsToShip = [...failedToRefreshItems.map(item => ({ ...item, disabled: true })), ...itemsArriving];
  const numUpgradesInBag = useSelector(state => state.smBag?.numUpgradesInBag) || 0;
  const smBagIsFull = useSelector(state => state.smBag?.isBagFull) || false;
  const hasOrderHistory = useSelector(state => state.smBag?.hasOrderHistory) || false;

  // we call calculateExpectedArrivalDate on itemsArriving instead of itemsToShip just to avoid reading failedToRefreshItems items
  const calculateExpectedArrivalDate = itemsArriving => {
    return itemsArriving.map(item => item.rentalBeginDate).sort((a, b) => new Date(b) - new Date(a))[0];
  };

  const handleRemove = bagItem =>
    bagItem?.disabled
      ? dispatch(SMBagActions.removeFailedToRefreshItem(bagItem.bagItemId))
      : dispatch(SMBagActions.removeBagItemPromise(bagItem, { productId: bagItem?.styleName }));

  const arrivalDate = itemsToShip?.length > 0 ? calculateExpectedArrivalDate(itemsArriving) : false;

  const headerText = "Your Next Shipment";

  let defaultSubHeaderText = "";

  if ((isReplacementSwap && smBagIsFull) || smBagIsFull || numUpgradesInBag > 0) {
    defaultSubHeaderText = `Confirm Shipment to get by ${formatDateFullMonthDay(arrivalDate)}.`;
  } else if (isReplacementSwap || (!hasOrderHistory && numOfUnfilledSlots > 0) || numOfOpenSlots > itemsReturning) {
    defaultSubHeaderText = `Add ${numOfUnfilledSlots} more items to complete your order.`;
  } else if (!smBagIsFull && numOfUnfilledSlots > 0) {
    defaultSubHeaderText = `Add or Keep ${numOfUnfilledSlots} more items to complete your order.`;
  } else {
    defaultSubHeaderText = "Add an item(s) to begin your order.";
  }

  const addItemText = hasOpenSlots ? (
    <span className="universal-xsmall--secondary">Add an Item</span>
  ) : (
    <div className="text-container">
      <span className="universal-xsmall--secondary">Add Extra Spot</span>
      {isReplacementSwap ? (
        <>
          <br />
          <br />
          <span className="universal-xsmall--secondary">(Note: This will count as one of your shipments.)</span>
        </>
      ) : (
        ""
      )}
    </div>
  );
  return (
    <div className={`section-container${props.removeBottomBorder ? " remove-border" : ""}`}>
      <SectionHeader headerText={headerText} subHeaderText={defaultSubHeaderText} />
      <VerticalGrid
        handleRemove={handleRemove}
        gridItems={itemsToShip}
        addItemText={addItemText}
        showKifahPricing={false}
      />
    </div>
  );
}

HeadedYourWayComponent.propTypes = propTypes;

export default HeadedYourWayComponent;
