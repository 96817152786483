import React from "react";
import classNames from "classnames";
import MembershipHelpers from "helpers/membership-helpers";
import ActionLogger from "action-logger";
import PropTypes from "prop-types";
import { bagTabs, switchBagTab } from "actions/bag-actions";
import { connect } from "react-redux";
import Close from "images/close.svg";

class BagHeader extends React.Component {
  static propTypes = {
    bagTab: PropTypes.string,
    isMobileViewport: PropTypes.bool,
    membershipState: PropTypes.object,
    onCloseBag: PropTypes.func,
    homePickupSchedulerIsVisible: PropTypes.bool,
    handleBackButton: PropTypes.func,
  };

  handleCloseBag = () => {
    this.log("close_cart");
    this.props.onCloseBag();
  };

  log = action => {
    ActionLogger.logAction({
      object_type: "top_nav",
      action: action,
      nav_location: "shopping_cart",
    });
  };

  renderBackIcon() {
    return (
      <svg
        data-test-id="bag-header-back-button-icon"
        width="24"
        height="24"
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3027 17.7071C14.6725 17.3166 14.6725 16.6834 14.3027 16.2929L8.34357 10L14.3027 3.70711C14.6725 3.31658 14.6725 2.68342 14.3027 2.29289C13.9329 1.90237 13.3333 1.90237 12.9635 2.29289L6.33475 9.29289C5.96494 9.68342 5.96494 10.3166 6.33475 10.7071L12.9635 17.7071C13.3333 18.0976 13.9329 18.0976 14.3027 17.7071Z"
          fill="black"
        />
      </svg>
    );
  }

  renderBagTitle() {
    const { bagTab, isMobileViewport, homePickupSchedulerIsVisible, handleBackButton } = this.props;
    const isKifah = bagTab === bagTabs.KIFAH_TAB;
    const isKifahSelect = bagTab === bagTabs.KIFAH_SELECT_TAB;
    const buttonLabel = "Close Bag";
    const titleBarClass = "bag-header-membership__title";
    const closeIconClass = "bag-header-membership__title__close";

    let headerText;

    // i hate this, and honestly this whole component could be abstracted out
    if (isKifah) {
      headerText = "Confirm Your Purchase";
    } else if (isKifahSelect) {
      headerText = "Before You Checkout";
    } else if (homePickupSchedulerIsVisible) {
      headerText = "Schedule a Home Pickup";
    } else {
      headerText = "Your Bag";
    }

    const headerElement = <h3 className="bag-section-heading universal-xxlarge--semibold">{headerText}</h3>;

    if (isMobileViewport) {
      if (homePickupSchedulerIsVisible) {
        return <div className={titleBarClass}>{headerElement}</div>;
      } else if (isKifahSelect) {
        return (
          <div className={titleBarClass}>
            <button className="bag-back-button" onClick={handleBackButton}>
              {this.renderBackIcon()}
            </button>
            {headerElement}
          </div>
        );
      }

      // NW [EXPLANATION] 3/15/19: for mobile, the whole title is a click target to close the bag.
      // On desktop only the X is a button - the user can also click away from the bag to close it.
      return (
        <button className={titleBarClass} aria-label={buttonLabel} onClick={this.handleCloseBag}>
          <div data-test-id="bag-header-close-button-icon" className={closeIconClass}>
            <Close />
          </div>
          {headerElement}
        </button>
      );
    }

    return (
      <div className={titleBarClass}>
        {(isKifah || isKifahSelect) && (
          <button className="bag-back-button" onClick={handleBackButton}>
            {this.renderBackIcon()}
          </button>
        )}
        {!homePickupSchedulerIsVisible && !isKifahSelect && (
          <button
            data-test-id="bag-header-close-button-icon"
            className={closeIconClass}
            aria-label={buttonLabel}
            onClick={this.handleCloseBag}>
            <Close />
          </button>
        )}
        {headerElement}
      </div>
    );
  }

  render() {
    const { membershipState, bagTab } = this.props;

    const headerClass = classNames("bag-header-membership", {
      "bag-header-membership--no-pick":
        MembershipHelpers.hasNoShipmentAction(membershipState) &&
        !MembershipHelpers.canInitiateSwapFlow(membershipState),
      "kifah-select": bagTab === bagTabs.KIFAH_SELECT_TAB,
    });

    return (
      <div className={headerClass} data-test-id="bag-header">
        {this.renderBagTitle()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  handleBackButton: () => dispatch(switchBagTab(bagTabs.MEMBERSHIP_TAB)),
});

export default connect(null, mapDispatchToProps)(BagHeader);
