import React from "react";
import PropTypes from "prop-types";
import Routes from "routes";
import { ceBagItemPropType } from "components/propTypes";
import ProductImage from "components/source/shared/product-image";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  ProductImageOrientation,
  ProductImageSize,
} from "helpers/product-image-helpers";
import ProductImagePath from "helpers/product-image-path";
import CloseIcon from "images/sprites/reb/svg/circled-x.svg";
import BuyNowComponent from "components/source/membership/homepage/item_card/footer/ctas/buyNow";
import CEBagActions from "actions/ce-bag-actions";
import SmBagPricingDetail from "./sm-bag-pricing-detail";
import { useDispatch } from "react-redux";
import { usdPriceIntoInt } from "helpers/PricingHelper";

const propTypes = {
  gridItem: ceBagItemPropType,
  handleRemove: PropTypes.func,
  handleSwap: PropTypes.func,
  showKifahPricing: PropTypes.bool,
  isOutOfShipments: PropTypes.bool,
};

function VerticalGridItem({ gridItem, handleRemove, handleSwap, showKifahPricing = true, isOutOfShipments = false }) {
  const fallBackThumbnailImgSrc = gridItem =>
    gridItem?.images?.[ProductImageOrientation.NO_MODEL.path]
      ? ProductImagePath + gridItem.images[ProductImageOrientation.NO_MODEL.path]?.[ProductImageSize.x480.path]
      : ProductImagePath + gridItem?.images?.[ProductImageOrientation.FRONT.path]?.[ProductImageSize.x480.path];

  const onRemoveHandler = () => handleRemove(gridItem);

  const onSwapHandler = event => {
    event.preventDefault();
    handleSwap(gridItem);
  };

  const dispatch = useDispatch();
  const initializePurchase = () => dispatch(CEBagActions.kifah(gridItem.sku, gridItem.orderId, gridItem.bookingId));

  if (!gridItem) {
    return null;
  }

  const smallerSwapIcon = (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="small-swap-icon">
      <path
        d="M0.615115 5.14958C0.841235 5.08845 0.993781 4.88143 1.0144 4.6481C1.15785 3.02519 2.29159 1.58861 3.95601 1.13861C5.52183 0.715259 7.12196 1.28278 8.09009 2.45849L7.12339 2.45849C6.84725 2.45849 6.62339 2.68234 6.62339 2.95849C6.62339 3.23463 6.84724 3.45849 7.12339 3.45849L9.74471 3.45849L9.74471 0.837166C9.74471 0.561024 9.52085 0.337166 9.24471 0.337166C8.96856 0.337166 8.74471 0.561024 8.74471 0.837166L8.74471 1.68513C7.52519 0.309587 5.59001 -0.339076 3.69501 0.173266C1.57163 0.747356 0.139209 2.606 0.00850847 4.68579C-0.0118475 5.0097 0.301808 5.23429 0.615115 5.14958Z"
        fill="black"
      />
      <path
        d="M9.38486 4.85035C9.15874 4.91148 9.0062 5.1185 8.98557 5.35183C8.84213 6.97474 7.70839 8.41132 6.04397 8.86133C4.46092 9.28933 2.84281 8.70455 1.87817 7.50243L2.91657 7.50243C3.19271 7.50243 3.41657 7.27857 3.41657 7.00243C3.41657 6.72629 3.19271 6.50243 2.91657 6.50243L0.29525 6.50243L0.29525 9.12375C0.29525 9.39989 0.519108 9.62375 0.79525 9.62375C1.07139 9.62375 1.29525 9.39989 1.29525 9.12375L1.29525 8.35935C2.51681 9.7043 4.43055 10.3334 6.30497 9.82667C8.42835 9.25258 9.86077 7.39393 9.99147 5.31415C10.0118 4.99023 9.69817 4.76564 9.38486 4.85035Z"
        fill="black"
      />
    </svg>
  );

  return (
    <div className={`vertical-grid-item-container ${gridItem?.disabled ? "disabled" : ""}`}>
      <a className={`content ${gridItem?.disabled ? "disabled" : ""}`} href={Routes.PDP.RouteById(gridItem?.styleName)}>
        <div className="thumbnail-container">
          <ProductImage
            className="thumbnail"
            altText={getProductImageAltText(gridItem.displayName, gridItem.designerName)}
            imageGroup={getProductImageUrlsByOrientation(gridItem.images, [ProductImageOrientation.NO_MODEL.path])}
            imageSize={ProductImageSize.x480}
            fallbackSrc={fallBackThumbnailImgSrc()}
          />
          {gridItem.disabled && <div className="status-tag">UNAVAILABLE</div>}
          {typeof handleSwap === "function" && !isOutOfShipments && (
            <button
              className="swap-item-superimposed"
              type="button"
              data-test-id={`swap-button-${gridItem.bookingId}`}
              name={`swap-button-${gridItem.bookingId}`}
              onClick={onSwapHandler}>
              {smallerSwapIcon}
              <span>Swap</span>
            </button>
          )}
        </div>
        {gridItem.designerName && gridItem.displayName && gridItem.size && (
          <div className="details-container">
            <p className="universal-xsmall--semibold">{gridItem.designerName}</p>
            <p className="universal-xsmall">{gridItem.displayName}</p>
            <p className="universal-xsmall size">Size {gridItem.size}</p>
          </div>
        )}
      </a>
      {typeof handleRemove === "function" && (
        <button
          className="remove-item"
          type="button"
          name={`close-button-${gridItem.bagItemId}`}
          onClick={onRemoveHandler}>
          <CloseIcon title={`close-button-${gridItem.bagItemId}`} />
        </button>
      )}
      {showKifahPricing && (
        <SmBagPricingDetail
          percentOff={gridItem.percentOff}
          purchasePrice={usdPriceIntoInt(gridItem.purchasePrice)}
          retailPrice={usdPriceIntoInt(gridItem.retailPrice)}
        />
      )}
      {showKifahPricing && (
        <BuyNowComponent
          bookingId={gridItem.bookingId}
          purchasePrice={gridItem.purchasePrice}
          initializePurchase={initializePurchase}
          isBag={true}
          className={"solid-button"}
        />
      )}
    </div>
  );
}

VerticalGridItem.propTypes = propTypes;

export default VerticalGridItem;
