import PropTypes from "prop-types";
import { idPropType } from "./id-prop-type";
import { ceBagItemPropType } from "./ce-bag-item-prop-type";

export const ceBagPropType = PropTypes.shape({
  id: idPropType,
  bagType: PropTypes.oneOf(["MEMBERS", "MIXED"]),
  bagItems: PropTypes.arrayOf(ceBagItemPropType),
  checkoutReady: PropTypes.bool,
  failedToRefreshItems: PropTypes.array,
  subTotal: PropTypes.number,
  userId: idPropType,
});
