import PropTypes from "prop-types";
import ContactlessPickup from "images/icon-doorstep-SF.svg";
import LiveSwapHandOff from "images/icon-handoff-SF.svg";
import DirectHandoff from "images/icon-direct-handoff-SF.svg";
import {
  HomePickupMethod,
  HomePickupType,
  HOME_PICKUP_METHOD_KEY,
  HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY,
  HOME_PICKUP_NEW_TAG_OTE,
  assetBaseUrls,
} from "rtr-constants";
import AtomPhoneInput from "components/source/atoms/atom-phone-input";
import { compose } from "redux";
import { connect } from "react-redux";
import HomePickupActions, { HOME_PICKUP_STORAGE_NAME_SPACE } from "actions/home-pickup-actions";
import HomePickupSchedulerError from "../home-pickup-scheduler-error";
import HomePickupDatePicker from "./home-pickup-date-picker";
import AdditionalInstructionsTextArea from "./home-pickup-additional-instructions";
import { LocalStorage } from "site/localStorage";
import { getDigitsFromVisibleNumber } from "../../../../helpers/format-phone-number-helper";
import { withFeatureFlags, featureFlagsPropType, Flags } from "components/source/hoc/with-feature-flags";
import RtrImage from "../../shared/rtr-image";

const localStorageClient = new LocalStorage(HOME_PICKUP_STORAGE_NAME_SPACE);
const propTypes = {
  bookingIds: PropTypes.arrayOf(PropTypes.number),
  dismissHomePickupCTA: PropTypes.func,
  dismissHomePickupCTAText: PropTypes.string,
  errorHandler: PropTypes.func.isRequired,
  featureFlags: featureFlagsPropType,
  wasNewTagRemoved: PropTypes.bool,
  homePickupEligibility: PropTypes.object,
  isHomePickupSchedulerLoading: PropTypes.bool,
  homePickupRentBeginDate: PropTypes.string,
  initialPhoneDigitsOnly: PropTypes.string,
  scheduleHomePickup: PropTypes.func.isRequired,
  showHomePickupError: PropTypes.bool,
  updateHomePickupDateDetails: PropTypes.func,
};

class HomePickupScheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickupMethod: localStorageClient.get(HOME_PICKUP_METHOD_KEY)
        ? localStorageClient.get(HOME_PICKUP_METHOD_KEY)
        : HomePickupMethod.NONE_SELECTED,
      specialInstructions: localStorageClient.get(HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY)
        ? localStorageClient.get(HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY)
        : "",
      phoneDigitsOnly: getDigitsFromVisibleNumber(this.props.initialPhoneDigitsOnly),
      showNewTag: true,
    };
  }

  componentDidMount() {
    if (this.props.wasNewTagRemoved) {
      this.setState({
        showNewTag: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { initialPhoneDigitsOnly, wasNewTagRemoved } = this.props;
    if (prevProps.initialPhoneDigitsOnly !== initialPhoneDigitsOnly) {
      const digits = getDigitsFromVisibleNumber(initialPhoneDigitsOnly);
      if (digits) {
        this.setState({ phoneDigitsOnly: digits });
      }
    }

    if (!prevProps.wasNewTagRemoved && wasNewTagRemoved) {
      this.setState({
        showNewTag: false,
      });
    }
  }

  selectMethod(method) {
    if (this.state.pickupMethod === method) {
      return;
    }
    this.setState({
      pickupMethod: method,
    });
  }

  onSpecialInstructionsInput = value => this.setState({ specialInstructions: value });

  onPhoneInput = visibleNumber => {
    const formattedPhoneNumber = getDigitsFromVisibleNumber(visibleNumber);
    this.setState({
      phoneDigitsOnly: formattedPhoneNumber,
    });
  };

  isButtonDisabled = () => {
    return this.state.pickupMethod === HomePickupMethod.NONE_SELECTED || this.state.phoneDigitsOnly.length !== 10;
  };

  isLiveSwap() {
    if (this.props.homePickupEligibility?.preferredPickupDate === this.props.homePickupRentBeginDate) {
      return true;
    }
    return false;
  }

  renderLoader() {
    return (
      <div className="Home-Pickup-Scheduler" data-test-id="home-pickup-scheduler-loading">
        <div className="loading">
          <RtrImage src="//sf-p.rtrcdn.com/images/loading.gif" alt="Loading animation" />
        </div>
      </div>
    );
  }

  renderMethodButton(selectedMethod, className, methodType, methodTitle, methodDescriptionElement, isLiveSwap) {
    const directHandoffIcon = isLiveSwap ? <LiveSwapHandOff /> : <DirectHandoff />;
    return (
      <button
        className={`${className} method-button ${selectedMethod === methodType ? "active" : ""}`}
        data-test-id={className}
        onClick={() => {
          this.selectMethod(methodType);
        }}>
        <div className="method-icon">
          {methodType === HomePickupMethod.LEAVE_ON_DOORSTEP && <ContactlessPickup />}
          {methodType === HomePickupMethod.DIRECT_HANDOFF && directHandoffIcon}
        </div>
        <div>
          <p className="method-title universal-medium">{methodTitle}</p>
          <p className="method-description">{methodDescriptionElement}</p>
        </div>
      </button>
    );
  }

  scheduleHomePickupCTA = () => {
    const pickupType = this.isLiveSwap() ? HomePickupType.LIVE_SWAP_ELIGIBLE : HomePickupType.HOME_PICKUP_ONLY;

    this.props.scheduleHomePickup(
      this.props.bookingIds,
      this.state.phoneDigitsOnly,
      this.state.pickupMethod,
      pickupType,
      this.state.specialInstructions
    );
  };

  render() {
    if (this.props.showHomePickupError) {
      return <HomePickupSchedulerError errorHandler={this.props.errorHandler} />;
    }

    if (
      !this.props.homePickupEligibility?.preferredPickupDate ||
      this.props.isHomePickupSchedulerLoading ||
      !this.props.bookingIds?.length > 0
    ) {
      return this.renderLoader();
    }

    const selectedMethod = this.state.pickupMethod;
    const isLiveSwap = this.isLiveSwap();

    const pickupTypeText = isLiveSwap
      ? "We’ll pick up your returns when we deliver your new shipment."
      : "We’ll come to you.";
    const {
      preferredPickupDate,
      pickupWindowStart,
      pickupWindowEnd,
      carrierName,
      pickupDateOptions,
    } = this.props.homePickupEligibility;
    const pickupDetails = {
      preferredPickupDate,
      pickupWindowStart,
      pickupWindowEnd,
      carrierName,
      pickupDateOptions,
    };

    return (
      <div className="Home-Pickup-Scheduler" data-test-id="home-pickup-scheduler">
        <div className="content">
          {this.state.showNewTag && (
            <RtrImage
              className="new-tag-icon"
              alt="new tag"
              src={`${assetBaseUrls.DESIGNER_IMAGES}/03302023_New_Tag.svg`}
            />
          )}
          <div className="home-pickup-title">No time to drop off returns?</div>
          <div className="home-pickup-description universal-small">
            {pickupTypeText} Schedule your complimentary pickup now!
          </div>
          <HomePickupDatePicker
            pickupDetails={pickupDetails}
            handleOnSelectDate={date => this.props.updateHomePickupDateDetails(date)}
            multipleDatesFeatureFlag={this.props.featureFlags?.[Flags.HOME_PICKUP_MULTIPLE_DATES]}
          />
          {this.renderMethodButton(
            selectedMethod,
            "leave-on-doorstep-button",
            HomePickupMethod.LEAVE_ON_DOORSTEP,
            "Contactless",
            <span>I&rsquo;ll put my returns in an accessible pickup spot</span>,
            isLiveSwap
          )}
          <AdditionalInstructionsTextArea
            isOpen={selectedMethod === HomePickupMethod.LEAVE_ON_DOORSTEP}
            setSpecialInstructions={this.onSpecialInstructionsInput}
            specialInstructions={this.state.specialInstructions}
          />
          {this.renderMethodButton(
            selectedMethod,
            "direct-handoff-button",
            HomePickupMethod.DIRECT_HANDOFF,
            "Direct Handoff",
            <span>I&rsquo;ll hand my returns to the courier</span>,
            isLiveSwap
          )}
          <div className="section-title universal-xsmall">Contact Details</div>
          <div className="section-description universal-small">Track your pickup via your confirmation email</div>
          <div className="phone-input">
            <AtomPhoneInput originalPhoneNumber={this.state.phoneDigitsOnly} onInputCallback={this.onPhoneInput} />
          </div>
          <div className="terms-of-service universal-xsmall">
            By scheduling a pickup, I agree to the <a href="/pages/termsofservice">Terms of Service</a>,
            <br />
            <a href="/privacy">Privacy Policy</a> and to receive text messages.
          </div>
        </div>
        <div className="footer">
          <div className="schedule-button">
            <button disabled={this.isButtonDisabled()} className="btn btn-primary" onClick={this.scheduleHomePickupCTA}>
              Schedule Pickup
            </button>
          </div>
          {this.props.dismissHomePickupCTA && (
            <button className="decline-pickup universal-xsmall" onClick={this.props.dismissHomePickupCTA}>
              {this.props.dismissHomePickupCTAText
                ? this.props.dismissHomePickupCTAText
                : "No thanks, I’ll drop off my returns instead"}
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bookingIds: state.homePickup?.homePickupBookingIds,
  homePickupEligibility: state.homePickup?.homePickupEligibility,
  homePickupRentBeginDate: state.homePickup?.homePickupRentBeginDate,
  isHomePickupSchedulerLoading: state.homePickup?.isSchedulerLoading,
  wasNewTagRemoved: state.oneTimeExperiences?.[HOME_PICKUP_NEW_TAG_OTE],
});

const mapDispatchToProps = dispatch => ({
  updateHomePickupDateDetails: selectedDate => dispatch(HomePickupActions.updateHomePickupDateDetails(selectedDate)),
});

HomePickupScheduler.propTypes = propTypes;

export { HomePickupScheduler as UnconnectedHomePickupScheduler };

export default compose(
  withFeatureFlags(Flags.HOME_PICKUP_MULTIPLE_DATES),
  connect(mapStateToProps, mapDispatchToProps)
)(HomePickupScheduler);
