import React from "react";
import { assetBaseUrls } from "rtr-constants";
import PropTypes from "prop-types";
import { displayModal } from "actions/shared-actions";
import AddShipmentModal from "components/source/membership/modals/AddShipmentModal/AddShipmentModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { formatDateFullMonthDay } from "helpers/date-helpers";
import RtrImage from "../../../shared/rtr-image";
import KeepingItemsComponent from "../sm/sections/sm-bag-keeping-items";
import { isEligibleForImmediateSwapUpgrade } from "helpers/membership-helpers";

const propTypes = {
  shipments: PropTypes.number,
  unlockDate: PropTypes.string,
};

const OutOfShipments = ({ shipments, unlockDate }) => {
  const dispatch = useDispatch();

  const itemsAtHome = useSelector(state => state.smBag?.items?.keeping) || [];
  const membershipState = useSelector(state => state.membershipState);

  return (
    <>
      {!itemsAtHome.length > 0 && (
        <div className="out-of-shipments-body">
          <RtrImage alt="garment-bag" src={`${assetBaseUrls.DESIGNER_IMAGES}/RTR_GarmentBag_06012023.svg`} />
          <h2>
            Your next {shipments > 1 ? `${shipments} shipments unlock ` : "shipment unlocks "}
            {formatDateFullMonthDay(unlockDate)}
          </h2>
          {isEligibleForImmediateSwapUpgrade(membershipState) && (
            <p>
              Need new styles?{" "}
              <button onClick={() => dispatch(displayModal(AddShipmentModal.modalName))}>Add a shipment</button>
            </p>
          )}
        </div>
      )}
      {itemsAtHome.length > 0 && (
        <>
          <div className="banner out-of-shipments">
            <h2 className="header">
              Your next {shipments > 1 ? `${shipments} shipments unlock ` : "shipment unlocks "}
              {formatDateFullMonthDay(unlockDate)}
            </h2>
            {isEligibleForImmediateSwapUpgrade(membershipState) && (
              <div className="sub-header">
                Need new styles?&nbsp;
                <button onClick={() => dispatch(displayModal(AddShipmentModal.modalName))}>Add a shipment</button>
              </div>
            )}
          </div>
          <div className="simplify-membership-bag-content">
            <KeepingItemsComponent isOutOfShipments={true} />
          </div>
        </>
      )}
    </>
  );
};

OutOfShipments.propTypes = propTypes;

export default OutOfShipments;
