import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { childrenPropType } from "components/propTypes";

export default class TooltipInline extends React.Component {
  static propTypes = {
    align: PropTypes.oneOf(["left", "center", "right"]),
    children: childrenPropType,
    className: PropTypes.string,
    display: PropTypes.bool,
    fadeOut: PropTypes.number,
    onToggleTooltip: PropTypes.func,
    position: PropTypes.oneOf(["top", "bottom"]),
  };

  static defaultProps = {
    align: "center",
    position: "bottom",
  };

  componentDidMount() {
    const { fadeOut = 0, onToggleTooltip } = this.props;

    if (fadeOut <= 0) {
      return;
    }

    window.setTimeout(() => {
      onToggleTooltip(true);

      window.setTimeout(() => onToggleTooltip(false), 8000); // 8000? sure.
    }, fadeOut);
  }

  render() {
    const { align, className, display, position } = this.props;
    const classes = classNames("tooltip", className, {
      "tooltip-top": position === "top",
      "tooltip-bottom": position !== "top", // default
      "tooltip-left": align === "left",
      "tooltip-right": align === "right",
      "tooltip-center": align !== "left" && align !== "right", // default
      "show": display,
    });

    return <div className={classes}>{this.props.children}</div>;
  }
}
