import BagBannerComponent from "../components/sm-bag-banner-component";
import BagBannerShipmentCounter from "../layout/sm-bag-banner-shipment-counter";
import HeadedYourWayComponent from "../sections/sm-bag-headed-your-way";
import { useSelector } from "react-redux";

const SMBagReplacementSpot = () => {
  const upgradesInMembershipBag = useSelector(state => state.smBag?.numUpgradesInBag) || 0;

  const bannerHeader = "Fill your free replacement spot";
  const bannerSubHeader = (
    <span>
      This will <b>not</b> count as one of your shipments for the month, unless you add an extra spot.
    </span>
  );

  return (
    <>
      {upgradesInMembershipBag ? (
        <BagBannerShipmentCounter />
      ) : (
        <BagBannerComponent headerText={bannerHeader} subHeader={bannerSubHeader} />
      )}
      <div className="simplify-membership-bag-content">
        <HeadedYourWayComponent removeBottomBorder={true} />
      </div>
    </>
  );
};

export default SMBagReplacementSpot;
