import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import MembershipHelpers from "helpers/membership-helpers";
import { isCanceled } from "helpers/membership-plan-helpers";
import { hasMixedItems, hasMembersItems } from "helpers/ce-bag-helpers";
import { bagTabs, switchBagTab } from "actions/bag-actions";
import membershipBagActions from "actions/membership-bag-actions";
import smBagActions from "actions/sm-bag-actions";
import BagHeader from "./bag-header";
import BagToggle from "./toggle";
import ceBagActions from "actions/ce-bag-actions";
import sharedActions from "actions/shared-actions";
import homePickupActions from "actions/home-pickup-actions";
import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";

import { bagTabPropType, browserPropType, membershipStatePropType, userDataPropType } from "components/propTypes";
import { featureFlagsPropType, Flags } from "components/source/hoc/with-feature-flags";
import { isAnonymous } from "components/source/hoc/with-user-data";
import { withFireWithUserData } from "components/source/hoc/with-fire-with-user-data";
import HomePickupCrossShipContainer from "components/source/home_pickup/home-pickup-cross-ship-container";
import { CEBagErrors, smBagPromoBannerCMSContent, bagUpsellCMSContent } from "rtr-constants";
import { flagsAndExperimentNames } from "components/source/hoc/with-flags-and-experiments";

import SMBag from "components/source/checkout/bag/sm-bag";
import SMKifah from "components/source/checkout/bag/sm-kifah";
import SMKifahSelect from "components/source/checkout/bag/sm/pages/sm-kifah-select";
import ContentsMixedSingleGroup from "./ce/contents-mixed-single-group";

import RoktPlacement from "components/source/common/rokt-placement";

export class CEBagComponent extends Component {
  static propTypes = {
    bagIsOpen: PropTypes.bool,
    bagTab: bagTabPropType,
    browser: browserPropType,
    ceBag: PropTypes.object,
    ceBagError: PropTypes.bool,
    ceBagLoading: PropTypes.bool,
    ceCheckoutLoading: PropTypes.bool,
    closeBag: PropTypes.func,
    dispatch: PropTypes.func,
    displayHomePickup: PropTypes.bool,
    displayHomePickupOverlay: PropTypes.bool,
    featureFlags: featureFlagsPropType,
    fetchCrossShipReturnByDate: PropTypes.func,
    fetchCEBag: PropTypes.func,
    fetchSMBag: PropTypes.func,
    fetchSMBagCMSContent: PropTypes.func,
    fetchBagUpsellCMSContent: PropTypes.func,
    hasSMBagBeenFetched: PropTypes.bool,
    isHomePickupSchedulerLoading: PropTypes.bool,
    membershipState: membershipStatePropType,
    smBagLoading: PropTypes.bool,
    switchTab: PropTypes.func.isRequired,
    userData: userDataPropType,
  };

  state = {
    isFirstShipmentConfirmationDisabled: true,
  };

  integratedBagElement = React.createRef();

  componentDidMount() {
    const { switchTab } = this.props;

    switchTab(!this.isMembershipEnabled() ? bagTabs.CLASSIC_TAB : bagTabs.MEMBERSHIP_TAB);

    this.props.dispatch(
      FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.DRE_SF_DR_824_OUT_OF_SHIPMENTS_SCREEN)
    );
    this.props.fetchSMBagCMSContent();
    this.props.fetchBagUpsellCMSContent();
    window.addEventListener("resize", this.handleScroll);
    this.handleInitialSMBagFetch({});
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    this.handleRefreshOnUpdate(prevProps);
    this.handleScrollOnUpdate(prevProps);
    this.handleSwitchTabOnUpdate(prevProps);
    this.handleInitialSMBagFetch(prevProps);
  }

  handleRefreshOnUpdate(prevProps) {
    const { bagIsOpen, bagTab, fetchCEBag, switchTab, userData } = this.props;

    const bagJustOpened = bagIsOpen && !prevProps.bagIsOpen;

    if (bagTab === bagTabs.KIFAH_SELECT_TAB && bagJustOpened) {
      switchTab(bagTabs.MEMBERSHIP_TAB);
    }

    if ((bagTab === bagTabs.MEMBERSHIP_TAB && this.isMembershipEnabled()) || !bagJustOpened || isAnonymous(userData))
      return;

    if (bagTab !== bagTabs.KIFAH_TAB) {
      fetchCEBag();
    }
  }

  handleInitialSMBagFetch(prevProps) {
    if (this.isMembershipEnabled(prevProps) !== this.isMembershipEnabled()) {
      const { hasSMBagBeenFetched, smBagLoading } = this.props;
      if (this.isMembershipEnabled() && !hasSMBagBeenFetched && !smBagLoading) {
        this.props.fetchCrossShipReturnByDate();
        this.props.fetchSMBag();
      }
    }
  }

  handleScrollOnUpdate(prevProps) {
    /* NW [EXPLANATION] 9/10/19: if bag was just opened, or bag contents just loaded,
     * check whether the entire bag is visible in the viewport
     * (otherwise user will need to scroll down to enable the Confirm button) */
    const bagJustOpened = this.props.bagIsOpen && !prevProps.bagIsOpen;
    if (bagJustOpened) {
      // NW [EXPLANATION] 9/10/19: wait for bag open animation duration
      setTimeout(this.handleScroll, 500);
    }

    if (this.props.bagIsOpen) {
      this.handleScroll();
    }
  }

  handleSwitchTabOnUpdate(prevProps) {
    const { bagTab, ceBag, membershipState, switchTab } = this.props;

    /**
     * if the user is a member, and they now no longer have any mixed items in bag, switch the bag tab to the Members one.
     */
    if (
      this.isMembershipEnabled() &&
      (prevProps.ceBag !== ceBag || prevProps.membershipState !== membershipState) &&
      bagTab === bagTabs.CLASSIC_TAB &&
      !hasMixedItems(ceBag)
    ) {
      switchTab(bagTabs.MEMBERSHIP_TAB);
    }
  }

  handleScroll = () => {
    // NW [EXPLANATION] 9/10/19: for first-shipment only, detect whether the bottom of bag is visible, and then enable the Shipment Confirmation button
    if (this.state.isFirstShipmentConfirmationDisabled) {
      const visibleBagBottom =
        (this.integratedBagElement.current?.scrollHeight || 0) - (this.integratedBagElement.current?.scrollTop || 0);
      const windowHeight = window.screen.height;

      // MH [EXPLANATION] 12/02/19: the scroll event doesn't fire properly on android phones so we
      // need to add a buffer of space to ensure the button becomes un-disabled
      const bagBottomVisible = visibleBagBottom <= windowHeight + 20;

      if (bagBottomVisible) {
        this.setState({
          isFirstShipmentConfirmationDisabled: false,
        });
      }
    }
  };

  handleCloseBag = () => {
    this.props.closeBag();
  };

  isMembershipEnabled(props) {
    const propsForEvaluation = props || this.props;
    return (
      MembershipHelpers.isActiveMembership(propsForEvaluation.membershipState) &&
      !isCanceled(propsForEvaluation.membershipState)
    );
  }

  renderToggle() {
    const { ceBag, switchTab, userData, bagIsOpen, browser } = this.props;

    // we should only show the bag tabs if the user is a member with at least one mixed item in bag
    return (
      <BagToggle
        ceBag={ceBag}
        onToggle={switchTab}
        bagIsOpen={bagIsOpen}
        isMobileViewport={browser?.isMobileViewport}
        isSubscriptionMember={MembershipHelpers.isSubscriptionMember(userData)}
        previewInvoice={null}
      />
    );
  }

  renderLoadingOverlay() {
    const { ceBagLoading, ceCheckoutLoading, bagTab } = this.props;

    if (
      (this.isMembershipEnabled() && bagTab !== bagTabs.CLASSIC_TAB) || // if on smBag, have all loading be done withing simplify-membership bag
      (!ceBagLoading && !ceCheckoutLoading)
    )
      return null;

    return <div className="active-orders-loader" data-test-id="ce-bag-loading-overlay" />;
  }

  renderBagHeader() {
    const { bagTab, browser, displayHomePickup, membershipState, userData } = this.props;

    if (bagTab === bagTabs.KIFAH_SELECT_TAB) {
      return null;
    }

    return (
      <BagHeader
        bagTab={bagTab}
        homePickupSchedulerIsVisible={displayHomePickup}
        isMobileViewport={browser?.isMobileViewport}
        isToggledToReserveContents={this.props.bagTab === bagTabs.CLASSIC_TAB}
        membershipState={membershipState}
        onCloseBag={this.handleCloseBag}
        userData={userData}
      />
    );
  }

  renderContents(showHomePickup = false) {
    const { bagTab, ceBag, ceBagError } = this.props;
    const isMembershipEnabled = this.isMembershipEnabled();

    if (showHomePickup) {
      return <HomePickupCrossShipContainer />;
    } else if (bagTab === bagTabs.KIFAH_SELECT_TAB) {
      return <SMKifahSelect handleCloseBag={this.handleCloseBag} />;
    }

    const contentsWrapper = children => (
      <div className="bag-contents">
        {this.renderToggle()}
        {children}
      </div>
    );

    if (!isMembershipEnabled && ceBagError && (typeof ceBag === "undefined" || Object.entries(ceBag).length === 0)) {
      return contentsWrapper(
        <div className="unlimited-checkout-error">
          <div className="unlimited-checkout-error__help">{CEBagErrors.BAG_GENERIC_ERROR}</div>
        </div>
      );
    }

    if (bagTab === bagTabs.KIFAH_TAB) {
      return contentsWrapper(<SMKifah />);
    } else if (isMembershipEnabled && bagTab === bagTabs.MEMBERSHIP_TAB) {
      return contentsWrapper(<SMBag />);
    }

    return contentsWrapper(<ContentsMixedSingleGroup onCloseBag={this.handleCloseBag} />);
  }

  isNoPick = () => {
    const { bagTab, ceBag, ceBagLoading, displayHomePickup } = this.props;

    return (
      ceBagLoading ||
      (bagTab === bagTabs.MEMBERSHIP_TAB &&
        !hasMembersItems(ceBag) &&
        !displayHomePickup &&
        !this.isMembershipEnabled())
    );
  };

  render() {
    const { bagTab, bagIsOpen, displayHomePickup, displayHomePickupOverlay, isHomePickupSchedulerLoading } = this.props;

    const visibleClass = bagIsOpen ? "visible" : "hidden";
    const isMembershipEnabled = this.isMembershipEnabled();

    const showHomePickup =
      this.props.featureFlags?.[Flags.HOME_PICKUP_AFTER_CHECKOUT] &&
      !displayHomePickupOverlay && // we block the rendering of hpu-cross-ship-container by making sure that the actions occuring in hpu-standalone-scheduler do not trigger isHomePickupSchedulerLoading/displayHomePickup
      (displayHomePickup || isHomePickupSchedulerLoading);

    const bagStyle = classNames({
      "non-sticky-bag simplify-membership-bag": isMembershipEnabled,
    });

    const relativeContainerClassName = classNames("integrated-bag__relative-container", {
      "no-pick": this.isNoPick(),
      "home-pickup": showHomePickup,
      "simplify-membership-bag": isMembershipEnabled,
    });

    const contentsAndFooterClassName = classNames("bag-contents-and-footer", {
      "home-pickup": showHomePickup,
      "single-group-checkout-bag": bagTab === bagTabs.CLASSIC_TAB,
      "kifah-select": bagTab === bagTabs.KIFAH_SELECT_TAB,
    });

    return (
      <div id="integrated-bag" className={visibleClass + " " + bagStyle} data-test-id="ce-bag">
        {this.renderLoadingOverlay()}
        <div
          className={relativeContainerClassName}
          ref={this.integratedBagElement}
          onScroll={this.handleScroll}
          data-test-id="ce-integrated-bag-relative-container">
          {this.renderBagHeader()}
          <div className={contentsAndFooterClassName}>{this.renderContents(showHomePickup)}</div>
        </div>
        <RoktPlacement />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeBag: () => {
    dispatch(homePickupActions.displayHomePickup(false));
    dispatch(ceBagActions.closeBag());
  },
  displayModal: modalName => dispatch(sharedActions.displayModal(modalName)),
  fetchCEBag: () => dispatch(ceBagActions.get()),
  fetchCrossShipReturnByDate: () => dispatch(membershipBagActions.fetchCrossShipReturnByDateForToday()),
  fetchSMBag: () => dispatch(smBagActions.fetchSMBag()),
  fetchSMBagCMSContent: () =>
    dispatch(sharedActions.loadCMSContent(smBagPromoBannerCMSContent.CMS_PATH, smBagPromoBannerCMSContent.STATE_KEY)),
  fetchBagUpsellCMSContent: () =>
    dispatch(sharedActions.loadCMSContent(bagUpsellCMSContent.CMS_PATH, bagUpsellCMSContent.STATE_KEY)),
  switchTab: tab => dispatch(switchBagTab(tab)),
});

export default compose(withFireWithUserData, connect(null, mapDispatchToProps))(CEBagComponent);
