import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  additionalClassName: PropTypes.string,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

function BagBannerComponent({ additionalClassName, headerText, subHeader }) {
  return (
    <div className={`banner ${additionalClassName ? additionalClassName : ""}`}>
      <h2 className="header">{headerText}</h2>
      <div className="universal-medium sub-header">{subHeader}</div>
    </div>
  );
}

BagBannerComponent.propTypes = propTypes;

export default BagBannerComponent;
