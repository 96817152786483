import PropTypes from "prop-types";
import React from "react";
import { membershipLens } from "rtr-constants";
import styles from "./styles.module.scss";
import TrustIcon from "images/trust-icon.svg";
import { useSelector } from "react-redux";
import { HELP } from "routes";

const FitGuarantee = ({ lens = membershipLens.classic, placement }) => {
  const membershipState = useSelector(state => state?.membershipState);
  let title, copy;

  if (lens === membershipLens.classic) {
    title = "Fit Guarantee";
    copy = (
      <span>
        Always have something you love to wear. If you don’t love it,{" "}
        <a href={`${HELP.FIT_GUARANTEE}&placement=${placement}`} className="underline">
          let us know
        </a>{" "}
        to get a replacement.{" "}
      </span>
    );
  } else if (
    (lens === membershipLens.unlimited || lens === membershipLens.RTRUpdate) &&
    membershipState?.membershipTermNumber < 2
  ) {
    title = "Rent With Confidence";
    copy = "Keep what you love. Replace what you don’t. We’ll replace any item for free your first 60 days. ";
  } else {
    return null;
  }

  return (
    <div className={`${styles["fit-guarantee"]}`}>
      <div className={styles["head"]}>
        <TrustIcon className={styles["icon"]} />
        <p className="universal-medium--semibold">{title}</p>
      </div>
      <p>{copy}</p>
    </div>
  );
};

FitGuarantee.propTypes = {
  lens: PropTypes.string,
  placement: PropTypes.string,
};

export default FitGuarantee;
