import PropTypes from "prop-types";
import { idPropType } from "./id-prop-type";

export const ceBagItemPropType = PropTypes.shape({
  bookingId: idPropType,
  designerName: PropTypes.string,
  discountReason: PropTypes.string,
  displayName: PropTypes.string,
  displayPrice: PropTypes.number,
  homeGoods: PropTypes.bool,
  id: idPropType,
  images: PropTypes.object,
  rentBegin: PropTypes.string,
  rentEnd: PropTypes.string,
  size: PropTypes.string,
  sku: PropTypes.string,
  styleName: PropTypes.string,
  type: PropTypes.string,
  zipCode: PropTypes.string,
});
