import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ceBagGroupPropType } from "components/propTypes";
import { CEBagTypes, CEBagGroupTypes, CEBagItemTypes } from "rtr-constants";
import styles from "./styles.module.scss";
import { formattedPrice } from "helpers/invoice-helper";
import AtomPrimaryButton from "components/source/atoms/atom-primary-button";
import MembershipTierActions from "actions/membership-tier-actions";
import ceBagActions from "actions/ce-bag-actions";
import { usdPriceIntoFloat } from "helpers/PricingHelper";
import { getOrCreateCheckoutFromGroup } from "actions/ce-standard-checkout-actions.js";

import Header from "./header";
import BagItem from "./bag-item";
import ReserveBag from "./reserve-bag";
import { removeFromBagCEEvent } from "helpers/heap-ce-helpers";
import GTMHelpers from "helpers/gtm";

const BagGroup = ({ bagGroup }) => {
  const dispatch = useDispatch();
  const membershipTier = useSelector(state => state?.membershipTier);
  const userData = useSelector(state => state?.userData);
  const isSubscription = bagGroup?.bagGroupType === CEBagGroupTypes.SUBSCRIPTION;
  const isRentals = bagGroup?.bagGroupType === CEBagGroupTypes.RENTALS;
  const isPurchases = bagGroup?.bagGroupType === CEBagGroupTypes.PURCHASES;

  useEffect(() => {
    if (isSubscription) {
      dispatch(MembershipTierActions.getMembershipTier(bagGroup.bagItems[0].membershipTierId));
    }
  }, [bagGroup]);

  function handleRemoveFromCheckoutGAEvent(bagItem) {
    const { sku } = bagItem;
    const analyticsItem = {
      item_id: sku,
      item_name: bagItem.displayName,
      affiliation: "Rent The Runway",
      currency: "USD",
      item_brand: bagItem.designerName,
      discount: "N/A",
      price: bagItem?.pricing?.price,
      item_variant: bagItem.styleName,
    };
    GTMHelpers.removeFromCartEvent(analyticsItem, sku, userData?.userProfile?.id, userData?.userProfile?.email);
  }

  const checkoutLoading = useSelector(state => state?.checkoutLoading);
  // so we can show "please wait" when the checkout is being created
  // (separately from disabling other checkout buttons)
  const checkoutIdCurrentlyCreating = useSelector(state => state?.ceCheckoutIdCurrentlyCreating);

  const onClickCheckout = () => {
    dispatch(getOrCreateCheckoutFromGroup(bagGroup));
  };

  const handleRemove = bagItem => {
    const { id, holdId } = bagItem;
    removeFromBagCEEvent(CEBagTypes.MIXED, id);
    handleRemoveFromCheckoutGAEvent(bagItem);
    dispatch(ceBagActions.remove(id, holdId, CEBagTypes.MIXED, { bagItemId: id, productId: bagItem.styleName }));
  };

  const getPurchasesByType = () => {
    const purchases = bagGroup?.bagItems?.filter(item => item.type === CEBagItemTypes.KIF_FROM_RACK);
    const finalSale = bagGroup?.bagItems?.filter(item => item.type === CEBagItemTypes.CLEARANCE);

    return { purchases, finalSale };
  };

  const purchasesByType = getPurchasesByType();

  const displaySubTotal = pricing => {
    const preDiscountSubtotal = Object.values(pricing.itemPrices).reduce((acc, price) => {
      return acc + usdPriceIntoFloat(price.basePrice);
    }, 0);

    if (preDiscountSubtotal > usdPriceIntoFloat(pricing.subTotal)) {
      return (
        <>
          <span className={`${styles["cross-out-price"]} ${styles["second-discount"]}`}>
            {formattedPrice(preDiscountSubtotal)}
          </span>
          <span>{formattedPrice(pricing.subTotal)}</span>
        </>
      );
    } else {
      return formattedPrice(pricing.subTotal);
    }
  };

  const renderSubtotalAndCTA = () => {
    return (
      <div className={`${styles["subtotal-and-cta"]} ${styles["sticky-cta"]}`}>
        <div className={styles["subtotal-holder"]}>
          <div className={styles["price-holder"]}>
            <div>Subtotal</div>
            <div>
              {displaySubTotal(bagGroup.pricing)}
              {isSubscription && "/mo"}
            </div>
          </div>
          <p className={styles.fineprint}>Excludes Taxes and Shipping</p>
        </div>
        <AtomPrimaryButton
          dataTestId="single-group-checkout-button"
          buttonText={checkoutIdCurrentlyCreating === bagGroup.id ? "Please wait..." : "Checkout"}
          className={`${styles.cta} single-group-checkout-button`}
          onClick={onClickCheckout}
          disabled={checkoutIdCurrentlyCreating || checkoutLoading}
        />
      </div>
    );
  };

  return (
    <div className={styles["bag-group"]} data-test-id="single-group-bag-group">
      {isRentals && <ReserveBag bagGroup={bagGroup} handleRemove={handleRemove} />}
      <div className={styles["bag-group-items"]}>
        {isSubscription && (
          <>
            <Header type={bagGroup.bagGroupType} text="Membership" />
            {bagGroup.bagItems.map((item, index) => (
              <BagItem item={item} key={index} handleRemove={handleRemove} membershipTier={membershipTier} />
            ))}
          </>
        )}

        {isPurchases && (
          <>
            {purchasesByType?.purchases.length > 0 ? (
              <>
                <Header type={bagGroup.bagGroupType} text="Purchasing" />

                {purchasesByType.purchases.map((item, index) => (
                  <BagItem item={item} key={index} handleRemove={handleRemove} />
                ))}
              </>
            ) : null}

            {purchasesByType?.finalSale.length > 0 && (
              <>
                <Header type={bagGroup.bagGroupType} text="Final Sale" />
                {purchasesByType?.finalSale.map((item, index) => (
                  <BagItem item={item} key={index} handleRemove={handleRemove} />
                ))}
              </>
            )}
          </>
        )}
      </div>
      {renderSubtotalAndCTA()}
    </div>
  );
};

BagGroup.propTypes = {
  bagGroup: ceBagGroupPropType,
};

export default BagGroup;
