import PropTypes from "prop-types";
import { chargeOrTotalPropType } from "./";

export const invoiceOrderGroupPropType = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.string,
  charges: PropTypes.arrayOf(chargeOrTotalPropType),
  totals: PropTypes.arrayOf(chargeOrTotalPropType),
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      sku: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string,
      }),
      merchandiseCategory: PropTypes.string,
      charges: PropTypes.arrayOf(chargeOrTotalPropType),
      totals: PropTypes.arrayOf(chargeOrTotalPropType),
      orderItem: PropTypes.object,
    })
  ),
  orderGroup: PropTypes.object,
});
