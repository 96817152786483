import { createAction } from "redux-actions";
import ActionTypes from "actions/action-types";
import { LocalStorage } from "../site/localStorage";

// NW [EXPLANATION] 1/29/21: actions to support front-end-only one-time experiences (tooltips, auto-open modals, etc) that rely on local storage
const ONE_TIME_EXPERIENCES_LOCAL_STORAGE_KEY = "oneTimeExperiences";
const actions = {
  setOneTimeExperienceDismissed: createAction(ActionTypes.ONE_TIME_EXPERIENCE_DISMISSED),

  getAllOneTimeExperienceStatuses: () => {
    const localStorageClient = new LocalStorage(ONE_TIME_EXPERIENCES_LOCAL_STORAGE_KEY);
    const statuses = [];
    localStorageClient.iterate(function (experienceName, status) {
      statuses.push([experienceName, status]);
    });

    return statuses;
  },

  getIsOneTimeExperienceDismissed: experienceName => {
    return function (dispatch) {
      const localStorageClient = new LocalStorage(ONE_TIME_EXPERIENCES_LOCAL_STORAGE_KEY);
      const isExperienceDismissed = localStorageClient.get(experienceName);

      // NW [EXPLANATION] 5/20/19: set the status in Redux
      if (isExperienceDismissed) {
        dispatch(
          actions.setOneTimeExperienceDismissed({
            experienceName,
            dismissed: true,
          })
        );
      } else {
        dispatch(
          actions.setOneTimeExperienceDismissed({
            experienceName,
            dismissed: false,
          })
        );
      }
    };
  },

  dismissOneTimeExperience: experienceName => {
    return function (dispatch) {
      const localStorageClient = new LocalStorage(ONE_TIME_EXPERIENCES_LOCAL_STORAGE_KEY);
      localStorageClient.set(experienceName, true);
      dispatch(
        actions.setOneTimeExperienceDismissed({
          experienceName,
          dismissed: true,
        })
      );
    };
  },
};

export default actions;

export const {
  setOneTimeExperienceDismissed,
  getIsOneTimeExperienceDismissed,
  dismissOneTimeExperience,
  getAllOneTimeExperienceStatuses,
} = actions;
