import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PromoCode } from "components/source/checkout/generic-code-input";
import ExpressBagPromoCode from "components/source/checkout/bag/membership/express-bag-promo-code";
import { applyPromo, removePromo } from "actions/promo-actions";
import { refreshHoldsSuccess } from "actions/classic-order-actions";
import { chargeOrTotalArrayPropType } from "components/propTypes";

const mapStateToProps = state => {
  const { orderId } = state.previewInvoice || state?.membershipBag?.group || {};
  const { appliedCode, error, isSubmitting } = state.promo || {};
  return {
    appliedCode,
    error,
    isSubmitting,
    orderId,
  };
};

// TODO: Do we need to refresh the order on failure? Or can we assume that a failed patch call
// means that the original order was not altered?
const mapDispatchToProps = (dispatch, ownProps) => {
  // success callback will receive the updated order; we don't need to fetch it again, only update state with it
  const onSubmitSuccess = (data, code) => {
    const { onApplyPromoCodeSuccess } = ownProps;

    dispatch(refreshHoldsSuccess(data));

    if (onApplyPromoCodeSuccess) {
      onApplyPromoCodeSuccess(data, code);
    }
  };

  const onSubmitFailure = (err, code) => {
    const { onApplyPromoCodeFailure } = ownProps;

    if (onApplyPromoCodeFailure) {
      onApplyPromoCodeFailure(err, code);
    }
  };

  const onRemoveSuccess = data => {
    const { onRemovePromoCodeSuccess } = ownProps;

    dispatch(refreshHoldsSuccess(data));

    if (onRemovePromoCodeSuccess) {
      onRemovePromoCodeSuccess(data);
    }
  };

  const onRemoveFailure = err => {
    const { onRemovePromoCodeFailure } = ownProps;

    if (onRemovePromoCodeFailure) {
      onRemovePromoCodeFailure(err);
    }
  };

  return {
    onSubmitCode: (code, orderId) => {
      dispatch(applyPromo({ code, orderId }, onSubmitSuccess, onSubmitFailure));
    },
    onRemoveCode: (code, orderId) => {
      dispatch(removePromo({ code, orderId }, onRemoveSuccess, onRemoveFailure));
    },
  };
};

export const ExpressBagPromoCodeComponent = connect(mapStateToProps, mapDispatchToProps)(ExpressBagPromoCode);
export const CheckoutPromoCodeComponent = connect(mapStateToProps, mapDispatchToProps)(PromoCode);

CheckoutPromoCodeComponent.displayName = "CheckoutPromoCode";
CheckoutPromoCodeComponent.propTypes = {
  onApplyPromoCodeSuccess: PropTypes.func,
  onApplyPromoCodeFailure: PropTypes.func,
  onRemovePromoCodeSuccess: PropTypes.func,
  onRemovePromoCodeFailure: PropTypes.func,
  orderTotals: chargeOrTotalArrayPropType,
  orderId: PropTypes.number,
  defaultOpen: PropTypes.bool,
};

ExpressBagPromoCodeComponent.displayName = "ExpressBagPromoCode";
ExpressBagPromoCodeComponent.propTypes = {
  onApplyPromoCodeSuccess: PropTypes.func,
  onApplyPromoCodeFailure: PropTypes.func,
  onRemovePromoCodeSuccess: PropTypes.func,
  onRemovePromoCodeFailure: PropTypes.func,
};

export default {
  CheckoutPromoCodeComponent,
  ExpressBagPromoCodeComponent,
};
