import React from "react";
import PropTypes from "prop-types";
import { ceBagItemPropType } from "components/propTypes";
import VerticalGridItem from "./sm-bag-vertical-grid-item";
import { Home } from "routes";

const propTypes = {
  gridItems: PropTypes.arrayOf(ceBagItemPropType),
  handleRemove: PropTypes.func,
  handleSwap: PropTypes.func,
  addItemText: PropTypes.element,
  showKifahPricing: PropTypes.bool,
  isOutOfShipments: PropTypes.bool,
};

function VerticalGrid({
  gridItems,
  handleRemove,
  handleSwap,
  addItemText,
  showKifahPricing,
  isOutOfShipments = false,
}) {
  return (
    <div className={`vertical-grid-container even-spacing`}>
      {gridItems?.length > 0 &&
        gridItems.map(item => (
          <VerticalGridItem
            handleRemove={handleRemove}
            handleSwap={handleSwap}
            gridItem={item}
            key={item.bookingId}
            showKifahPricing={showKifahPricing}
            isOutOfShipments={isOutOfShipments}
          />
        ))}
      {typeof handleRemove === "function" && addItemText && (
        <div className="vertical-grid-item-container add-item">
          <a className="content add-item" href={Home}>
            <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="24.407" cy="25" rx="23.4925" ry="25" fill="#C8ACA0" />
              <path
                d="M24.0228 14.451C23.431 14.451 22.9522 14.9502 22.9522 15.567V23.6575H15.1898C14.598 23.6575 14.1191 24.1566 14.1191 24.7735C14.1191 25.3903 14.598 25.8894 15.1898 25.8894H22.9522V33.9799C22.9522 34.5967 23.431 35.0959 24.0228 35.0959C24.6146 35.0959 25.0935 34.5967 25.0935 33.9799V25.8894H32.8558C33.4476 25.8894 33.9265 25.3903 33.9265 24.7735C33.9265 24.1566 33.4476 23.6575 32.8558 23.6575H25.0935V15.567C25.0935 14.9502 24.6146 14.451 24.0228 14.451Z"
                fill="white"
              />
            </svg>
            {addItemText}
          </a>
        </div>
      )}
    </div>
  );
}

VerticalGrid.propTypes = propTypes;

export default VerticalGrid;
