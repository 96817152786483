import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import CEBagActions from "actions/ce-bag-actions";

const BagOverlay = ({ bagIsOpen, onClick }) => {
  const dispatch = useDispatch();

  const handleClick = event => {
    event.stopPropagation();

    if (onClick) {
      onClick();
      return;
    }

    dispatch(CEBagActions.closeBag());
  };

  const classes = classnames("bag-overlay", { visible: bagIsOpen, hidden: !bagIsOpen });

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <div onClick={handleClick} className={classes} data-test-id="bag-overlay" />;
};

BagOverlay.propTypes = {
  bagIsOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BagOverlay;
