import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import SMBagActions from "actions/sm-bag-actions";
import SavingsIcon from "images/savings.svg";

const propTypes = {
  checkoutId: PropTypes.string.isRequired,
  appliedPromoCode: PropTypes.string,
  appliedPromoSavings: PropTypes.string,
  promoCodeError: PropTypes.string,
  onApply: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const SMBagPromoCode = ({ checkoutId, appliedPromoCode, promoCodeError, appliedPromoSavings, onApply, onRemove }) => {
  const [value, setValue] = useState("");
  const isLoading = useSelector(state => state.ceCheckoutPromoLoading);
  const bagIsOpen = useSelector(state => state.bagIsOpen);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!bagIsOpen) {
      setValue("");
    }
  }, [bagIsOpen]);

  const onChange = event => {
    setValue(event.target.value);
  };

  function onClick() {
    if (!appliedPromoCode) {
      if (!value) return;
      onApply(checkoutId, value);
    } else {
      setValue("");
      onRemove(checkoutId);
    }
  }

  function renderInputs() {
    if (appliedPromoCode) {
      const ctaText = !isLoading ? "Change Promo" : "Changing...";
      return (
        <div className="applied-promo-container">
          <div className="details">
            <SavingsIcon role="img" />
            <div className="applied-code-details">
              <span className="savings">You save {appliedPromoSavings}</span>
              <span className="code-applied universal-xsmall">{appliedPromoCode} applied</span>
            </div>
          </div>
          <button className="inline-cta" data-test-id="sm-promo-code-cta-button" onClick={onClick} disabled={isLoading}>
            {ctaText}
          </button>
        </div>
      );
    }

    const ctaText = !isLoading ? "Apply" : "Applying...";

    return (
      <div className="apply-promo-container">
        <input
          className="inline-input"
          type="text"
          name="promoCode"
          onChange={onChange}
          placeholder="Promo Code"
          data-test-id="sm-promo-code-text-input"
          value={value}></input>
        <button className="inline-cta" data-test-id="sm-promo-code-cta-button" onClick={onClick} disabled={isLoading}>
          {ctaText}
        </button>
      </div>
    );
  }

  function renderError() {
    let ctaText;

    /**
     * we have to account for two kinds of promo code errors:
     *
     * 1. "active" promo code errors, which are errors that are returned from the server with an
     * error HTTP code. in this case the promo code is not applied and the user is prompted to try again.
     * 2. "passive" promo code errors, which can happen if the user applied a promo code that was valid
     * at the time of application, but is no longer valid at the time of checkout.
     * e.g. user applied a valid promo code, but then the user removed items, rendering the promo code
     * invalid. this would show as the promo code being applied to the checkout but the checkout would
     * have a promo code error. these would also persist between page loads.
     * in this case we prompt the user to remove the promo code (requiring an AJAX request) as the user
     * won't be able to complete checkout otherwise.
     */
    if (appliedPromoCode && isLoading) {
      ctaText = "Removing...";
    } else if (appliedPromoCode) {
      ctaText = "Remove";
    } else {
      ctaText = "Try New Code";
    }

    return (
      <div data-test-id="sm-bag-promo-code-error" className="error-container universal-small">
        <span>{promoCodeError}</span>
        <button
          data-test-id="sm-bag-promo-code-cta-button-error"
          onClick={() => {
            appliedPromoCode ? onRemove(checkoutId) : dispatch(SMBagActions.clearPromoErrors());
          }}
          disabled={isLoading}>
          {ctaText}
        </button>
      </div>
    );
  }

  return (
    <div className="sm-bag-promo-code" data-test-id="sm-bag-promo-code">
      {promoCodeError ? renderError() : renderInputs()}
    </div>
  );
};

SMBagPromoCode.propTypes = propTypes;

export default SMBagPromoCode;
