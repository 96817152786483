import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import AuthActions from "actions/auth-actions";
import { idPropType, userDataPropType } from "components/propTypes";
import { isAuthorized, withUserData } from "components/source/hoc/with-user-data";
import { HEAP_AUTH_TRIGGER_TYPES, HEAP_ADD_TO_BAG_IDS } from "../../../../../../../helpers/heap-helpers";

const propTypes = {
  bookingId: idPropType.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  initializePurchase: PropTypes.func.isRequired,
  isBag: PropTypes.bool,
  purchasePrice: PropTypes.string,
  returnFlow: PropTypes.shape({
    promised: PropTypes.array,
    purchased: PropTypes.array,
  }),
  userData: userDataPropType,
};

function BuyNowComponent({
  bookingId,
  className = "",
  disabled = false,
  initializePurchase,
  isBag = false,
  purchasePrice = null,
  returnFlow = {
    promised: [],
    purchased: [],
  },
  userData,
}) {
  const dispatch = useDispatch();

  const openAuthModal = () => dispatch(AuthActions.showAuthModal({ triggeredBy: HEAP_AUTH_TRIGGER_TYPES.AUTH_PROMPT }));

  const isPurchased = () => {
    const hasPurchasePrice = Boolean(purchasePrice);
    // TODO We should only be passing in `purchased` not the parent obj
    const purchasedItems = returnFlow.purchased || [];
    const isItemPurchased = purchasedItems.some(obj => obj.bookingId === bookingId);
    return hasPurchasePrice && !isItemPurchased;
  };

  // if you have to define handlers on the fly, consider using a class component
  const handleClick = () => {
    if (isAuthorized(userData)) {
      initializePurchase();
    } else {
      openAuthModal();
    }
  };

  let classes = `${className}`;
  if (!isBag) {
    classes = `subscription-item-card-at-home-ttb subscription-item-card-footer-information ${className}`;
  }

  if (!isPurchased()) {
    return null;
  }

  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={classes}
      data-heap-id={HEAP_ADD_TO_BAG_IDS.KIFAH}
      data-test-id="buy">
      {"Buy Now"}
    </button>
  );
}

BuyNowComponent.propTypes = propTypes;

export default withUserData(BuyNowComponent);
