import React from "react";
import _ from "underscore";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CheckoutButton from "./checkout-button";
import { CheckoutLogging } from "rtr-constants/logging";
import classNames from "classnames";
import { ExpressBagPromoCodeComponent } from "components/source/checkout/checkout-promo-code";
import CEMembershipPromoCode from "components/source/checkout/bag/ce/ce-membership-promo-code";
import SMBagPromoCode from "components/source/checkout/bag/sm/components/sm-bag-promo-code";
import { pluralize } from "helpers/FormattingUtils";
import Terms from "components/source/checkout/bag/membership/terms";
import Emissions from "../emissions";
import AtomInlineAddress from "components/source/atoms/atom-inline-address";
import {
  baseSlotUpgradeTotal,
  formattedPrice,
  formatSavingsAsPositive,
  isZeroAmount,
  merchCreditsApplied,
  priceStringIntoFloat,
  priceStringIntoFormattedNumber,
} from "helpers/invoice-helper";
import { bagTabPropType } from "components/propTypes";
import { bagTabs } from "actions/bag-actions";
import membershipBagActions from "actions/membership-bag-actions";
import MembershipHelpers from "helpers/membership-helpers";
import MembershipUpgradesHelpers from "helpers/membership-upgrades-helpers";
import { getBillingInfo } from "helpers/payment-method-helpers";
import ActionLogger from "action-logger";
import { usdPriceIntoFloat } from "helpers/PricingHelper";
import { analytics, ChangeAddressModalNames, ChangePaymentMethodModalNames, dateFnsFormats } from "rtr-constants";
import { format, parseISO } from "date-fns";
import SMBagActions from "actions/sm-bag-actions";
import { onEnterPress } from "helpers/a11y-helper";

export class MembershipFooter extends React.Component {
  static propTypes = {
    bagTab: bagTabPropType,
    ceCheckout: PropTypes.object,
    ceCheckoutPromoError: PropTypes.string,
    dispatch: PropTypes.func, // @TODO: connect dispatch rather than pass it?
    displayModal: PropTypes.func,
    isSMBagEnabled: PropTypes.bool,
    isSubmitDisabled: PropTypes.bool,
    hideConfirmShipmentCta: PropTypes.bool,
    membershipBag: PropTypes.object,
    membershipState: PropTypes.object,
    onMembershipCheckout: PropTypes.func.isRequired,
    promo: PropTypes.object, // TODO: define a shape or create a customPropType
    smBag: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      planInfoSectionIsExpanded: false,
      promoFieldOpen: false,
    };
  }

  logTooltipOpen = tooltipIsOpen => {
    // We don't log if the tooltip isn't open
    if (!tooltipIsOpen) {
      return;
    }

    const membershipType = this.props.membershipState?.membershipType;

    ActionLogger.logAction({
      object_type: analytics.ADD_ONS,
      action: "tooltip",
      membership_type: membershipType,
      source: analytics.ACTION_LOCATIONS.CHECKOUT,
    });
  };

  renderMerchCreditApplied = () => {
    const { ceCheckout, membershipBag, smBag } = this.props;

    let creditAmount;
    if (smBag?.checkout?.expectedPayments?.["MERCH_CREDIT"]) {
      creditAmount = priceStringIntoFormattedNumber(smBag.checkout.expectedPayments["MERCH_CREDIT"]);
    } else if (ceCheckout?.expectedPayments?.["MERCH_CREDIT"]) {
      creditAmount = priceStringIntoFormattedNumber(ceCheckout.expectedPayments["MERCH_CREDIT"]);
    } else {
      const merchCredits = merchCreditsApplied(membershipBag);
      creditAmount = priceStringIntoFormattedNumber(merchCredits);
    }

    if (!creditAmount) {
      return;
    }
    return (
      <div className="membership-footer__summary__credits">
        <span className="membership-footer__summary__credits__description">Merch Credit</span>
        <span className="membership-footer__summary__credits__value"> - ${creditAmount}</span>
      </div>
    );
  };

  openAddressBook = () => {
    this.props.displayModal(ChangeAddressModalNames.changeAddressFromBag);
  };

  openPaymentProfileSelector = () => {
    this.props.displayModal(ChangePaymentMethodModalNames.changePaymentMethodFromBag);
  };

  renderChargeLineItem(description, value, isNegative = false, isPromo = false) {
    if (!description || !value) {
      return null;
    }

    const isDiscount = usdPriceIntoFloat(value) < 0;

    const lineItemClassName = "membership-footer__summary__charges__line-item";

    return (
      <p className={`${lineItemClassName} body-copy${isPromo ? " promo" : ""}`}>
        <span className={`${lineItemClassName}__description`}>{description}</span>
        <span className={`${lineItemClassName}__value ${isDiscount ? "discount" : ""}`}>
          {(isNegative ? "-" : "") + formattedPrice(value, false)}
        </span>
      </p>
    );
  }

  getUpgradePrice() {
    const { ceCheckout, isSMBagEnabled, membershipBag, membershipState, smBag } = this.props;

    if (isSMBagEnabled && smBag?.numUpgradesInBag && smBag?.baseSlotUpgradePrice) {
      return this.priceIfNotZero(priceStringIntoFloat(smBag.baseSlotUpgradePrice) * smBag.numUpgradesInBag);
    } else if (ceCheckout?.bagItems) {
      return this.priceIfNotZero(
        MembershipUpgradesHelpers.getUpgradeExtraMonthlyCostCE(membershipState, ceCheckout.bagItems.length)
      );
    }

    const upgradePrice = baseSlotUpgradeTotal(membershipBag);
    return priceStringIntoFloat(upgradePrice);
  }

  priceIfNotZero = price => (isZeroAmount(price) ? null : price);

  renderPrice() {
    const { ceCheckout, ceCheckoutPromoError, isSMBagEnabled, membershipBag, membershipState, smBag } = this.props;

    const orderInvoices = membershipBag?.orderInvoice?.totals;
    const upgradePrice = this.getUpgradePrice();

    // NW [EXPLANATION] 3/29/19: if there's no upgrade price, we should have no prices to display here.
    if (!upgradePrice) {
      if (!ceCheckoutPromoError) return null;

      /**
       * DV [EXPLANATION] 5/15/23: if there's no upgrade price but there IS a checkout promo error
       * (this would happen if the user had a promo code set but then removed bag items such that
       * there's no longer an upgrade price), show the promo code section so the user can remove the
       * promo code and proceed with checkout. See CE-1322
       */
      return (
        <div className="membership-footer__summary membership-footer__line-item">{this.renderPromoCodeSection()}</div>
      );
    }

    let prorationAmount, promoItemTotal, taxTotal, total, numItems;
    if (isSMBagEnabled) {
      prorationAmount = this.priceIfNotZero(smBag?.checkout?.charges?.totals?.slotProration);
      promoItemTotal = this.priceIfNotZero(smBag?.checkout?.charges?.totals?.promoItem);
      taxTotal = this.priceIfNotZero(smBag?.checkout?.charges?.totals?.tax);

      // if there are charges but the total isn't present, then the total must be $0.
      // we should still show $0 to the user (e.g. they have priced items but merch credit or something covers it)
      total = smBag?.checkout?.expectedPayments?.CREDIT_CARD ?? "USD 0.00";
      numItems = smBag?.numUpgradesInBag;
    } else if (ceCheckout) {
      prorationAmount = this.priceIfNotZero(ceCheckout.charges?.totals?.slotProration);
      promoItemTotal = this.priceIfNotZero(ceCheckout.charges?.totals?.promoItem);
      taxTotal = this.priceIfNotZero(ceCheckout.charges?.totals?.tax);
      total = ceCheckout?.expectedPayments?.CREDIT_CARD ?? "USD 0.00";
      numItems = MembershipUpgradesHelpers.getUpgradesInCEBag(membershipState, ceCheckout?.bagItems?.length ?? 0);
    } else {
      prorationAmount = _.findWhere(orderInvoices, { type: "slotProration" })?.amount;
      promoItemTotal = _.findWhere(orderInvoices, { type: "promoItem" })?.amount;
      taxTotal = _.findWhere(orderInvoices, { type: "tax" })?.amount;
      total = _.findWhere(orderInvoices, { type: "total" })?.amount;
      numItems = MembershipUpgradesHelpers.getUpgradesInBag(membershipState, membershipBag);
    }
    const planUpgradeCopy = `${numItems} Spot Upgrade`;

    // if there is a promo code error then open the field so the user can see it
    // (but check state too so we don't infinite loop)
    if (ceCheckoutPromoError && !this.state.promoFieldOpen) {
      this.setState({ promoFieldOpen: true });
    }

    return (
      <div className="membership-footer__summary membership-footer__line-item">
        <section className="membership-footer__summary__label">
          <h5 className="bag-section-heading">Summary</h5>
          {!this.state.promoFieldOpen && !isSMBagEnabled && (
            <button
              onClick={() => this.setState({ promoFieldOpen: true })}
              data-test-id="add-promo-code-button"
              className="membership-footer__promo-action">
              Add Promo Code
            </button>
          )}
        </section>
        {(this.state.promoFieldOpen || isSMBagEnabled) && this.renderPromoCodeSection()}
        <div className="membership-footer__summary__charges">
          {this.renderChargeLineItem(planUpgradeCopy, upgradePrice)}
          {this.renderChargeLineItem("Mid-Month Discount", prorationAmount)}
          {this.renderChargeLineItem("Promo Savings", isZeroAmount(promoItemTotal) ? "" : promoItemTotal, null, true)}
          {this.renderChargeLineItem("Tax", taxTotal)}
        </div>
        {this.renderMerchCreditApplied()}
        <div className="membership-footer__summary__final">
          <span className="membership-footer__summary__final__description">Total</span>
          <span className="membership-footer__summary__final__value">{formattedPrice(total, false)}</span>
        </div>
      </div>
    );
  }

  getAddress() {
    const { ceCheckout, smBag, isSMBagEnabled } = this.props;
    let shippingAddress;

    if (isSMBagEnabled) {
      shippingAddress = smBag?.checkout?.shippingAddress;
    } else {
      shippingAddress = ceCheckout?.shippingAddress;
    }

    if (shippingAddress) {
      return <AtomInlineAddress address={shippingAddress} />;
    }

    return <p>Please enter a shipping address</p>;
  }

  renderShippingAddress() {
    const shippingAddressBodyClass = classNames("membership-footer__shipping-address__street");

    return (
      <>
        <div
          role="button"
          onKeyPress={onEnterPress(this.openAddressBook)}
          tabIndex={0}
          onClick={this.openAddressBook}
          className="membership-footer__shipping-address membership-footer__line-item">
          <div className="membership-footer__shipping-address-label">
            <h5 className="bag-section-heading">Shipping</h5>
          </div>
          <div className={shippingAddressBodyClass}>{this.getAddress()}</div>
          <div className="membership-footer__edit">
            <button className="address-edit"></button>
          </div>
        </div>
        <Emissions additionalClassNames="membership-footer__shipping-address membership-footer__line-item" />
      </>
    );
  }

  renderBillingId() {
    const { ceCheckout, isSMBagEnabled, smBag } = this.props;

    const source = isSMBagEnabled ? smBag?.checkout : ceCheckout;
    const total = source?.charges?.totals?.grand;

    // if there's no dollar amount charge, hide billing info
    if (!priceStringIntoFloat(total)) return;

    const activeCard = source?.paymentMethods?.[0];
    const billingInfo = getBillingInfo(activeCard);

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyDown={onEnterPress(this.openPaymentProfileSelector)}
        onClick={this.openPaymentProfileSelector}
        className="membership-footer__billing membership-footer__line-item"
        data-test-id="membership-footer-billing">
        <div className="membership-footer__billing-label">
          <h5 className="bag-section-heading">Billing</h5>
        </div>
        {billingInfo.creditCardIcon}
        <span className="membership-footer__billing_info body-copy body-copy--disabled">
          {billingInfo.billingInfoCopy}
        </span>
        <div className="membership-footer__edit">
          <button className="billing-edit"></button>
        </div>
      </div>
    );
  }

  renderPlanInfo = () => {
    const { ceCheckout, isSMBagEnabled, membershipBag, membershipState, smBag } = this.props;

    if (!this.isPlanUpgrading()) {
      return null;
    }

    let newSpotCount, extraMonthlyCost;

    if (isSMBagEnabled) {
      newSpotCount = smBag?.baseSlotCount + smBag?.numUpgradesInBag;
      extraMonthlyCost =
        smBag?.baseSlotUpgradePrice && smBag?.numUpgradesInBag
          ? priceStringIntoFloat(smBag.baseSlotUpgradePrice) * smBag.numUpgradesInBag
          : 0;
    } else {
      const itemsInBag = ceCheckout?.bagItems?.length ?? 0;
      newSpotCount = MembershipUpgradesHelpers.getUpgradePendingSlotCountCE(membershipState, itemsInBag);
      extraMonthlyCost = MembershipUpgradesHelpers.getUpgradeExtraMonthlyCostCE(membershipState, itemsInBag);
    }

    const numberFreeSpots = MembershipUpgradesHelpers.getFreeSlotsInBag(membershipBag);
    const extraSpotsPrice = extraMonthlyCost === 0 ? "+ $0" : `+ $${extraMonthlyCost}/month`;
    const title = extraMonthlyCost === 0 ? `Free Extra ${pluralize("Spot", numberFreeSpots)}!` : "New Plan";

    return (
      <div className="membership-footer__new-plan membership-footer__line-item">
        <div>
          <h5 className="bag-section-heading">{title}</h5>
        </div>
        <div className="membership-footer__new-plan__content">
          <p className="body-copy">
            {newSpotCount} spots per shipment
            <button
              className="membership-footer__new-plan__content__question"
              onClick={this.togglePlanInfoExpandedState}
              aria-label="Click to view more details about your new plan"
            />
          </p>
          <p className="membership-footer__new-plan__content__price body-copy">{extraSpotsPrice}</p>
        </div>
        {this.renderExpandedDetail()}
      </div>
    );
  };

  togglePlanInfoExpandedState = () => {
    const planIsExpanding = !this.state.planInfoSectionIsExpanded;
    this.logTooltipOpen(planIsExpanding);

    this.setState({
      planInfoSectionIsExpanded: planIsExpanding,
    });
  };

  renderExpandedDetail() {
    const baseClass = "membership-footer__new-plan__expanded";
    const openClass = ` ${baseClass}--open`;
    const className = baseClass + (this.state.planInfoSectionIsExpanded ? openClass : "") + " body-copy";

    const { ceCheckout, membershipState, isSMBagEnabled, smBag } = this.props;

    const content = MembershipUpgradesHelpers.getPlanChangeDetailsCE(
      membershipState,
      isSMBagEnabled ? smBag.items?.arriving?.length : ceCheckout?.bagItems?.length
    );

    return (
      <p className={className} data-test-id="footer-new-plan-expanded-details">
        {content}
      </p>
    );
  }

  fetchMembershipBag() {
    this.props.dispatch(membershipBagActions.fetchBag());
  }

  handleApplyPromoCodeSuccess = (_data, promoCode) => {
    const {
      orderSummary: { actions },
    } = CheckoutLogging;

    this.fetchMembershipBag();

    ActionLogger.logAction(actions.apply_promo_code, {
      promo_code: promoCode,
      success: true,
    });
  };

  handleApplyPromoCodeFailure = (err, promoCode) => {
    const {
      orderSummary: { actions },
    } = CheckoutLogging;

    ActionLogger.logAction(actions.apply_promo_code, {
      promo_code: promoCode,
      success: false,
      error: err?.responseText,
    });
  };

  handleRemovePromoCodeSuccess = () => {
    const {
      orderSummary: { actions },
    } = CheckoutLogging;

    this.fetchMembershipBag();

    ActionLogger.logAction(actions.remove_promo_code, {
      success: true,
    });
  };

  handleRemovePromoCodeFailure = err => {
    const {
      orderSummary: { actions },
    } = CheckoutLogging;

    ActionLogger.logAction(actions.remove_promo_code, {
      success: false,
      error: err?.responseText,
    });
  };

  hasPromoCodeError() {
    return this.props.promo?.error?.hasError || !!this.props.ceCheckoutPromoError;
  }

  shouldSeePromoField() {
    const { ceCheckoutPromoError } = this.props;

    // if there's no original dollar amount charge, hide the promocode input
    return Boolean(ceCheckoutPromoError || !!this.getUpgradePrice());
  }

  onApplyPromoCode = (checkoutId, promoCode) => {
    this.props.dispatch(SMBagActions.applyPromoCode(checkoutId, promoCode));
  };

  onRemovePromoCode = checkoutId => {
    this.props.dispatch(SMBagActions.removePromoCode(checkoutId));
  };

  renderPromoCodeSection() {
    const { ceCheckout, ceCheckoutPromoError, isSMBagEnabled, smBag } = this.props;

    if (!this.shouldSeePromoField()) {
      return;
    }

    let toRender;

    if (isSMBagEnabled) {
      toRender = (
        <SMBagPromoCode
          checkoutId={smBag?.checkout?.id}
          appliedPromoCode={smBag?.checkout?.promoCode ?? null}
          appliedPromoSavings={formatSavingsAsPositive(smBag?.checkout?.charges?.totals?.promoItem) ?? null}
          promoCodeError={ceCheckoutPromoError}
          onApply={this.onApplyPromoCode}
          onRemove={this.onRemovePromoCode}
        />
      );
    } else if (ceCheckout) {
      toRender = (
        <CEMembershipPromoCode
          checkoutId={ceCheckout.id}
          appliedPromoCode={ceCheckout?.promoCode ?? null}
          promoCodeError={ceCheckoutPromoError}
        />
      );
    } else {
      const orderId = this.props?.membershipBag?.group?.orderId || "";
      const orderInvoices = this.props.membershipBag?.orderInvoice?.totals;
      const promoItemTotal = _.findWhere(orderInvoices, { type: "promoItem" })?.amount;
      const appliedPromoCode = this.props?.membershipBag?.promoCode;
      const promoCode = isZeroAmount(promoItemTotal) ? "" : appliedPromoCode;

      toRender = (
        <>
          <span>Promo Code</span>{" "}
          <ExpressBagPromoCodeComponent
            defaultOpen={true}
            notInput={true}
            orderId={orderId}
            onApplyPromoCodeSuccess={this.handleApplyPromoCodeSuccess}
            onApplyPromoCodeFailure={this.handleApplyPromoCodeFailure}
            onRemovePromoCodeSuccess={this.handleRemovePromoCodeSuccess}
            onRemovePromoCodeFailure={this.handleRemovePromoCodeFailure}
            value={promoCode}
          />
        </>
      );
    }

    return (
      <div
        className="membership-footer__promo-code membership-footer__summary__final body-copy"
        data-test-id="membership-bag-promo-code-field">
        {toRender}
      </div>
    );
  }

  isPlanUpgrading = () => {
    const { ceCheckout, membershipState, isSMBagEnabled, smBag } = this.props;
    let upgradesInBag;
    if (isSMBagEnabled) {
      upgradesInBag = smBag?.numUpgradesInBag;
    } else {
      upgradesInBag = MembershipUpgradesHelpers.getUpgradesInCEBag(membershipState, ceCheckout?.bagItems?.length);
    }

    return upgradesInBag > 0;
  };

  isMembershipSwapFlow = () => {
    const { membershipState } = this.props;
    return MembershipHelpers.canInitiateSwapFlow(membershipState) || MembershipHelpers.inSwapFlow(membershipState);
  };

  renderConfirmation() {
    if (this.props.hideConfirmShipmentCta || this.props.isSMBagEnabled) {
      return null;
    }

    return (
      <CheckoutButton
        disabled={this.props.isSubmitDisabled}
        isPlanUpgrading={this.isPlanUpgrading()}
        isMembershipSwapFlow={this.isMembershipSwapFlow()}
        onClick={this.props.onMembershipCheckout}
      />
    );
  }

  renderTerms() {
    const { isSMBagEnabled, bagTab } = this.props;
    if (isSMBagEnabled && bagTab !== bagTabs.CLASSIC_TAB) {
      return null;
    }

    return <Terms />;
  }

  getCEArrivalDate = () => {
    const { ceCheckout } = this.props;
    return ceCheckout?.estimatedArrivalDate ?? null;
  };

  renderArrivalDate() {
    const { isSMBagEnabled, ceCheckout, membershipState } = this.props;
    const bagIsFull = ceCheckout?.bagItems?.length >= MembershipHelpers.fetchNumOpenSlots(this.props.membershipState);
    const hideInSwapFlow =
      MembershipHelpers.canInitiateSwapFlow(membershipState) || MembershipHelpers.inSwapFlow(membershipState);

    if (isSMBagEnabled || !bagIsFull || hideInSwapFlow) {
      return null;
    }

    const arrivalDate = this.getCEArrivalDate();

    if (!arrivalDate) {
      return null;
    }

    const formattedArrivalDate = format(parseISO(arrivalDate || ""), dateFnsFormats.EEEE_MMMM_d_YYYY);

    return (
      <div className="membership-footer__arrival-date membership-footer__line-item">
        <h5 className="bag-section-heading">Estimated Arrival</h5>
        <p className="body-copy">{formattedArrivalDate}</p>
      </div>
    );
  }

  render() {
    // Do not delete membership-footer class, we need it to scroll in the bag.
    // The associated code is located in SwapHeader
    return (
      <div className="membership-footer" ref={e => (this.membershipFooter = e)}>
        {this.renderPlanInfo()}
        {this.renderArrivalDate()}
        {this.renderShippingAddress()}
        {this.renderBillingId()}
        {this.renderPrice()}
        {this.renderTerms()}
        {this.renderConfirmation()}
      </div>
    );
  }
}

const mapStateToProps = ({ bagTab, ceCheckoutPromoError }) => ({
  bagTab,
  ceCheckoutPromoError,
});

export default connect(mapStateToProps, null)(MembershipFooter);
