import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ceCheckoutActions from "actions/ce-checkout-actions";

const CEMembershipPromoCode = ({ checkoutId, appliedPromoCode, promoCodeError, placeholderText, hideLabel }) => {
  const [value, setValue] = useState("");
  const isLoading = useSelector(state => state.ceCheckoutPromoLoading);

  const onChange = event => {
    setValue(event.target.value);
  };

  const dispatch = useDispatch();

  function onClick() {
    if (!appliedPromoCode) {
      if (!value) return;

      dispatch(ceCheckoutActions.applyPromoCode(checkoutId, value));
    } else {
      setValue("");
      dispatch(ceCheckoutActions.removePromoCode(checkoutId));
    }
  }

  function renderInputs() {
    let ctaText;

    if (!appliedPromoCode) {
      ctaText = !isLoading ? "Apply" : "Applying...";
    } else {
      ctaText = !isLoading ? "Remove" : "Removing...";
    }

    return (
      <>
        {!hideLabel && (
          <>
            <span className="membership-footer__promo-code__label">Promo Code</span>{" "}
          </>
        )}
        <input
          className="express-bag-promo-code__input"
          type="text"
          id="promoCode"
          name="promoCode"
          onChange={onChange}
          placeholder={placeholderText ?? "Optional"}
          readOnly={!!appliedPromoCode}
          data-test-id="ce-promo-code-text-input"
          value={appliedPromoCode ?? value}></input>
        <button
          className="express-bag-promo-code__button"
          data-test-id="ce-promo-code-cta-button"
          onClick={onClick}
          disabled={isLoading}>
          {ctaText}
        </button>
      </>
    );
  }

  function renderError() {
    let ctaText;

    /**
     * we have to account for two kinds of promo code errors:
     *
     * 1. "active" promo code errors, which are errors that are returned from the server with an
     * error HTTP code. in this case the promo code is not applied and the user is prompted to try again.
     * 2. "passive" promo code errors, which can happen if the user applied a promo code that was valid
     * at the time of application, but is no longer valid at the time of checkout.
     * e.g. user applied a valid promo code, but then the user removed items, rendering the promo code
     * invalid. this would show as the promo code being applied to the checkout but the checkout would
     * have a promo code error. these would also persist between page loads.
     * in this case we prompt the user to remove the promo code (requiring an AJAX request) as the user
     * won't be able to complete checkout otherwise.
     */
    if (appliedPromoCode && isLoading) {
      ctaText = "Removing...";
    } else if (appliedPromoCode) {
      ctaText = "Remove";
    } else {
      ctaText = "Try Again";
    }

    return (
      <div data-test-id="promo-code-error" className="promo-code-error">
        <span>{promoCodeError}</span>
        <button
          className="express-bag-promo-code__button express-bag-promo-code__button--error"
          data-test-id="ce-promo-code-cta-button-error"
          onClick={() => {
            appliedPromoCode
              ? dispatch(ceCheckoutActions.removePromoCode(checkoutId))
              : dispatch(ceCheckoutActions.clearPromoErrors());
          }}
          disabled={isLoading}>
          {ctaText}
        </button>
      </div>
    );
  }

  return <div className="express-bag-promo-code">{promoCodeError ? renderError() : renderInputs()}</div>;
};

CEMembershipPromoCode.propTypes = {
  checkoutId: PropTypes.string.isRequired,
  appliedPromoCode: PropTypes.string,
  hideLabel: PropTypes.bool,
  promoCodeError: PropTypes.string,
  placeholderText: PropTypes.string,
};

export default CEMembershipPromoCode;
