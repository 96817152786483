import React, { useEffect, useState } from "react";
import ActionLogger from "action-logger";
import {
  analytics,
  ChangeAddressModalNames,
  ChangePaymentMethodModalNames,
  flagsAndExperimentNames,
  KIFAHTriggerTypes,
} from "rtr-constants";
import { useDispatch, useSelector } from "react-redux";
import { differenceInDays } from "date-fns";
import { parseISOWithoutTime } from "helpers/date-helpers";
import { setKifahAvailableItems, setKifahTrigger } from "actions/kifah-actions";
import { useOnLoadFlagOrExperiment } from "components/source/hoc/with-flags-and-experiments";
import MembershipHelpers from "helpers/membership-helpers";
import sharedActions from "actions/shared-actions";
import SMBagActions from "actions/sm-bag-actions";
import ceCheckoutActions from "actions/ce-checkout-actions";
import MembershipFooter from "components/source/checkout/bag/membership/footer";
import ChangeShippingAddress from "components/source/shared/shipping_addresses/ChangeShippingAddress";
import ManagePaymentMethods from "components/source/shared/manage-payment-methods";
import AtomButton from "components/source/atoms/atom-button";
import Terms from "components/source/checkout/bag/membership/terms";
import ApplePaySectionBag from "../../apple-pay-section-bag";
import { evaluateApplePayOptions, PAYMENT_OPTION_RULE } from "helpers/payment-options-helper";
import { getRoktLauncher, getUsableRoktAttributes } from "helpers/third-party/rokt-helpers";

const SmBagFooter = () => {
  const dispatch = useDispatch();

  const ceCheckoutIsLoading = useSelector(state => state.ceCheckoutLoading) || false;
  const ceCheckoutIsSubmitting = useSelector(state => state.ceCheckoutSubmitting) || false;
  const ceCheckoutPromoIsLoading = useSelector(state => state.ceCheckoutPromoLoading) || false;
  const membershipBag = useSelector(state => state.membershipBag) || {};
  const membershipState = useSelector(state => state.membershipState) || {};
  const workingFilters = useSelector(state => state.workingFilters) || {};
  const userData = useSelector(state => state.userData) || {};
  const smBag = useSelector(state => state.smBag) || {};
  const smBagArrivingItems = useSelector(state => state.smBag?.items.arriving) || [];
  const smBagNumOpenSlots = useSelector(state => state.smBag?.numOpenSlots) || 0;
  const smBagCheckout = useSelector(state => state.smBag?.checkout) || {};
  const smBagError = useSelector(state => state.smBag?.smBagError) || "";
  const failedToRefreshItems = useSelector(state => state.smBag?.failedToRefreshItems) || [];
  const smBagIsLoading = useSelector(state => state.smBag?.smBagLoading) || false;
  const smBagIsFull = useSelector(state => state.smBag?.isBagFull) || false;
  const numUpgradesInBag = useSelector(state => state.smBag?.numUpgradesInBag) || 0;
  const paymentMethods = useSelector(state => state.paymentProfiles) || [];
  const isInSwapFlow = useSelector(state => state.smBag?.isInSwapFlow) || false;

  const [showApplePayForSubscription] = useOnLoadFlagOrExperiment(
    flagsAndExperimentNames?.COMET_APPLE_PAY_SUBSCRIPTION
  );
  const [checkForThirtyDayItemsForKifah] = useOnLoadFlagOrExperiment(
    flagsAndExperimentNames?.SWAT_SF_SWAT_624_THIRTY_DAY_KIFAH_TRIGGER
  );
  const [paymentRule, setPaymentRule] = useState(PAYMENT_OPTION_RULE.DISPLAY_COF);

  const ceCheckout = {
    ...smBagCheckout,
    userId: smBag.id,
    bagItems: smBag.items?.arriving || [],
  };

  const isSubmitDisabled = Boolean(
    smBagError ||
      smBagIsLoading ||
      ceCheckoutIsLoading ||
      ceCheckoutPromoIsLoading ||
      ceCheckoutIsSubmitting ||
      smBagCheckout?.passivePromoError ||
      failedToRefreshItems?.length > 0 ||
      !smBagIsFull
  );

  const displayModal = modalName => dispatch(sharedActions.displayModal(modalName));

  useEffect(() => {
    if (paymentMethods) {
      setPaymentRule(evaluateApplePayOptions(paymentMethods, window?.ApplePaySession));
    }
  }, [paymentMethods]);

  const onChangePaymentMethod = paymentMethod => {
    if (smBagCheckout?.id) {
      dispatch(SMBagActions.setPaymentMethod(smBagCheckout.id, paymentMethod.id));
    }
  };

  const getReturnDetails = () => {
    const homePickupEligibilityAction = () => {
      ActionLogger.logAction({
        object_type: analytics.OBJECT_TYPE.HOME_PICKUP_SCHEDULER,
        action: analytics.ACTION_TYPE.HOME_PICKUP_VISIBLE,
        user_id: membershipState?.id,
        membership_id: membershipState?.membershipId,
        zipcode: membershipState?.address?.postalCode,
        orderId: MembershipHelpers.getMostRecentOrderId(membershipState),
      });
    };

    return {
      returningItems: smBag?.items?.returning || [],
      rentalBeginDate: smBagCheckout?.estimatedArrivalDate,
      actionLogger: homePickupEligibilityAction,
    };
  };

  /**
   * go through membershipItems and do the following checks
   * - kept at home item for 30 days
   *
   */
  const checkForKifahItems = () => {
    const itemsAtHome = smBag.items?.keeping || [];
    const findThirtyDayItemsFn = item =>
      differenceInDays(parseISOWithoutTime(new Date()), parseISOWithoutTime(item?.rentalBeginDate)) || 0;

    return itemsAtHome.reduce((prev, curr) => {
      const memberhipItem = membershipState?.membershipItems.find(item => item.bookingId === curr.bookingId);
      const difference = findThirtyDayItemsFn(memberhipItem);

      if (difference > 30) {
        prev.push({
          ...curr,
          kifahReason: `You've kept this item for over ${difference} days`,
          kifahTrigger: KIFAHTriggerTypes.THIRTY_DAYS,
        });
      }
      return prev;
    }, []);
  };

  const onMembershipCheckout = () => {
    if (checkForThirtyDayItemsForKifah && checkForKifahItems().length > 0) {
      dispatch(setKifahTrigger(KIFAHTriggerTypes.THIRTY_DAYS));
      dispatch(setKifahAvailableItems(checkForKifahItems()));
    } else {
      dispatch(ceCheckoutActions.placeOrder(ceCheckout, getReturnDetails(), isInSwapFlow)).then(placedOrder => {
        if (isInSwapFlow) {
          /**
           * the actual rokt placeholder is in the <CEBag /> component.
           * but we'll select the placements here because this is the point
           * we'll have the order id, which Rokt needs.
           */
          dispatch(getRoktLauncher())
            .then(launcher => {
              launcher?.selectPlacements({
                attributes: getUsableRoktAttributes(userData, placedOrder?.id),
                identifier: "swapFlowConfirmation",
              });
            })
            .catch(_ => {});
        }
      });
    }
  };

  /**
   * checks:
   * ApplePaySession available
   * Checkout has loaded with charges (required for apple pay wrapper)
   * Bag contains a spot upgrade
   * Flag is on
   */
  const applePayAllowed = () => {
    const isUpgrade = ceCheckout?.bagItems?.length > membershipState?.baseSlotCount;
    const isCharge = smBagCheckout?.charges ?? false;
    return isCharge && isUpgrade && showApplePayForSubscription;
  };

  const renderConfirmationCTA = () => {
    let buttonText;

    if (applePayAllowed() && window?.ApplePaySession) {
      buttonText = "Confirm Order with Card On File";
    } else {
      if (numUpgradesInBag > 0) {
        buttonText = "Confirm Shipment and New Plan";
      } else if (smBagIsFull) {
        buttonText = "Confirm Shipment";
      } else if (smBagNumOpenSlots > smBagArrivingItems.length) {
        buttonText = `Add ${smBagNumOpenSlots - smBagArrivingItems.length} more items`;
      } else if (smBagArrivingItems.length === 0 && smBagNumOpenSlots === 0 && isInSwapFlow) {
        buttonText = "Add More Items";
      }
    }
    return (
      <AtomButton
        className="confirm-button"
        onClick={onMembershipCheckout}
        buttonText={buttonText}
        disabled={isSubmitDisabled}
      />
    );
  };

  const renderApplePaySection = withCTA => {
    const applePayStyle = {
      type: "order",
      style: withCTA ? "white-outline" : "black",
    };
    const cta = withCTA ? renderConfirmationCTA : null;
    // if show both pass in the render confirm cta
    return (
      <ApplePaySectionBag
        renderConfirmPurchase={cta}
        checkout={ceCheckout}
        applePayStyle={applePayStyle}
        bag={smBag}
        onSuccessfulPayment={orderId => {
          dispatch(ceCheckoutActions.afterPlacedMembershipOrder(orderId, getReturnDetails()));
        }}
      />
    );
  };

  const renderOption = () => {
    if (!applePayAllowed()) return renderConfirmationCTA();

    if (paymentRule === PAYMENT_OPTION_RULE.DISPLAY_APPLE_PAY) {
      return renderApplePaySection();
    }

    if (paymentRule === PAYMENT_OPTION_RULE.DISPLAY_COF) {
      return renderConfirmationCTA();
    }

    if (paymentRule === PAYMENT_OPTION_RULE.DISPLAY_BOTH) {
      return renderApplePaySection(true);
    }

    if (paymentRule === PAYMENT_OPTION_RULE.UPDATE_BILLING) {
      return (
        <AtomButton
          className="confirm-button"
          onClick={() => {}}
          buttonText={"UPDATE BILLING METHOD"}
          disabled={true}
        />
      );
    }
  };

  const renderConfirmSection = () => {
    return (
      <div className="confirm-button-container">
        {renderOption()}
        <Terms isSMBagEnabled={true} />
      </div>
    );
  };

  return (
    <div className="simplify-membership-bag-footer">
      <MembershipFooter
        ceCheckout={ceCheckout}
        dispatch={dispatch}
        displayModal={displayModal}
        isSMBagEnabled={true}
        isSubmitDisabled={isSubmitDisabled}
        membershipBag={membershipBag}
        membershipState={membershipState}
        onMembershipCheckout={onMembershipCheckout}
        smBag={smBag}
        userData={userData}
        workingFilters={workingFilters}
      />
      {renderConfirmSection()}
      <ChangeShippingAddress
        addressBookTitle="Where will we be shipping?"
        modalName={ChangeAddressModalNames.changeAddressFromBag}
        isSMBagEnabled={true}
        onChange={() => {
          dispatch(SMBagActions.fetchSMBag(false, true));
        }}
      />
      <ManagePaymentMethods
        displayModal={displayModal}
        modalName={ChangePaymentMethodModalNames.changePaymentMethodFromBag}
        onCreatePaymentMethodSuccess={onChangePaymentMethod}
        onUpdatePaymentMethodsSuccess={onChangePaymentMethod}
        showMakeDefaultCheckbox={false}
        setAsDefault={true}
        isBillingMethod={true}
      />
    </div>
  );
};

export default SmBagFooter;
