import PropTypes from "prop-types";
import { CEBagItemTypes } from "rtr-constants";
import styles from "./styles.module.scss";
import { ProductImageSize } from "helpers/product-image-helpers";
import { formattedPrice } from "helpers/invoice-helper";
import ProductImageWrapper from "components/source/shared/product-image-wrapper";
import Close from "images/close.svg";
import Routes from "routes";
import { usdPriceIntoFloat } from "helpers/PricingHelper";
import SubscriptionIcon from "./subscription-icon.svg";
import { getMembershipCopy } from "helpers/ce-bag-helpers";
import flagsAndExperimentsNames from "constants/flags-and-experiments-names";
import { useOnLoadFlagOrExperiment } from "components/source/hoc/with-flags-and-experiments";

const BagItem = ({ item, handleRemove, membershipTier }) => {
  const { styleName, designerName, displayName, images, beginDate, endDate, retailPrice, pricing, size, type } = item;

  const product = {
    id: styleName,
    designer: { displayName: designerName },
    displayName: displayName,
    images: images,
    beginDate: beginDate,
    endDate: endDate,
    size: size,
  };

  const showMembershipDiscountLabel = pricing?.adjustments?.some(adjustment => adjustment.reason === "Members Perk");

  // Refactor this peice once experiment RSV_CHANGE_DEFAULT_IMAGE_IN_BAG is over
  const [shouldChangeDefaultImageInBag] = useOnLoadFlagOrExperiment(
    flagsAndExperimentsNames.RSV_CHANGE_DEFAULT_IMAGE_IN_BAG
  );

  const renderSubscriptionContent = () => {
    const { title, swapCopy } = getMembershipCopy(membershipTier);

    return (
      <>
        <div className={styles["subscription-purchase-image-holder"]}>
          <SubscriptionIcon />
        </div>
        <div className={styles["content-holder"]}>
          <div className="designer">{title}</div>
          <ul className={styles["subscription-bullet-points"]}>
            {swapCopy && <li>{swapCopy}</li>}
            <li>Free shipping, rental coverage, and dry cleaning</li>
            <li>Pause, cancel, or switch your plan at anytime</li>
          </ul>
        </div>
      </>
    );
  };

  const displayItemPrice = pricing => {
    const basePrice = usdPriceIntoFloat(pricing.base);
    const endPrice = usdPriceIntoFloat(pricing.price);
    const isPriceReduced = basePrice > endPrice;

    const secondDiscountAdjustment = pricing?.adjustments?.find(
      adjustment => adjustment.reason === "Reduced Second Style" && adjustment.amount !== "USD 0.00"
    );

    const secondDiscountPrice = secondDiscountAdjustment
      ? basePrice + usdPriceIntoFloat(secondDiscountAdjustment.amount)
      : null;

    const showSecondDiscountPrice = secondDiscountPrice && secondDiscountPrice > endPrice;

    return (
      <div className={styles["prices"]}>
        <span className={isPriceReduced ? styles["cross-out-price"] : ""}>{formattedPrice(pricing.base)}</span>
        {isPriceReduced && showSecondDiscountPrice ? (
          <span className={`${styles["cross-out-price"]} ${styles["second-discount"]}`}>
            {formattedPrice(secondDiscountPrice)}
          </span>
        ) : null}
        {isPriceReduced && <span className={styles["promo-price"]}>{formattedPrice(pricing.price)}</span>}
      </div>
    );
  };

  const renderBagContent = () => {
    return (
      <div className={styles["content-holder"]}>
        <div className={styles["content-holder-top"]}>
          {displayName}
          <div className="universal-xsmall--secondary">
            {type === CEBagItemTypes.RESERVE ? designerName : `${formattedPrice(retailPrice)} Retail Price`}
          </div>
          <>Size {size}</>
        </div>

        <div className={`universal-xsmall--secondary`}>
          <div data-test-id="bag-item-price" className="universal-xsmall">
            {displayItemPrice(pricing)}
          </div>
          {showMembershipDiscountLabel && <div className={styles["promo-price"]}>Member Discount 25%</div>}
        </div>
      </div>
    );
  };

  return (
    <div className={styles["bag-item"]} data-test-id="single-group-bag-item">
      {type === CEBagItemTypes.SUBSCRIPTION ? (
        renderSubscriptionContent()
      ) : (
        <>
          <a
            data-test-id="bag-item-pdp-link"
            href={Routes.PDP.RouteById(styleName)}
            className={styles["product-image-container"]}>
            {/* Refactor this peice once experiment RSV_CHANGE_DEFAULT_IMAGE_IN_BAG is over */}
            <ProductImageWrapper
              productImageClassName={styles["product-image"]}
              product={product}
              size={ProductImageSize.x480}
              showImageWithModel={shouldChangeDefaultImageInBag}
            />
          </a>
          <>{renderBagContent()}</>
        </>
      )}

      <button
        className={styles["delete-button"]}
        data-test-id="single-group-bag-item-delete-button"
        onClick={() => handleRemove(item)}>
        <Close alt="Remove item" className={styles["delete-button-svg"]} />
      </button>
    </div>
  );
};

BagItem.propTypes = {
  item: PropTypes.object,
  type: PropTypes.oneOf(Object.values(CEBagItemTypes)),
  handleRemove: PropTypes.func,
  membershipTier: PropTypes.string,
};

export default BagItem;
