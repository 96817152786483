import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import PlusIcon from "images/plus_in_circle.svg";

// TODO: remove `hedTex` and `subTex` once this component is working as expected in production
// ensure the bag-upsells CMS component is aligned with `hedText` and `dekText` parameters
const Cta = ({ cta: { hedTex, hedText, subTex, dekText, link } }) => {
  return (
    <div className={styles["bag-item"]} data-test-id="bonus-group-bag-item">
      <a href={link} className={styles["placeholder-image"]} target="_blank" rel="noopener noreferrer">
        <div className={styles["plus-icon"]}>
          <PlusIcon />
        </div>
      </a>

      <div className={styles["placeholder-text"]}>
        <div className="designer">{hedTex || hedText}</div>
        <div className={styles["display-name"]}>{subTex || dekText}</div>
      </div>
    </div>
  );
};

Cta.propTypes = {
  cta: PropTypes.object,
};

export default Cta;
