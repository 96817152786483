import { isCookieCategoryActivated } from "../cookie-consent-helper";
import { RoktFlagStates, ONETRUST_CONSENT_MANAGEMENT } from "rtr-constants";
import {
  requestedFlagAndExperimentSelector,
  flagsAndExperimentNames,
} from "components/source/hoc/with-flags-and-experiments";

let launcher = null,
  initPromise = null,
  initFailed = false;

// resets the launcher singleton, should only be used in testing
export const reset = () => {
  launcher = null;
  initPromise = null;
  initFailed = false;
};

const logToSentry = (message, error = null) => {
  if (window.Sentry && window.Sentry.captureException) {
    window.Sentry.captureException(message, error, {
      tags: {
        thirdparty: true,
        thirdparty_name: "rokt",
      },
    });
  }
};

export const sourceScript = () => {
  // if the script already exists, don't load it again
  if (document.getElementById("rokt-launcher")) {
    return Promise.resolve();
  }

  const targetTime = Date.now() + 5000;
  return new Promise((resolve, reject) => {
    const target = document.head || document.body;
    const script = document.createElement("script");

    script.id = "rokt-launcher";
    script.type = "text/javascript";
    script.src = "https://apps.rokt.com/wsdk/integrations/launcher.js";
    script.fetchpriority = "high";
    script.crossOrigin = "anonymous";
    script.async = true;

    script.addEventListener("load", () => {
      if (targetTime < Date.now()) {
        console.warn("Rokt script took more than 5s to load");
        logToSentry("Rokt script took more than 5s to load");
      }

      return resolve();
    });

    script.addEventListener("error", error => {
      console.error("Error when loading Rokt script", error);
      logToSentry("Error when loading Rokt script", error);

      return reject(error);
    });

    target.appendChild(script);
  });
};

export const getUsableRoktAttributes = (userData, confirmationRef, extraAttributes = {}) => {
  let attributes = {
    confirmationref: confirmationRef,
  };

  /**
   * only add user attributes if the user consented to onetrust
   */
  if (isCookieCategoryActivated(ONETRUST_CONSENT_MANAGEMENT.COOKIE_CATEGORIES.TARGETING)) {
    attributes = {
      ...attributes,
      email: userData?.userProfile?.email,
      firstname: userData?.userProfile?.firstName,
      lastname: userData?.userProfile?.lastName,
      userId: userData?.id,
      ...extraAttributes,
    };
  }

  return attributes;
};

export const roktIsOnOrSandbox = flagState => {
  return flagState === RoktFlagStates.ENABLED || flagState === RoktFlagStates.SANDBOX;
};

export const getRoktLauncher = () => {
  // access to redux is needed to check the flag state and to get the rokt account id
  return async function (dispatch, getState) {
    const state = getState();
    const flagState = requestedFlagAndExperimentSelector(flagsAndExperimentNames.SF_ROKT)(state);

    if (!roktIsOnOrSandbox(flagState)) {
      return Promise.reject("Rokt is flagged off.");
    }

    // if the rokt launcher failed to load, just reject, don't try to load again
    if (initFailed) {
      return Promise.reject("Rokt launcher failed to instantiate previously.");
    }

    if (launcher) {
      return Promise.resolve(launcher);
    } else if (initPromise) {
      /**
       * if the launcher isn't made but the promise is, then the launcher is
       * still being made. don't do anything, just return the promise so the
       * caller can wait for it.
       */
      return initPromise;
    }

    initPromise = (async () => {
      try {
        await sourceScript();
      } catch (error) {
        initFailed = true;
        throw error;
      }

      const doNot = !isCookieCategoryActivated(ONETRUST_CONSENT_MANAGEMENT.COOKIE_CATEGORIES.TARGETING);

      try {
        const launcherInstance = await window.Rokt.createLauncher({
          accountId: state.publicEnv?.roktAccountId,
          sandbox: flagState === RoktFlagStates.SANDBOX,
          noTargeting: doNot,
          doNotShareOrSell: doNot,
        });

        launcher = launcherInstance;
        return launcherInstance;
      } catch (error) {
        console.error("Error creating Rokt launcher", error);
        logToSentry("Error creating Rokt launcher", error);
        initFailed = true;
        throw error;
      }
    })();

    return initPromise;
  };
};
