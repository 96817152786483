import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import BagGroup from "./bag-group/bag-group";

const ContentsMixedSingleGroup = ({ onCloseBag }) => {
  const mixedBag = useSelector(state => state.ceBag?.mixed);
  const bagTabId = useSelector(state => state.bagTabId);

  const activeBag = bagTabId
    ? mixedBag?.bagGroups?.find(bagGroup => bagGroup.id === bagTabId)
    : mixedBag?.bagGroups?.[0];

  const bagIsEmpty = () => {
    return !mixedBag?.bagGroups || mixedBag?.bagGroups.length === 0 || !activeBag;
  };

  return (
    <div className="single-group-checkout">
      {bagIsEmpty() ? (
        <div className="classic-bag-contents">
          <div className="empty-bag">
            <p className="empty-bag__caption dek-one">There are no items in your shopping bag.</p>
            <button className="btn btn-secondary" onClick={onCloseBag}>
              Continue Shopping
            </button>
          </div>
        </div>
      ) : (
        <BagGroup key={activeBag?.id} bagGroup={activeBag} />
      )}
    </div>
  );
};

ContentsMixedSingleGroup.propTypes = {
  onCloseBag: PropTypes.func.isRequired,
};

export default ContentsMixedSingleGroup;
