import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { ceBagGroupPropType } from "components/propTypes";
import { format, parseISO } from "date-fns";
import { dateFnsFormats } from "rtr-constants";
import styles from "./styles.module.scss";
import { getDaysBetweenDates } from "helpers/ce-checkout-helpers";

import FitGuarantee from "components/source/shared/fit-guarantee/fit-guarantee";
import Header from "./header";
import BagItem from "./bag-item";
import Cta from "./cta";

const ReserveBag = ({ bagGroup, handleRemove }) => {
  const bagUpsell = useSelector(state => state.cmsContent?.bagUpsell);
  const reserveDuration = getDaysBetweenDates(bagGroup.beginDate, bagGroup.endDate);

  const cmsKey = `${reserveDuration}dayRental`;

  // TODO: To facilitate the change from old to new format, the existing cms component will simply contain both.
  // Once deployed to production the old values can be deleted from the cms component
  // and we can remove the fallback conditionals. See unit tests for an example of old vs new.
  const rentalsHedText = bagUpsell?.[cmsKey]?.[0]?.hedText || "Your Event Looks";
  const upsellsHedText = bagUpsell?.[cmsKey]?.[1]?.hedText || bagUpsell?.hedText;
  const ctas = bagUpsell?.[cmsKey]?.[1]?.ctas || bagUpsell?.upSells;

  let endDate;
  if (
    new Date(parseISO(bagGroup.beginDate || "")).getMonth() === new Date(parseISO(bagGroup.endDate || "")).getMonth()
  ) {
    endDate = format(parseISO(bagGroup.endDate || ""), dateFnsFormats.dayOfMonth);
  } else {
    endDate = format(parseISO(bagGroup.endDate || ""), dateFnsFormats.MMM_D);
  }
  return (
    <>
      <div className={`${styles["reserve-bag-group-header"]}`}>
        <p className="universal-medium">{reserveDuration}-day Rental</p>
        <div className={`${styles["reserve-bag-group-header-container"]}`}>
          <p>
            <span className={`${styles["reserve-bag-group-header-dates"]}`}>
              {format(parseISO(bagGroup.beginDate || ""), dateFnsFormats.MMM_D)}-{endDate}
            </span>
            <span className={`${styles["reserve-bag-group-header-dates"]}`}>
              {`${format(parseISO(bagGroup.beginDate || ""), dateFnsFormats.dayOfWeekShort)} to 
              ${format(parseISO(bagGroup.endDate || ""), dateFnsFormats.dayOfWeekShort)}`}
            </span>
          </p>
          <p>
            <span className={styles["reserve-bag-group-header-shipping-text"]}>Shipping to</span> {bagGroup.zipCode}
          </p>
        </div>
      </div>
      <div className={styles["bag-group-items"]}>
        <Header type={bagGroup.bagGroupType} text={rentalsHedText} />
        {bagGroup.bagItems.map((item, index) => (
          <BagItem item={item} key={index} handleRemove={handleRemove} />
        ))}
        {
          // Only display these CTAs if no secondStyleDiscount is present
          // AND if bagUpsell?.[cmsKey]?.[0]?.ctas exists
          !Object.values(bagGroup.pricing?.itemPrices || {}).some(price => price?.secondStyleDiscount) &&
            bagUpsell?.[cmsKey]?.[0]?.ctas?.map((cta, index) => <Cta cta={cta} key={index} />)
        }
        {bagUpsell && (
          <>
            <Header type={bagGroup.bagGroupType} text={upsellsHedText} />
            {ctas.map((cta, index) => (
              <Cta cta={cta} key={index} />
            ))}
          </>
        )}
        <div className={styles["fit-gaurantee"]}>
          <FitGuarantee placement="bag" />
        </div>
      </div>
    </>
  );
};

ReserveBag.propTypes = {
  bagGroup: ceBagGroupPropType,
  handleRemove: PropTypes.func,
};

export default ReserveBag;
