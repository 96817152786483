import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import SimplifyMembershipBagActions from "actions/sm-bag-actions";
import SectionHeader from "../components/sm-bag-section-header";
import VerticalGrid from "../components/sm-bag-vertical-grid";

const propTypes = {
  keepingItemsRef: PropTypes.object,
  scrollToReturning: PropTypes.func,
  isOutOfShipments: PropTypes.bool,
};

function SMBagKeepingItems({ keepingItemsRef, scrollToReturning = () => {}, isOutOfShipments = false }) {
  const dispatch = useDispatch();
  const itemsToKeepRedux = useSelector(state => state.smBag?.items?.keeping) || [];
  const [itemsToKeepLocal, setItemsToKeepLocal] = React.useState(itemsToKeepRedux);
  const cmsPromoBannerContent = useSelector(state => state.cmsContent?.smBagPromoBanner);
  const promoBannerBgColor = cmsPromoBannerContent?.config?.backgroundColor;
  const promoBannerFontColor = cmsPromoBannerContent?.config?.fontColor;

  React.useEffect(() => {
    setItemsToKeepLocal(oldItems => {
      if (itemsToKeepRedux?.length < oldItems?.length) {
        scrollToReturning();
      }

      return itemsToKeepRedux;
    });
  }, [itemsToKeepRedux]);

  const handleSwap = item => {
    dispatch(SimplifyMembershipBagActions.setReturnPromise(item.bookingId));
  };

  return (
    <div className={`section-container${isOutOfShipments ? " remove-border" : ""}`} ref={keepingItemsRef}>
      {isOutOfShipments ? (
        <SectionHeader
          headerText="Don't want to say goodbye?"
          subHeaderText="Buy items you have at home at a discounted price."
        />
      ) : (
        <SectionHeader headerText="Keep Renting" subHeaderText="Swap to return or buy now to keep it forever." />
      )}
      {itemsToKeepLocal?.length > 0 && cmsPromoBannerContent?.bagScreen && (
        <div className="promo-banner" style={{ backgroundColor: promoBannerBgColor, color: promoBannerFontColor }}>
          {cmsPromoBannerContent.bagScreen}
        </div>
      )}
      {itemsToKeepLocal?.length > 0 ? (
        <VerticalGrid handleSwap={handleSwap} gridItems={itemsToKeepLocal} isOutOfShipments={isOutOfShipments} />
      ) : (
        <span className="universal-medium empty-text">Items you keep will appear here</span>
      )}
    </div>
  );
}

SMBagKeepingItems.propTypes = propTypes;
export default SMBagKeepingItems;
