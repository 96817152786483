import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CEBag from "./ce-bag";
import Overlay from "./overlay";
import { withFeatureFlags, featureFlagsPropType, Flags } from "components/source/hoc/with-feature-flags";
import {
  flagsAndExperimentsPropType,
  flagsAndExperimentNames,
  withOnLoadFlagsAndExperiments,
} from "components/source/hoc/with-flags-and-experiments";
import {
  bagTabPropType,
  browserPropType,
  membershipBagPropType,
  membershipStatePropType,
  ordersPropType,
  userDataPropType,
} from "components/propTypes";

export class BagContainerComponent extends Component {
  static propTypes = {
    activeOrders: ordersPropType,
    bagIsOpen: PropTypes.bool,
    bagTab: bagTabPropType,
    browser: browserPropType,
    ceBag: PropTypes.object,
    ceBagError: PropTypes.bool,
    ceBagLoading: PropTypes.bool,
    ceCheckout: PropTypes.object,
    ceCheckoutLoading: PropTypes.bool,
    ceCheckoutError: PropTypes.string,
    dispatch: PropTypes.func, // prefer connect with mapDispatchToProps, but this is a top-level component, which gets dispatch
    displayHomePickup: PropTypes.bool,
    displayHomePickupOverlay: PropTypes.bool,
    featureFlags: featureFlagsPropType,
    flagsAndExperiments: flagsAndExperimentsPropType,
    hasSMBagBeenFetched: PropTypes.bool,
    smBagLoading: PropTypes.bool,
    isHomePickupSchedulerLoading: PropTypes.bool,
    membershipBag: membershipBagPropType,
    membershipState: membershipStatePropType,
    userData: userDataPropType,
  };

  render() {
    // MH Explanation: this component shouldn't be rendered server-side at all, but if for some reason it is anyway
    if (process.env.IS_SSR) {
      return null;
    }

    return (
      <div className="bag-container">
        <CEBag
          activeOrders={this.props.activeOrders}
          bagIsOpen={this.props.bagIsOpen}
          bagTab={this.props.bagTab}
          browser={this.props.browser}
          dispatch={this.props.dispatch}
          displayHomePickup={this.props.displayHomePickup}
          displayHomePickupOverlay={this.props.displayHomePickupOverlay}
          featureFlags={this.props.featureFlags}
          hasSMBagBeenFetched={this.props.hasSMBagBeenFetched}
          isHomePickupSchedulerLoading={this.props.isHomePickupSchedulerLoading}
          isOutOfShipmentsScreenEnabled={
            this.props.flagsAndExperiments?.[flagsAndExperimentNames.DRE_SF_DR_824_OUT_OF_SHIPMENTS_SCREEN]
          }
          isSMKifahEnabled={true}
          membershipBag={this.props.membershipBag}
          membershipState={this.props.membershipState}
          ceBag={this.props.ceBag}
          ceBagError={this.props.ceBagError}
          ceBagLoading={this.props.ceBagLoading}
          ceCheckout={this.props.ceCheckout}
          ceCheckoutLoading={this.props.ceCheckoutLoading}
          ceCheckoutError={this.props.ceCheckoutError}
          userData={this.props.userData}
          smBagLoading={this.props.smBagLoading}
        />
        <Overlay bagIsOpen={this.props.bagIsOpen} membershipState={this.props.membershipState} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    activeOrders,
    activeOrdersError,
    activeOrdersLoading,
    bagIsOpen,
    bagTab,
    browser,
    ceBag,
    ceBagError,
    ceBagLoading,
    ceCheckout,
    ceCheckoutLoading,
    ceCheckoutError,
    dispatch,
    displayHomePickupOverlay,
    flagsAndExperiments,
    homePickup,
    membershipBag,
    membershipState,
    smBag,
    userData,
    workingFilters,
  } = state;

  return {
    activeOrders,
    activeOrdersError,
    activeOrdersLoading,
    bagIsOpen,
    bagTab,
    browser,
    ceBag,
    ceBagError,
    ceBagLoading,
    ceCheckout,
    ceCheckoutLoading,
    ceCheckoutError,
    dispatch,
    displayHomePickup: homePickup?.displayHomePickup,
    displayHomePickupOverlay,
    flagsAndExperiments,
    hasSMBagBeenFetched: !!smBag?.id,
    smBagLoading: smBag?.smBagLoading,
    isHomePickupSchedulerLoading: homePickup?.isSchedulerLoading,
    membershipBag,
    membershipState,
    userData,
    workingFilters,
  };
}
export const ConnectedBagContainer = compose(
  withFeatureFlags(Flags.HOME_PICKUP_AFTER_CHECKOUT),
  withOnLoadFlagsAndExperiments(
    flagsAndExperimentNames.DRE_SF_DR_946_SIMPLIFY_MEMBERSHIP_BAG_ENABLED // to be removed with https://renttherunway.jira.com/browse/SWAT-633
  ),
  connect(mapStateToProps)
)(BagContainerComponent);

export default ConnectedBagContainer;
