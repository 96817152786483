import React from "react";
import PropTypes from "prop-types";
import { findAmountForType, formatSavingsAsPositive } from "helpers/invoice-helper";
import DollarBill from "images/sprites/reb/svg/dollar_bill.svg";
import AtomHighlightTextButton from "components/source/atoms/atom-highlight-text-button";
import AtomSecondaryButton from "components/source/atoms/atom-secondary-button";
import { chargeOrTotalArrayPropType } from "components/propTypes";

const propTypes = {
  onClick: PropTypes.func.isRequired,
  appliedText: PropTypes.string,
  ctaText: PropTypes.string,
  hasError: PropTypes.bool,
  orderTotals: chargeOrTotalArrayPropType,
  type: PropTypes.string,
};

const CodeLinkWithIcon = ({
  orderTotals,
  hasError = false,
  onClick,
  appliedText = "",
  ctaText = "Try another code",
  type = null,
}) => {
  const codeLinkIcon = () => {
    if (hasError) return null;

    return (
      <div className="code-link-icon" data-test-id="code-link-icon">
        <DollarBill />
      </div>
    );
  };

  const codeLinkText = () => {
    const appliedTextClass = `applied-text ${hasError ? "error" : ""}`;

    if (hasError) {
      return (
        <>
          <span className={appliedTextClass}>{appliedText} </span>
          <AtomHighlightTextButton onClick={onClick} buttonText={ctaText} />
        </>
      );
    }

    const charge = findAmountForType(orderTotals, type);
    return (
      <>
        <div className="code-link-text">
          <p className="promo-charge">You save {formatSavingsAsPositive(charge)}</p>
          <p className="promo-code-applied">{appliedText}</p>
        </div>
        <AtomSecondaryButton className="promo-code-button" buttonText={ctaText} onClick={onClick} />
      </>
    );
  };

  return (
    <div className="promo-link" data-test-id="code-link-with-icon">
      {codeLinkIcon()}
      {codeLinkText()}
    </div>
  );
};

export default CodeLinkWithIcon;

CodeLinkWithIcon.propTypes = propTypes;
