import React from "react";
import PropTypes from "prop-types";
import BagHeader from "components/source/checkout/bag/bag-header";
import { useSelector, useDispatch } from "react-redux";
import Routes from "routes";
import MembershipHelpers from "helpers/membership-helpers";
import ActionLogger from "action-logger";
import ProductImage from "components/source/shared/product-image";
import { kifah } from "actions/ce-bag-actions";
import { bagTabs, switchBagTab } from "actions/bag-actions";
import { setKifahTrigger } from "actions/kifah-actions";
import ceCheckoutActions from "actions/ce-checkout-actions";
import { analytics } from "rtr-constants";
import HeapHelpers from "helpers/heap-helpers";

import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  ProductImageOrientation,
  ProductImageSize,
} from "helpers/product-image-helpers";
import ProductImagePath from "helpers/product-image-path";
import { usdPriceIntoInt } from "helpers/PricingHelper";

import BuyNowComponent from "components/source/membership/homepage/item_card/footer/ctas/buyNow";
import styles from "./sm-kifah-select-styles.module.scss";

const fallBackThumbnailImgSrc = item =>
  item?.images?.[ProductImageOrientation.NO_MODEL.path]
    ? ProductImagePath + item.images[ProductImageOrientation.NO_MODEL.path]?.[ProductImageSize.x480.path]
    : ProductImagePath + item?.images?.[ProductImageOrientation.FRONT.path]?.[ProductImageSize.x480.path];

const buyNowCopy = (item = {}) => {
  if (item.wasPurchased) {
    return (
      <div className={styles["content"]}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 5.75C8.55228 5.75 9 5.30229 9 4.75C9 4.19772 8.55229 3.75 8 3.75C7.44772 3.75 7 4.19771 7 4.75C7 5.30229 7.44771 5.75 8 5.75Z"
            fill="black"
          />
          <path
            d="M8.75 7.32843C8.75 6.91421 8.41421 6.57843 8 6.57843C7.58579 6.57843 7.25 6.91421 7.25 7.32843L7.25 11.8284C7.25 12.2426 7.58579 12.5784 8 12.5784C8.41421 12.5784 8.75 12.2426 8.75 11.8284L8.75 7.32843Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8Z"
            fill="black"
          />
        </svg>
        <span>Add a new item to fill this shipment</span>
      </div>
    );
  }
  return (
    <>
      Buy for ${usdPriceIntoInt(item.purchasePrice)}{" "}
      <span className={styles["strike-copy"]}>${usdPriceIntoInt(item.retailPrice)}</span>
    </>
  );
};

export function SmKifahSelect(props) {
  const dispatch = useDispatch();
  const kifahItems = useSelector(state => state.kifahAvailableItems || []).sort((a, b) =>
    a.wasPurchased ? -1 : b.wasPurchased ? 1 : 0
  );
  const kifahTrigger = useSelector(state => state.kifahTrigger);
  const smBag = useSelector(state => state.smBag) || {};
  const browser = useSelector(state => state.browser) || {};
  const userData = useSelector(state => state.userData) || {};
  const membershipState = useSelector(state => state.membershipState) || {};
  const percentOffBgColor = useSelector(state => state.cmsContent?.smBagPromoBanner?.config?.backgroundColor);

  const smBagCheckout = smBag?.checkout;
  const nonPurchasedKifahItems = kifahItems.filter(item => !item.wasPurchased);
  const userHasPurchasedItems = kifahItems.some(item => item.wasPurchased);

  React.useEffect(() => {
    const heapEventData = {
      number_of_items: nonPurchasedKifahItems.length,
      item_skus: JSON.stringify(nonPurchasedKifahItems.map(item => item.sku)),
      trigger_type: kifahTrigger,
    };
    HeapHelpers.fireHeapEvent("sf_view_kifah_trigger", heapEventData);
  }, []);

  const initializePurchase = item => {
    const heapEventData = {
      item_sku: item.sku,
      trigger_type: item.kifahTrigger,
    };
    HeapHelpers.fireHeapEvent("sf_bag_click_buy_kifah_trigger", heapEventData);
    dispatch(kifah(item.sku, item.orderId, item.bookingId));
    dispatch(setKifahTrigger(item.kifahTrigger));
  };

  const handleCTA = () => {
    const heapEventData = {
      number_of_items: nonPurchasedKifahItems.length,
      item_skus: JSON.stringify(nonPurchasedKifahItems.map(item => item.sku)),
      trigger_type: kifahTrigger,
    };
    HeapHelpers.fireHeapEvent("sf_bag_click_no_thanks_kifah_trigger", heapEventData);
    dispatch(setKifahTrigger(null));
    if (userHasPurchasedItems) {
      dispatch(switchBagTab(bagTabs.MEMBERSHIP_TAB));
    } else {
      const getReturnDetails = () => {
        const homePickupEligibilityAction = () => {
          ActionLogger.logAction({
            object_type: analytics.OBJECT_TYPE.HOME_PICKUP_SCHEDULER,
            action: analytics.ACTION_TYPE.HOME_PICKUP_VISIBLE,
            user_id: membershipState?.id,
            membership_id: membershipState?.membershipId,
            zipcode: membershipState?.address?.postalCode,
            orderId: MembershipHelpers.getMostRecentOrderId(membershipState),
          });
        };

        return {
          returningItems: smBag?.items?.returning || [],
          rentalBeginDate: smBagCheckout?.estimatedArrivalDate,
          actionLogger: homePickupEligibilityAction,
        };
      };

      const ceCheckout = {
        ...smBagCheckout,
        userId: smBag?.id,
        bagItems: smBag?.items?.arriving || [],
      };

      dispatch(ceCheckoutActions.placeOrder(ceCheckout, getReturnDetails(), true));
    }
  };

  const renderPercentOff = item => {
    if (item?.percentOff > 0) {
      return (
        <div className={styles["percent-off"]} style={{ backgroundColor: percentOffBgColor }}>
          {item?.wasPurchased ? "PURCHASED" : `-${item?.percentOff}%`}
        </div>
      );
    }
  };

  return (
    <div className={styles["kifah-select-screen"]} data-heap-id="kifah-select-screen">
      <BagHeader
        bagTab={bagTabs.KIFAH_SELECT_TAB}
        homePickupSchedulerIsVisible={false}
        isMobileViewport={browser?.isMobileViewport}
        isToggledToReserveContents={false}
        membershipState={membershipState}
        onCloseBag={props.handleCloseBag}
        userData={userData}
      />
      <div
        className={`${styles["kifah-select-item-container"]}${
          kifahItems.length === 1 ? ` ${styles["centered"]}` : ""
        }`}>
        {kifahItems.map(item => (
          <div className={styles["kifah-select-item-card"]} key={`${item.bookingId}-item-card`}>
            <a className={styles["thumbnail-container"]} href={Routes.PDP.RouteById(item?.styleName)}>
              <ProductImage
                className={styles["thumbnail"]}
                altText={getProductImageAltText(item.displayName, item.designerName)}
                imageGroup={getProductImageUrlsByOrientation(item.images, [ProductImageOrientation.NO_MODEL.path])}
                imageSize={ProductImageSize.x480}
                fallbackSrc={fallBackThumbnailImgSrc(item)}
              />
              {renderPercentOff(item)}
            </a>
            <div className={styles["details-container"]}>
              <p className="universal-xsmall--bold">{item.designerName}</p>
              <p className="universal-xsmall">
                {item.wasPurchased ? "🎉 This purchase opened a free spot" : item.kifahReason}
              </p>
            </div>
            {item.wasPurchased ? (
              <div className={`${styles[`cta`]} ${styles[`sold`]}`}>{buyNowCopy(item)}</div>
            ) : (
              <BuyNowComponent
                bookingId={item.bookingId}
                className={`${styles[`cta`]} ${styles[`sale`]}`}
                givenCopy={buyNowCopy(item)}
                initializePurchase={() => initializePurchase(item)}
                isBag={true}
                purchasePrice={item.purchasePrice}
                showPrice={true}
              />
            )}
          </div>
        ))}
      </div>
      <div className={styles["kifah-select-cta-container"]}>
        {userHasPurchasedItems ? (
          <button
            className={styles["kifah-select-cta-button"]}
            onClick={handleCTA}
            data-heap-id="skip-kifah-select-screen">
            Add Items and Checkout
          </button>
        ) : (
          <>
            <p>
              Buy now and use{" "}
              {nonPurchasedKifahItems?.length > 1 ? "these spots to rent new items" : "this spot to rent a new item"}
            </p>
            <button
              className={styles["kifah-select-cta-button"]}
              onClick={handleCTA}
              data-heap-id="skip-kifah-select-screen">
              No, Continue to Checkout
            </button>
          </>
        )}
      </div>
    </div>
  );
}

const propTypes = {
  handleCloseBag: PropTypes.func,
};

SmKifahSelect.propTypes = propTypes;

export default SmKifahSelect;
