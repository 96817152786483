import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ActionLogger from "action-logger";
import MembershipHelpers from "helpers/membership-helpers";
import { analytics, HomePickupOrderType, HOME_PICKUP_NEW_TAG_OTE } from "rtr-constants";
import HomePickupActions from "actions/home-pickup-actions";
import * as NotificationCenterActions from "actions/subscription-notification-center-actions";
import HomePickupScheduler from "components/source/home_pickup/scheduler/home-pickup-scheduler";
import OneTimeExperienceActions from "actions/one-time-experience-actions";

export const HomePickupCrossShipContainer = () => {
  const [showHomePickupError, setShowHomePickupError] = React.useState(false);

  const dispatch = useDispatch();
  const membershipState = useSelector(state => state.membershipState);
  const userData = useSelector(state => state.userData);
  const homePickupEligibility = useSelector(state => state.homePickup?.homePickupEligibility);
  const wasNewTagRemoved = useSelector(state => state.oneTimeExperiences?.[HOME_PICKUP_NEW_TAG_OTE]);

  const initialPhoneNumber = userData?.userProfile?.cellPhoneNumber
    ? userData.userProfile.cellPhoneNumber
    : membershipState?.address?.phone;

  React.useEffect(() => {
    dispatch(OneTimeExperienceActions.getIsOneTimeExperienceDismissed(HOME_PICKUP_NEW_TAG_OTE));
    return () => {
      if (!wasNewTagRemoved) {
        dispatch(OneTimeExperienceActions.dismissOneTimeExperience(HOME_PICKUP_NEW_TAG_OTE));
      }
    };
  }, []);

  const scheduleHomePickupHandler = (bookingIds, phoneNumber, pickupMethod, pickupType, specialInstructions) => {
    const orderId = MembershipHelpers.getMostRecentOrderId(membershipState);
    const pickupAddress = membershipState?.address;
    const email = userData?.userProfile?.email;

    const logData = {
      object_type: analytics.OBJECT_TYPE.HOME_PICKUP_SCHEDULER,
      action: analytics.ACTION_TYPE.SCHEDULE_HOME_PICKUP_CLICK,
      user_id: membershipState?.id,
      membership_id: membershipState?.membershipId,
      order_id: orderId,
      zipcode: membershipState?.address?.postalCode,
    };
    ActionLogger.logAction(logData);

    const errorCallback = () => {
      setShowHomePickupError(true);

      const errorLogData = {
        object_type: analytics.OBJECT_TYPE.HOME_PICKUP_SCHEDULER,
        action: analytics.ACTION_TYPE.SCHEDULE_HOME_PICKUP_ERROR,
        user_id: membershipState?.id,
        membership_id: membershipState?.membershipId,
        order_id: orderId,
        zipcode: membershipState?.address?.postalCode,
      };
      ActionLogger.logAction(errorLogData);
    };

    dispatch(
      HomePickupActions.scheduleHomePickup(
        bookingIds,
        email,
        errorCallback,
        homePickupEligibility,
        orderId,
        null, // membership home pickups don't include orderGroupIds
        phoneNumber,
        pickupAddress,
        pickupMethod,
        HomePickupOrderType.CROSS_SHIP,
        pickupType,
        specialInstructions
      )
    );
  };

  const errorHandler = () => {
    dispatch(HomePickupActions.showPostHomePickupModals(HomePickupOrderType.CROSS_SHIP));
  };

  const dismissHomePickupCTA = () => {
    const orderId = MembershipHelpers.getMostRecentOrderId(membershipState);
    const logData = {
      object_type: analytics.OBJECT_TYPE.HOME_PICKUP_SCHEDULER,
      action: analytics.ACTION_TYPE.NO_THANKS_HOME_PICKUP_CLICK,
      user_id: membershipState?.id,
      membership_id: membershipState?.membershipId,
      order_id: orderId,
      zipcode: membershipState?.address?.postalCode,
    };
    ActionLogger.logAction(logData);
    dispatch(HomePickupActions.displayHomePickup(false));
    dispatch(HomePickupActions.showPostHomePickupModals(HomePickupOrderType.CROSS_SHIP));
    dispatch(NotificationCenterActions.fetchNotification());
  };

  return (
    <HomePickupScheduler
      errorHandler={errorHandler}
      dismissHomePickupCTA={dismissHomePickupCTA}
      initialPhoneDigitsOnly={initialPhoneNumber}
      scheduleHomePickup={scheduleHomePickupHandler}
      showHomePickupError={showHomePickupError}
    />
  );
};

export default HomePickupCrossShipContainer;
