import React from "react";
import PropTypes from "prop-types";

class CheckoutButton extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    isPlanUpgrading: PropTypes.bool,
    isMembershipSwapFlow: PropTypes.bool,
  };

  handleClick = () => {
    this.props.onClick();
  };

  buttonCopy = () => {
    if (this.props.isMembershipSwapFlow) {
      return "Confirm";
    } else if (this.props.isPlanUpgrading) {
      return "Confirm Shipment and New Plan";
    }

    return "Confirm Shipment";
  };

  render() {
    return (
      <div className="checkout-button">
        <button disabled={this.props.disabled} className="btn btn-primary" onClick={this.handleClick}>
          {this.buttonCopy()}
        </button>
      </div>
    );
  }
}

export default CheckoutButton;
