import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";
import { flagsAndExperimentNames } from "rtr-constants";

/**
 * Component that renders the Rokt placeholder where Rokt placements will go.
 *
 * if the `noMarkup` prop is passed as `true` the component won't render HTML but will still
 * fire placements - this is the current operation for the order complete page where the Bag
 * is already rendering a Rokt placement, and without this an ad would be displayed twice.
 */
const RoktPlacement = ({ noMarkup = false }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames?.SF_ROKT));
  }, []);

  return noMarkup ? <></> : <div id="rokt-placeholder" data-test-id="rokt-placeholder"></div>;
};

RoktPlacement.propTypes = {
  noMarkup: PropTypes.bool,
};

export default RoktPlacement;
