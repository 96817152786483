import React from "react";
import PropTypes from "prop-types";
import AtomHighlightTextButton from "components/source/atoms/atom-highlight-text-button";

const propTypes = {
  onClick: PropTypes.func.isRequired,
  appliedText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  additionalClassName: PropTypes.string,
  hasError: PropTypes.bool,
};

const defaultProps = {
  ctaText: "Try another code",
  appliedText: "",
  additionalClassName: "",
  hasError: false,
};

export default class CodeLink extends React.Component {
  constructor(props) {
    super(props);
    this.baseClassName = "code-link";
  }

  className() {
    return `${this.baseClassName} ${this.props.additionalClassName}`;
  }

  appliedText() {
    const { appliedText, hasError } = this.props;
    const appliedTextClass = `applied-text ${hasError ? "error" : ""}`;

    if (appliedText) {
      return <span className={appliedTextClass}>{appliedText}</span>;
    }

    return null;
  }

  render() {
    const { ctaText, onClick } = this.props;
    return (
      <div className={this.className()}>
        {this.appliedText()}
        <AtomHighlightTextButton onClick={onClick} buttonText={ctaText} />
      </div>
    );
  }
}

CodeLink.propTypes = propTypes;
CodeLink.defaultProps = defaultProps;
