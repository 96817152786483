import { assetBaseUrls } from "rtr-constants";
import AtomButton from "components/source/atoms/atom-button";
import { Grids } from "routes";
import { navigateTo } from "../../../../../../helpers/location-helpers";
import tryCatchLog from "../../../../../../helpers/try-catch";
import RtrImage from "../../../../shared/rtr-image";

const EmptyBagScreen = () => {
  const navigateToProducts = () => tryCatchLog(() => navigateTo(Grids.Clothing));
  return (
    <div className="empty-bag-contents">
      <RtrImage alt="garment-bag" src={`${assetBaseUrls.DESIGNER_IMAGES}/RTR_GarmentBag_06012023.svg`} />
      <h2>Your bag is empty</h2>
      <p className="universal-medium">Select styles to build your shipment.</p>

      <AtomButton onClick={navigateToProducts} buttonText="Browse Now" />
    </div>
  );
};

export default EmptyBagScreen;
