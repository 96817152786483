import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { flagsAndExperimentNames } from "rtr-constants";
import ProductImage from "components/source/shared/product-image";
import ProductImagePath from "helpers/product-image-path";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  ProductImageOrientation,
  ProductImageSize,
} from "helpers/product-image-helpers";
import SectionHeader from "../components/sm-bag-section-header";
import SimplifyMembershipBagActions from "actions/sm-bag-actions";
import { formatDateMonthDayCondensed } from "helpers/date-helpers";

const propTypes = {
  removeBottomBorder: PropTypes.bool,
  returningItemsRef: PropTypes.object,
  scrollToKeeping: PropTypes.func,
};

function SMBagReturningItems({ removeBottomBorder, scrollToKeeping = () => {}, returningItemsRef }) {
  const dispatch = useDispatch();
  const returnByDate = useSelector(state => state.membershipBag?.crossShipReturnByDateForToday) || "";
  const itemsToReturnRedux = useSelector(state => state.smBag?.items?.returning) || [];

  const [itemsToReturnLocal, setItemsToReturnLocal] = React.useState(itemsToReturnRedux);

  React.useEffect(() => {
    setItemsToReturnLocal(oldItems => {
      if (itemsToReturnRedux?.length < oldItems?.length) {
        scrollToKeeping();
      }

      return itemsToReturnRedux;
    });
  }, [itemsToReturnRedux]);

  const isInKifahExperiment = useSelector(
    state => state.flagsAndExperiments?.[flagsAndExperimentNames.SWAT_SF_SWAT_591_KIFAH_IMPROVEMENTS] === "ENABLED"
  );

  const fallBackThumbnailImgSrc = gridItem =>
    gridItem?.images?.[ProductImageOrientation.NO_MODEL.path]
      ? ProductImagePath + gridItem.images[ProductImageOrientation.NO_MODEL.path]?.[ProductImageSize.x270.path]
      : ProductImagePath + gridItem?.images?.[ProductImageOrientation.FRONT.path]?.[ProductImageSize.x270.path];

  const handleKeep = item => {
    dispatch(SimplifyMembershipBagActions.setKeepPromise(item.bookingId));
  };

  return (
    <div
      className={`section-container returning-items${removeBottomBorder ? " remove-border" : ""}`}
      ref={returningItemsRef}>
      <SectionHeader
        headerText={
          itemsToReturnLocal.length > 0
            ? `Send ${itemsToReturnLocal.length > 1 ? "these" : "this"} back by ${formatDateMonthDayCondensed(
                returnByDate
              )}`
            : "Returning"
        }
        subHeaderText={
          itemsToReturnLocal.length > 0
            ? `Want to hold onto ${itemsToReturnLocal.length > 1 ? "an" : "this"} item? Tap to keep it longer.`
            : ""
        }
      />
      <div className="returning-items-grid">
        {itemsToReturnLocal?.length > 0 ? (
          itemsToReturnLocal.map(item => (
            <div
              key={item.bookingId}
              className={`returning-items-grid-item-container${isInKifahExperiment ? " button-separate" : ""}`}>
              <ProductImage
                className={`thumbnail${isInKifahExperiment ? " button-separate" : ""}`}
                altText={getProductImageAltText(item.displayName, item.designerName)}
                imageGroup={getProductImageUrlsByOrientation(item.images, [ProductImageOrientation.NO_MODEL.path])}
                imageSize={ProductImageSize.x270}
                fallbackSrc={fallBackThumbnailImgSrc(item)}
              />
              <button
                className={`keep-item${isInKifahExperiment ? " button-separate" : ""}`}
                onClick={() => handleKeep(item)}>
                <span className="universal-small--semibold">Keep</span>
              </button>
            </div>
          ))
        ) : (
          <span className="universal-medium empty-text">Items you swap will appear here</span>
        )}
      </div>
    </div>
  );
}

SMBagReturningItems.propTypes = propTypes;

export default SMBagReturningItems;
