import { Annotations } from "rtr-constants";
import { formatSavingsAsPositive } from "helpers/invoice-helper";

const backupStyleReason = "Backup Size";
const reducedSecondStyleReason = "Reduced Second Style";
const promoType = "PROMO";

const CEBagHelpers = {
  hasMembersItems: bag => !!bag?.members?.bagItems?.length,
  hasMixedItems: bag => !!bag?.mixed?.bagGroups?.length,
  hasReserveItems: bag => {
    let result = false;

    if (bag?.mixed) {
      result = bag.mixed.bagGroups.some(bagGroup => {
        return bagGroup.bagItems.some(bagItem => bagItem.type === "ReserveBagItem");
      });
    }

    return result;
  },

  numberOfMembersItems: bag => bag?.members?.bagItems?.length || 0,

  isPricingBackupStyle: pricing => !!pricing?.adjustments.find(adj => adj.reason === backupStyleReason),

  pricingAnnotation: pricing => {
    const promoSavings = pricing?.adjustments.find(adj => adj.type === promoType);
    const secondStyleAmount = pricing?.adjustments.find(adj => adj.reason === reducedSecondStyleReason);

    if (secondStyleAmount && promoSavings) {
      return Annotations.secondStyleAndPromo(formatSavingsAsPositive(promoSavings.amount));
    }

    if (promoSavings) {
      return Annotations.promoItemSavings(formatSavingsAsPositive(promoSavings.amount));
    }

    if (secondStyleAmount) {
      return Annotations.secondStyleDiscount;
    }

    if (isPricingBackupStyle(pricing)) {
      return Annotations.backupStyleDiscount;
    }

    return "";
  },

  getMembershipCopy: membershipTier => {
    const result = { title: "RTR Membership", swapCopy: null };

    if (membershipTier) {
      result.title = `${membershipTier.monthlyShipmentLimit * membershipTier.slotCount} items/month`;
    }

    if (membershipTier?.monthlyShipmentLimit === 1) {
      result.swapCopy = "1 shipment";
    } else if (membershipTier?.monthlyShipmentLimit > 1) {
      result.swapCopy = `${membershipTier?.monthlyShipmentLimit} shipments, ${membershipTier.slotCount} item${
        membershipTier.slotCount > 1 ? "s" : ""
      } at a time`;
    }

    return result;
  },

  mixedBagStyles: bag => {
    return bag?.mixed?.bagGroups?.map(group => group.bagItems.map(item => item.styleName)).flat() || [];
  },
};

export default CEBagHelpers;

export const {
  getMembershipCopy,
  hasMembersItems,
  hasMixedItems,
  hasReserveItems,
  isPricingBackupStyle,
  mixedBagStyles,
  numberOfMembersItems,
  pricingAnnotation,
} = CEBagHelpers;
