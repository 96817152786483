import React from "react";
import PropTypes from "prop-types";
import AtomAnimatedTextInput from "components/source/atoms/atom-animated-text-input";
import AtomSecondaryButton from "components/source/atoms/atom-secondary-button";

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  additionalClassName: PropTypes.string,
};

const defaultProps = {
  additionalClassName: "",
};

export default class AnimatedInlineButtonInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      value: "",
    };
  }

  className() {
    return `${this.props.baseClassName} ${this.props.additionalClassName}`;
  }

  handleInputChange(e) {
    const value = e.target.value;

    // The input atom needs an isValid prop of true for the label to stay up
    // after the user has entered a value and is no longer focused on the input.
    // Setting isValid to !!(value) assures that any value will do that for us.
    this.setState({
      value,
      isValid: !!value,
    });
  }

  handleSubmit() {
    this.props.onSubmit(this.state.value);
  }

  render() {
    const { name, label, ctaText } = this.props;
    return (
      <div className={this.className()}>
        <AtomAnimatedTextInput
          id={name}
          name={name}
          type="text"
          label={label}
          value={this.state.value}
          isValid={this.state.isValid}
          onChange={this.handleInputChange}
          onBlur={this.handleInputChange}
        />
        <AtomSecondaryButton buttonText={ctaText} onClick={this.handleSubmit} />
      </div>
    );
  }
}

AnimatedInlineButtonInput.propTypes = propTypes;
AnimatedInlineButtonInput.defaultProps = defaultProps;
