import React from "react";
import PropTypes from "prop-types";
import CodeLink from "components/source/checkout/code-link";
import AnimatedInlineButtonInput from "components/source/shared/animated_inputs/animated-inline-button-input";
import { getLocalParam } from "../navigation/url-param-reader";
import { paidPromotions, EVENTS_CUSTOM_CASTLE } from "rtr-constants";
import * as castleHelper from "helpers/castle-helper";
import CodeLinkWithIcon from "./code-link-with-icon";
import { chargeOrTotalArrayPropType } from "components/propTypes";

export const displayStates = {
  ADD_FORM: "ADD_FORM",
  APPLIED: "APPLIED", // state when a code has been applied successfully OR unsuccessfully
  INITIAL: "INITIAL",
};

const propTypes = {
  isSubmitting: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  appliedCode: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  onSubmitCode: PropTypes.func.isRequired,
  onRemoveCode: PropTypes.func.isRequired,
  error: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string,
  }),
  addFormText: PropTypes.shape({
    label: PropTypes.string,
    cta: PropTypes.string,
    submitting: PropTypes.string,
  }),
  linkText: PropTypes.shape({
    base: PropTypes.string,
    afterSuccess: PropTypes.string,
    afterError: PropTypes.string,
    submittingAfterError: PropTypes.string,
    submittingAfterSuccess: PropTypes.string,
  }),
  additionalClassName: PropTypes.string,
  orderTotals: chargeOrTotalArrayPropType,
};

let isAutoPopulatePromoCode = true;

// `type` defines what type of component this is, either a promo code or a gift card component.
// The two values for `type` are either:
// "giftCard" or "promoCode"
// From type, we derive defaultProps and base className.

function GenericCodeInput(type) {
  const defaultProps = {
    appliedCode: "",
    error: {
      hasError: false,
      message: "",
    },
    addFormText: {
      cta: "Apply",
      submitting: "Applying…",
    },
    additionalClassName: "",
  };

  let baseClassName, castleEventName;
  if (type === "giftCard") {
    baseClassName = "gift-card";
    castleEventName = EVENTS_CUSTOM_CASTLE.APPLY_GIFT_CARD;
    defaultProps.linkText = {
      base: "Add a gift card",
      afterSuccess: "Change gift card?",
      afterError: "Try again",
      submittingAfterError: "Reloading…",
      submittingAfterSuccess: "Removing gift card…",
    };

    defaultProps.addFormText.label = "Gift Card";
  } else if (type === "promoCode") {
    baseClassName = "promo-code";
    castleEventName = EVENTS_CUSTOM_CASTLE.APPLY_PROMO_CODE;
    defaultProps.linkText = {
      base: "Have a promo code?",
      afterSuccess: "Change promo",
      afterError: "Try again",
      submittingAfterError: "Reloading…",
      submittingAfterSuccess: "Removing code…",
    };

    defaultProps.addFormText.label = "Promo Code";
  }

  class GenericCodeInput extends React.Component {
    constructor(props) {
      super(props);
      this.showAddForm = this.showAddForm.bind(this);
      this.submitCode = this.submitCode.bind(this);
      this.baseClassName = baseClassName;

      this.state = {
        display: this.initialDisplayState(),
      };
    }

    initialDisplayState() {
      if (this.props.defaultOpen) {
        return displayStates.ADD_FORM;
      } else {
        return displayStates.INITIAL;
      }
    }

    className() {
      // additionalClassName is "" by default so we can simply call this
      // without risk of returning "undefined" at the end of the string
      return `${this.baseClassName} ${this.props.additionalClassName}`;
    }

    hasAppliedCode() {
      const { appliedCode, error } = this.props;

      if (appliedCode === null) {
        return false;
      }

      // A function that determines if the user has applied (or tried to apply) a code.
      // Returns true if a code is present OR if the code is an empty string and there is an error.
      // (A user can submit "" as a code, which will cause a 400 in Ruby.)
      const emptyStringCodeWithError = appliedCode.length === 0 && error.hasError;
      return !!(appliedCode || emptyStringCodeWithError);
    }

    showAddForm() {
      // Before showing the add form, we always delete the currently applied code from the order (if there is one)
      this.setState({
        display: displayStates.ADD_FORM,
      });

      if (this.hasAppliedCode()) {
        // There is a code to be removed - call props.onRemoveCode to do this.
        // This function needs to dispatch an action that removes the code and clears the error (if any)
        this.props.onRemoveCode(this.props.appliedCode, this.props.orderId);
      }
    }

    submitCode(code) {
      this.setState({
        display: displayStates.APPLIED,
      });

      castleHelper.logCustomEvent({ name: castleEventName, properties: { order_id: this.props.orderId } });

      // Callback for an additional action, e.g., posting the submitted code to Ruby / GM
      this.props.onSubmitCode(code, this.props.orderId);
    }

    addFormCtaText(isSubmitting = false) {
      const { submitting, cta } = this.props.addFormText;
      return isSubmitting ? submitting : cta;
    }

    codeAppliedText(appliedCode, error) {
      if (error.hasError) {
        return error.message || `${appliedCode} is invalid.`;
      } else if (appliedCode) {
        return `${appliedCode} applied`;
      }
    }

    codeLinkCtaText(appliedCode, error, isSubmitting = false) {
      const { afterSuccess, afterError, submittingAfterError, submittingAfterSuccess } = this.props.linkText;
      if (error.hasError) {
        return isSubmitting ? submittingAfterError : afterError;
      } else if (appliedCode) {
        return isSubmitting ? submittingAfterSuccess : afterSuccess;
      }
    }
    autoFillPromo() {
      const paidPromo = getLocalParam(
        paidPromotions.URL_PARAMETER_PAID_PROMOTION,
        paidPromotions.PAID_STORAGE_PARAM_NAME
      );
      if (!paidPromo) {
        return false;
      }
      if (paidPromo && isAutoPopulatePromoCode) {
        this.props.onSubmitCode(paidPromo, this.props.orderId);
        isAutoPopulatePromoCode = false;
        return true;
      }
    }

    content() {
      const { addFormText, error, appliedCode, isSubmitting } = this.props;

      if (this.autoFillPromo() && type === "promoCode") {
        //This will only auto populate the promo code if the paid promotions skinny-banner is displayed
        return (
          <AnimatedInlineButtonInput
            ctaText={this.addFormCtaText(isSubmitting)}
            label={addFormText.label}
            onSubmit={this.submitCode}
            name={type}
            baseClassName={`${this.baseClassName}-add`}
          />
        );
      }

      if (this.hasAppliedCode()) {
        const codeType = type === "promoCode" ? "promo" : type;
        // There is an applied code, so we should be in the link state.
        return (
          <CodeLinkWithIcon
            onClick={this.showAddForm}
            appliedText={this.codeAppliedText(appliedCode, error)}
            ctaText={this.codeLinkCtaText(appliedCode, error, isSubmitting)}
            hasError={error.hasError}
            orderTotals={this.props.orderTotals}
            type={codeType}
          />
        );
      } else if (!appliedCode && this.state.display !== displayStates.INITIAL) {
        // No code has been applied and we are not in initial state, so we should show the add form.
        return (
          <AnimatedInlineButtonInput
            ctaText={this.addFormCtaText(isSubmitting)}
            label={addFormText.label}
            onSubmit={this.submitCode}
            name={type}
            baseClassName={`${this.baseClassName}-add`}
          />
        );
      } else if (this.state.display === displayStates.INITIAL) {
        return <CodeLink onClick={this.showAddForm} ctaText={this.props.linkText.base} />;
      }
    }

    render() {
      return (
        <div className={this.className()} data-test-id={this.baseClassName}>
          {this.content()}
        </div>
      );
    }
  }
  GenericCodeInput.displayName = `GenericCodeInput(${type})`;
  GenericCodeInput.propTypes = propTypes;
  GenericCodeInput.defaultProps = defaultProps;
  return GenericCodeInput;
}

const PromoCode = GenericCodeInput("promoCode");
const GiftCard = GenericCodeInput("giftCard");

export { PromoCode, GiftCard };
