import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ApplePayButton from "components/source/common/apple-pay-button/ApplePayButton";
import { applePayButtonPropType } from "components/propTypes/apple-pay-button-prop-type";
// eslint-disable-next-line no-restricted-imports
import Script from "next/script";

import { ApplePayConstants, CECheckoutTypes, getAppleDeviceType, iframeActions, iframeRemoteKeys } from "rtr-constants";

import { requestApplePaySession } from "actions/apple-pay-actions";
import { getApplePayMerchantIdentifier } from "helpers/checkout-helpers";

import { goToOrderConfirmationPage } from "actions/ce-standard-checkout-actions";

import {
  formatRequestObject,
  constructApplePayError,
  createValidationDataObject,
} from "helpers/apple-pay-payment-helpers";

import HeapHelpers from "helpers/heap-helpers";
import GTMHelpers from "helpers/gtm";
import Converge from "helpers/converge";
import ActionLogger from "action-logger";

import { getPurchaseDetails, applePayEventData } from "helpers/heap-ce-helpers";
import { createCheckoutPixel } from "helpers/checkout-pixel-helper";
const propTypes = {
  checkout: PropTypes.object.isRequired,
  bag: PropTypes.object,
  applePayStyle: applePayButtonPropType,
  onSuccessfulPayment: PropTypes.func,
};

/**
 * Wrapper for non-checkout placements of the Apple Pay Button
 * Can take different checkout states e.g. ceCheckout or ceCheckoutKifah
 * Can handle different order types: KIFAH, Spot upgrades
 * do not require a shipping address
 */
const StandaloneApplePayWrapper = ({ checkout, bag, applePayStyle = {}, onSuccessfulPayment }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state?.userData?.userProfile);
  const secureApplePayEntryRef = useRef(null);
  const checkoutRef = useRef(checkout);

  useEffect(() => {
    checkoutRef.current = checkout;
  }, [checkout]);

  useEffect(() => {
    if (window.iframeMessenger && secureApplePayEntryRef.current?.contentWindow) {
      window.iframeMessenger.registerRemote(
        iframeRemoteKeys.SECURE_APPLE_PAY,
        secureApplePayEntryRef.current.contentWindow
      );
    }
  }, [secureApplePayEntryRef.current]);

  const performValidation = (url, merchantIdentifier, displayName, domain) => {
    const data = createValidationDataObject(url, merchantIdentifier, displayName, domain);
    return dispatch(requestApplePaySession(data, true));
  };

  const getOnValidateMerchant = session => {
    return event => {
      const merchantIdentifier = getApplePayMerchantIdentifier();
      performValidation(
        event.validationURL,
        merchantIdentifier,
        ApplePayConstants.merchantDisplay,
        window.location.hostname
      )
        .then(response => {
          session.completeMerchantValidation(response);
        })
        .catch(() => {
          session.abort();
        });
    };
  };

  const performApplePayDebit = payment => {
    const { billingContact, shippingContact, token } = payment;
    const { paymentData } = token;

    return new Promise(function (resolve, reject) {
      if (billingContact.countryCode !== ApplePayConstants.validCountryCode) {
        return reject(ApplePayConstants.APPLE_PAY_ERRORS.BILLING_CONTACT_INVALID);
      }

      // add handlers for success/fail. These events will be called in eprotect-helper.js
      window.iframeMessenger.addHandler(
        iframeRemoteKeys.PARENT,
        iframeActions.ON_SAVE_APPLE_PAY_PAYMENT_METHOD_SUCCESS,
        (action, payload) => {
          return resolve(payload);
        }
      );
      window.iframeMessenger.addHandler(
        iframeRemoteKeys.PARENT,
        iframeActions.ON_SAVE_APPLE_PAY_PAYMENT_METHOD_FAILURE,
        (action, payload) => {
          return reject(payload);
        }
      );

      const orderId = checkoutRef.current.id.substring(0, 24);
      const setRecurring = checkoutRef.current?.checkoutType === "MEMBERS";
      window.iframeMessenger.send(
        iframeRemoteKeys.SECURE_APPLE_PAY,
        iframeActions.SAVE_APPLE_PAY_PAYMENT_TOKEN_USING_EPROTECT,
        {
          orderId,
          id: `${orderId}`,
          PKPaymentToken: paymentData,
          paymentMethodUrl: `/api/checkout/${checkoutRef.current.id}/paymentMethod/applepay`,
          billingPayloadValues: {
            phone: shippingContact?.phoneNumber ?? user?.cellPhoneNumber,
            city: billingContact.locality,
            firstName: billingContact.givenName,
            lastName: billingContact.familyName,
            postalCode: billingContact.postalCode,
            street1: billingContact.addressLines[0],
            street2: billingContact.addressLines[1] || "",
            zoneCode: billingContact.administrativeArea,
            userId: checkoutRef.current.userId,
            default: false,
            recurring: setRecurring,
          },
        }
      );
    });
  };

  /**
   * Function that fires when the user authorizes the Apple Pay payment
   * (so, once the user successfully authenticates with TouchID / FaceID)
   *
   * @param {*} resolve
   * @param {*} reject
   * @param {*} session
   * @returns
   */
  const getOnPaymentAuthorized = (resolve, reject, session) => {
    return event => {
      const purchase = getPurchaseDetails(checkoutRef.current);
      const heapData = applePayEventData(checkoutRef.current);
      const heapEventData = {
        deviceType: getAppleDeviceType(),
        checkout: heapData.id,
        promoCode: heapData.promoCode,
        purchaseType: purchase.purchaseType,
        purchaseTypeDetail: purchase.purchaseTypeDetail,
        total: heapData.grandTotal,
        apple_pay: true,
      };

      HeapHelpers.fireHeapEvent("apple_pay_checkout_initialized", heapEventData);
      return performApplePayDebit(event.payment)
        .then(res => {
          session.completePayment({
            status: window.ApplePaySession.STATUS_SUCCESS,
          });
          HeapHelpers.fireHeapEvent("apple_pay_checkout_successful", heapEventData);

          HeapHelpers.fireHeapEvent("order", {
            ...heapEventData,
            order_id: res.id,
            products: heapData.productIds,
            discount_amount: heapData.promoValue,
            discount_code_applied: heapData.promoCode,
            order_total: heapData.grandTotal,
            order_subtotal: heapData.subTotal,
          });

          fireAnalyticEvents(res.id);

          if ([CECheckoutTypes.KIFAH, CECheckoutTypes.MEMBERS].includes(checkoutRef.current.checkoutType)) {
            onSuccessfulPayment && onSuccessfulPayment(res.id);
          } else {
            goToOrderConfirmationPage(res.id);
          }
          return resolve(ApplePayConstants.PaymentStatus.SUCCESS);
        })
        .catch(e => {
          const result = { status: window.ApplePaySession.STATUS_FAILURE };
          HeapHelpers.fireHeapEvent("apple_pay_checkout_failed", heapEventData);

          if (e.jqXHR?.responseText === "invalid-shipping-address") {
            result.errors = [constructApplePayError(ApplePayConstants.APPLE_PAY_ERRORS.SHIPPING_CONTACT_INVALID)];
          }

          session.completePayment(result);
          return reject(e);
        });
    };
  };

  const fireAnalyticEvents = orderId => {
    Converge.trackCheckoutConversion(checkout, orderId);
    GTMHelpers.purchaseEventFromCheckout(checkout, orderId, user);
    const submitOrderPixel = createCheckoutPixel(
      "order_summary_form",
      "submit_order",
      user,
      checkout,
      bag,
      null,
      orderId
    );
    ActionLogger.logAction(submitOrderPixel);
  };

  const onCancelSession = resolve => {
    return () => {
      resolve(ApplePayConstants.PaymentStatus.CANCEL);
    };
  };

  const handleApplePaySession = () => {
    if (window.ApplePaySession?.canMakePayments()) {
      const { ApplePaySession } = window;

      return new Promise((resolve, reject) => {
        const requestObject = formatRequestObject(checkoutRef.current);

        const session = new ApplePaySession(ApplePayConstants.VERSION_NUMBER, requestObject);

        session.onvalidatemerchant = getOnValidateMerchant(session);
        session.onpaymentauthorized = getOnPaymentAuthorized(resolve, reject, session);

        session.oncancel = onCancelSession(resolve);

        const heapEventData = {
          deviceType: getAppleDeviceType(),
          checkout: checkout.id,
        };

        HeapHelpers.fireHeapEvent("apple_pay_loaded", heapEventData);

        session.begin();
      });
    }
  };

  return (
    <>
      <Script src="https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js" />
      <iframe title="secure-apple-pay-entry" src="/secureApplePayEntry" ref={secureApplePayEntryRef} hidden />
      <ApplePayButton handleApplePaySession={handleApplePaySession} buttonStyle={applePayStyle} />
    </>
  );
};

StandaloneApplePayWrapper.propTypes = propTypes;

export default StandaloneApplePayWrapper;
