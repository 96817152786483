import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

class AtomErrorNotification extends React.Component {
  static propTypes = {
    additionalClassName: PropTypes.string,
    errorIcon: PropTypes.element,
    errorCTA: PropTypes.string,
    errorCopy: PropTypes.string.isRequired,
    errorURL: PropTypes.string,
    onClick: PropTypes.func,
    forwardedRef: PropTypes.object,
  };

  static baseClass = "atom-error-notification";

  renderErrorElements() {
    const { additionalClassName, errorCopy, errorIcon, forwardedRef } = this.props;
    const classes = classNames(this.constructor.baseClass, {
      [additionalClassName]: additionalClassName,
    });

    return (
      <div className={classes} data-test-id="atom-error-notification" ref={forwardedRef}>
        {errorIcon && <div className={`${this.constructor.baseClass}--icon`}>{errorIcon}</div>}
        <div className={`${this.constructor.baseClass}--text`}>
          {errorCopy} {this.renderCTAs()}
        </div>
      </div>
    );
  }

  renderCTAs() {
    const { errorCTA, errorURL, onClick } = this.props;
    const universalStyle = "universal-underline";

    if (errorCTA) {
      return (
        <a
          className={`${this.constructor.baseClass}--cta ${universalStyle}`}
          href={errorURL || "#"}
          onClick={onClick || null}>
          {errorCTA}
        </a>
      );
    }
    return null;
  }

  render() {
    return this.renderErrorElements();
  }
}

export default AtomErrorNotification;
